import * as actionTypes from './actionTypes';
import axios from '../../services/axios';
import {setMessage} from "./common";

export const loadPositions = () => {
    return {
        type: actionTypes.LOAD_POSITIONS
    }
}

export const setPositions = (positionsData) => {
    return {
        type: actionTypes.SET_POSITIONS,
        positionsData: positionsData
    }
}

export const getPositions = () => {
    return (dispatch) => {
        dispatch(loadPositions())
        axios.get(`/positions`)
            .then(response => {
                dispatch(setPositions(response.data))
            })
            .catch(() => {
                dispatch(setPositions(null))
                dispatch(setMessage({type: 'warning', text: "Не удалось получить данные."}))
            })
    }
}