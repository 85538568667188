import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Alert, Form, Input } from 'antd';
import { FaUniversity } from "react-icons/fa";
import { AiOutlineLock, AiOutlineUser } from "react-icons/ai";

import classes from "./ChangePasswordAuth.module.scss"

import { loginWithPasswordChange } from "../../../store/actions";

import ContainerWithIconHeader from "../../UI/ContainerWithIconHeader/ContainerWithIconHeader";
import SubmitButton from "../../UI/SubmitButton/SubmitButton";
import { useAuthenticate } from "../../../hooks/useAuthenticate";
import { ConfirmPasswordItem } from "../../UI/Item/ConfirmPasswordItem";

const passwordRules = [
    {
        pattern: /[a-z]/,
        error: "Нет латинской буквы нижнего регистра",
    },
    {
        pattern: /[A-Z]/,
        error: "Нет латинской буквы верхнего регистра",
    },
    {
        pattern: /\d/,
        error: "Нет цифры",
    },
    {
        pattern: /[@!()*,.:;/?[_\]\-{}#$%^&=]/,
        error: "Нет специального сивола (!@#$%...)",
    },
    {
        pattern: /^[A-Za-z\d@!()*,.:;/?[_\]\-{}#$%^&=]*$/,
        error: "Пароль содержит некорректные символы",
    },
    {
        pattern: /^.{8,20}$/,
        error: "Длина пароля от 8 до 20 символов",
    },
]

const ChangePasswordAuth = () => {
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [newPasswordValidateTrigger, setNewPasswordValidateTrigger] = useState('onBlur')
    const [confirmedPassword, setConfirmedPassword] = useState('')

    const isLoginProcessing = useSelector(state => state.auth.isLoginProcessing)
    const message = useSelector(state => state.auth.message)
    const isBlocked = useSelector(state => state.auth.isBlocked);
    const errorMessage = isBlocked 
    ? "Превышено количество неудачных попыток. Попробуйте позже." 
    : "Не удалось изменить пароль.";

    const loginThatEqualsPassword = useSelector(state => state.auth.loginThatEqualsPassword)

    const authenticate = useAuthenticate(() => loginWithPasswordChange(
        loginThatEqualsPassword ?? username,
        loginThatEqualsPassword ?? password,
        newPassword
    ))

    return (
        <ContainerWithIconHeader
            icon={<FaUniversity size={40} />}
            title="Кабинет сотрудника БГУИР">
            <Form
                name="change_password_login"
                scrollToFirstError={true}
                labelCol={{ span: 9 }}
                requiredMark={false}
                onFinish={authenticate}
                className="ChangePasswordAuth_formContainer">
                <Form.Item>
                    <div className={classes.extra} style={{ textAlign: "justify" }}>
                        {`Для продолжения нужно указать новый пароль. Он должен состоять из `}
                        <b>латинских букв</b>
                        {` верхнего и нижнего регистров, включать хотя бы одну `}
                        <b>цифру</b>
                        {` и спец. символ. Длина пароля от 8 до 20 сиволов.`}
                    </div>
                </Form.Item>

                {
                    !loginThatEqualsPassword &&
                    <>
                        <Form.Item
                            name="login"
                            label="Логин"
                            style={{ marginBottom: '10px' }}
                            rules={[
                                {
                                    required: true, message: 'Данное поле является обязательным!'
                                },
                                {
                                    pattern: /^[a-zA-Z0-9_\-.]+$/g,
                                    message: 'Некорректный формат логина!'
                                }
                            ]}>
                            <Input
                                prefix={<AiOutlineUser />}
                                value={username}
                                onChange={(e) => setUsername(e.target.value.trim())} />
                        </Form.Item>

                        <Form.Item
                            name="password"
                            label="Старый пароль"
                            style={{ marginBottom: '34px' }}
                            rules={[
                                {
                                    required: true,
                                    message: 'Данное поле является обязательным!'
                                }
                            ]}>
                            <Input.Password
                                prefix={<AiOutlineLock />}
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value.trim())} />
                        </Form.Item>
                    </>
                }

                <Form.Item
                    name="newPassword"
                    label="Новый пароль"
                    validateTrigger={newPasswordValidateTrigger}
                    style={loginThatEqualsPassword ? undefined : { marginBottom: '10px' }}
                    rules={[
                        { required: true, message: 'Данное поле является обязательным!' },
                        () => ({
                            validator(_, value) {
                                if (!value) {
                                    setNewPasswordValidateTrigger(['onBlur', 'onChange']);
                                    return Promise.resolve();
                                }
                                for (let rule of passwordRules) {
                                    if (!value.match(rule.pattern)) {
                                        setNewPasswordValidateTrigger(['onBlur', 'onChange']);
                                        return Promise.reject(new Error(rule.error));
                                    }
                                }
                                return Promise.resolve();
                            },
                        }),
                    ]}>
                    <Input.Password
                        prefix={<AiOutlineLock />}
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value.trim())} />
                </Form.Item>

                <ConfirmPasswordItem
                    dependencie="newPassword"
                    value={confirmedPassword}
                    onChange={(e) => setConfirmedPassword(e.target.value.trim())}
                />

                <Form.Item>
                    <SubmitButton
                        type="submit"
                        text="Сохранить"
                        block
                        loading={isLoginProcessing}
                    />
                </Form.Item>

                {message && <Form.Item>
                    <Alert
                        className={classes.errorContainer}
                        message="Ошибка"
                        description={errorMessage}
                        type="error"
                        showIcon
                    />
                </Form.Item>}
            </Form>
        </ContainerWithIconHeader>
    );
}

export default ChangePasswordAuth;
