import { Input, Form } from "antd";

const EditableCell = ({
    editing,
    dataIndex,
    autoFocus,
    children,
    ...restProps
}) => {
    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{
                        margin: 0,
                    }}
                    rules={[
                        {
                            required: true,
                            message: "Данное поле является обязательным!",
                        },
                    ]}
                >
                    <Input autoFocus={autoFocus} />
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
}

export default EditableCell