import React from "react"
import { Form, Input, DatePicker } from 'antd';
import { AiOutlineLock } from "react-icons/ai";
import {PasswordRequirementsList} from "../ConfirmPassword/ConfirmPassword"
import classes from './CreateConfirmPassword.module.scss'
import locale from 'antd/es/date-picker/locale/ru_RU';
import dayjs from 'dayjs';


export const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@!()*,.:;/?[_\]\-{}#$%^&+=])([A-Za-z\d@!()*,.:;/?[_\]\-{}#$%^&+=]){8,}$/

export const CreateConfirmPasswordComponent = ({newPassword, confirmedPassword, birthDate, setNewPassword, setConfirmedPassword, setBirthDate}) => {
  
    return <div className={classes.tabContainer}>
        <div className={classes.tabSideCaption}>
            <PasswordRequirementsList />
        </div> 
        <div className={classes.tabContent}>
        
        <Form.Item
            name="password"
            label="Пароль"
            rules={[
                { required: true, message: 'Данное поле является обязательным!' },
                {
                    pattern: passwordPattern,
                    message: 'Некорректный формат пароля'
                }
            ]}>
            <Input.Password
                prefix={<AiOutlineLock />}
                placeholder="Новый пароль"
                value={newPassword}
                onChange={setNewPassword} />
        </Form.Item>

        <Form.Item
            name="confirmPassword"
            label="Подтвердите пароль"
            rules={[
                { required: true, message: 'Данное поле является обязательным!' },
                ({ getFieldValue }) => ({
                    validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                        }
                        return Promise.reject(new Error('Пароли должны совпадать!'));
                    },
                }),
            ]}>
            <Input.Password
                prefix={<AiOutlineLock />}
                placeholder="Подтвердите пароль"
                value={confirmedPassword}
                onChange={setConfirmedPassword} />
        </Form.Item>

            <Form.Item
                name="birthDatePicker"
                label="Дата рождения"
                rules={[{ required: true, message: 'Данное поле является обязательным!' }]}>
                <DatePicker style={{display:"flex", borderRadius:"5px"}}
                    placeholder='Дата рождения'
                    locale={locale}
                    placement={'topRight'}
                    disabledDate={current => current > dayjs()}
                    defaultPickerValue={dayjs().set('year', dayjs().year() - 17)}
                    format={[
                        'DD.MM.YYYY', 'D.MM.YYYY', 'DD.M.YYYY', 'D.M.YYYY',
                        'DD/MM/YYYY', 'D/MM/YYYY', 'DD/M/YYYY', 'D/M/YYYY',
                        'DD-MM-YYYY', 'D-MM-YYYY', 'DD-M-YYYY', 'D-M-YYYY',
                        'DD MM YYYY', 'D MM YYYY', 'DD M YYYY', 'D M YYYY'
                    ]}
                    value={birthDate}
                    onChange={(dateMoment) => setBirthDate(dateMoment)} 
                    />
            </Form.Item>
        </div>
    </div>
}