import useWindowDimensions from "../../hooks/windowDimensions";
import EmployeeLessonsMobile from "./EmployeeLessonsMobile/EmployeeLessonsMobile";
import ProfileJournalDesktop from "./ProfileJournalDesktop/ProfileJournalDesktop";

const Gradebook = () => {
    
    const { width } = useWindowDimensions();
    
    return (<div>
        {
            width <= 500 ? 
            <EmployeeLessonsMobile /> :
            <ProfileJournalDesktop />
        }
    </div>);
}

export default Gradebook;