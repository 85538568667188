import React, {useState, useEffect} from "react";
import {IoCheckmarkOutline, IoCloseOutline} from "react-icons/io5";
import {MdOutlineModeEditOutline, MdOutlineDeleteOutline} from "react-icons/md";
import {Button, Form, message, Popconfirm, Table} from "antd";

import classes from './ProfileLinks.module.scss'

import EditableCell from "./EditableCell/EditableCell";
import SubmitButton from "../../UI/SubmitButton/SubmitButton";
import parentClasses from "../PersonalPage.module.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencilAlt} from "@fortawesome/free-solid-svg-icons";
import {useSelector} from "react-redux";

const ProfileLinks = ({employeeDetails, updateProfileLinks, deleteProfileLinks}) => {
    const [form] = Form.useForm();
    const user = useSelector(state => state.auth.user);

    const [data, setData] = useState(employeeDetails.profileLinks.map((link, index) => ({...link, key: index})));
    const [editingKey, setEditingKey] = useState(null);
    const [newRowKey, setNewRowKey] = useState(null)
    const [count, setCount] = useState(employeeDetails.profileLinks.length)
    const [hideAreaAddProfileLinks, setHideAreaAddProfileLinks] = useState(true)

    useEffect(() => {
        setData(employeeDetails.profileLinks.map((link, index) => ({...link, key: index})))
    }, [employeeDetails])
    const isEditing = (row) => row.key === editingKey;

    const edit = (row) => {
        form.setFieldsValue({...row});
        setEditingKey(row.key);
    };

    const cancel = () => {
        setEditingKey(null);
        if (typeof newRowKey === 'number') {
            setData(oldValue => oldValue.filter(link => link.key !== newRowKey))
            setNewRowKey(null)
        }
    };

    const deleteRow = (key) => {
        const index = data.findIndex((item) => key === item.key)
        const newData = data.filter((item) => item.key !== key);

        delete data[index].key

        deleteProfileLinks(data[index])
        setData(newData);
    }

    const saveRow = async (key) => {
        try {
            let row = await form.validateFields();
            const index = data.findIndex((item) => key === item.key)
            if (index > -1) {
                const tmp = data.map(link => ({...link}))
                row = {...row, profileLinkEmployeeId: tmp[index].profileLinkEmployeeId || null}
                tmp[index] = {...row, key: key}

                updateProfileLinks(row)
                setData(tmp)
            } else {
                setData(oldValue => [...oldValue, {...row, key: key}])
            }

            setCount(count + 1)

            setEditingKey(null)
            setNewRowKey(null)
        } catch (errInfo) {
            message.error('Невозможно сохранить ссылку!')
        }
    };

    const createEmptyRow = () => {
        setNewRowKey(count)
        setEditingKey(count)
        form.resetFields()

        const emptyRow = {}
        for (let i = 0; i < columns.length - 1; i++) {
            emptyRow[columns[i].dataIndex] = ''
        }
        const a = {...emptyRow, key: count};
        form.setFieldsValue(a)
        setData(oldValue => [...oldValue, a])
    }

    const columns = [
        {
            title: 'Название',
            dataIndex: 'profileLinkType',
            width: '30%',
            editable: true,
        },
        {
            title: 'Ссылка',
            dataIndex: 'link',
            width: '50%',
            editable: true,
        },
        {
            title: 'Управление',
            dataIndex: 'actions',
            width: '120px',
            render: (_, row) => {
                const editable = isEditing(row);
                return editable
                    ? <div className={classes.editControlsContainer}>
                        <button type="button" onClick={() => saveRow(row.key)}>
                            <IoCheckmarkOutline size={25}/>
                        </button>
                        <button type="button" onClick={() => cancel()}>
                            <IoCloseOutline size={27}/>
                        </button>
                    </div>
                    : <div className={classes.editControlsContainer}>
                        <button type="button" onClick={() => edit(row)} disabled={newRowKey !== null}>
                            <MdOutlineModeEditOutline size={25}/>
                        </button>
                        <Popconfirm title="Вы действительно хотите удалить данную ссылку?"
                                    onConfirm={() => deleteRow(row.key)}
                                    disabled={newRowKey !== null || editingKey !== null}>
                            <button type="button" disabled={newRowKey !== null || editingKey !== null}>
                                <MdOutlineDeleteOutline size={25}/>
                            </button>
                        </Popconfirm>
                    </div>
            },
        }
    ];

    const mergedColumns = columns.map((column) => {
        if (!column.editable) {
            return column;
        }
        return {
            ...column,
            onCell: (row) => ({
                row,
                dataIndex: column.dataIndex,
                editing: isEditing(row),
                autoFocus: Object.keys(row)[0] === column.dataIndex,
                style: {verticalAlign: isEditing(row) ? 'top' : 'middle', maxWidth: "400px"}
            })
        };
    });

    const addDefaultSrc = (e) => {
        e.target.src = 'https://www.google.com/s2/favicons?domain=https://www.freefavicon.com/freefavicons/software/iconinfo/icon-with-question-mark-152-28725.html&sz=16'
    }
    const openProfile = () => {
        const filterQuery = `${employeeDetails.lastName}, ${employeeDetails.firstName.charAt(0)}. ${employeeDetails.middleName.charAt(0)}.`;
        window.open(`https://libeldoc.bsuir.by/simple-search?filterquery=${filterQuery}&filtername=author&filtertype=equals`);
    }

    const openSchedule = (urlId) => {
        window.open(`https://iis.bsuir.by/schedule/${urlId}`);
    }

    return (
        <div>
            <div className={parentClasses.headerTab} style={!hideAreaAddProfileLinks ? {paddingBottom: 10} : null}>
                <div className={parentClasses.sectionTitle}>Ссылки на профили</div>
                {hideAreaAddProfileLinks
                    ?
                    <button className={parentClasses.button}
                            onClick={() => setHideAreaAddProfileLinks(!hideAreaAddProfileLinks)}>
                        <FontAwesomeIcon icon={faPencilAlt} style={{color: "#3A547F"}}/>
                    </button>
                    :
                    <div className={classes.addRowButtonContainer}>
                        <Button type='secondary' className={parentClasses.cancelButton}
                                // style={{padding: "5px 5px", margin: "0 15px"}}
                                onClick={() => setHideAreaAddProfileLinks(!hideAreaAddProfileLinks)}>
                            Закрыть
                        </Button>

                        <SubmitButton
                            type="button"
                            text="Добавить"
                            disabled={newRowKey !== null}
                            onClickHandler={() => createEmptyRow()}
                        />
                    </div>
                }
            </div>

            <div className={parentClasses.bodyTab}>
                {hideAreaAddProfileLinks
                    ? <ul style={{fontSize: 14}}>
                        {user.anyLessons &&
                            <li className={parentClasses.contact} style={{marginBottom: 5}}>
                              <span><img width="25px" src="https://iis.bsuir.by/favicon.ico"
                                         alt="Employee schedule"/></span>
                                <span onClick={() => openSchedule(employeeDetails.urlId)}
                                      className={parentClasses.underline}>Расписание</span>
                            </li>
                        }

                        <li className={parentClasses.contact}>
                            <span><img width="25px" src="https://libeldoc.bsuir.by/favicon.ico"
                                 alt="Profile link favicon"/></span>

                            <span onClick={openProfile}
                                  className={parentClasses.underline}>Репозиторий БГУИР</span>
                        </li>

                        {data.map((link) =>
                            <li className={parentClasses.links} key={link.profileLinkType}>
                                <div style={{padding: "3px 0"}}>
                                    <img width="25px" src={`https://www.google.com/s2/favicons?domain=${link.link}`}
                                         alt="Profile link favicon" onError={addDefaultSrc}/>
                                    <a href={link.link} target="_blank" rel="noreferrer"
                                       className={parentClasses.underline}>{link.profileLinkType}</a>
                                </div>
                            </li>
                        )}
                    </ul>
                    :
                    <div className={classes.tableContainer}>
                        <Form form={form} component={false}>
                            <Table
                                size="small"
                                scroll={{x: 'fit-content'}}
                                components={{
                                    body: {
                                        cell: EditableCell,
                                    },
                                }}
                                bordered
                                pagination={false}
                                columns={mergedColumns}
                                dataSource={data}
                            />
                        </Form>
                    </div>
                }
            </div>
        </div>
    )
}

export default ProfileLinks