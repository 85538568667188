import * as actionTypes from './actionTypes';
import axios from '../../services/axios';
import {setMessage} from "./common";

export const loadAnnouncements = () => {
    return {
        type: actionTypes.LOAD_ANNOUNCEMENTS
    }
}

export const setAnnouncements = (announcementsData) => {
    return {
        type: actionTypes.SET_ANNOUNCEMENTS,
        announcementsData: announcementsData
    }
}

export const getAnnouncements = (params, page, size) => {
    return (dispatch) => {
        dispatch(loadAnnouncements())
        axios.get(`/announcements?page=${page}&size=${size}`, { params: params })
            .then(response => {
                dispatch(setAnnouncements(response.data))
            })
            .catch(() => {
                dispatch(setMessage({type: 'warning', text: "Не удалось получить данные."}))
            })
    }
}

export const startUpdatingAnnouncement = () => {
    return {
        type: actionTypes.START_UPDATING_ANNOUNCEMENT
    }
}

export const endUpdatingAnnouncement = () => {
    return {
        type: actionTypes.END_UPDATING_ANNOUNCEMENT
    }
}

export const addNewAnnouncement = (params, onSuccess) => {
    return (dispatch) => {
        dispatch(startUpdatingAnnouncement())
        axios.post("/announcements", params)
            .then(_response => {
                dispatch(endUpdatingAnnouncement())
                dispatch(setMessage({type: 'success', text: "Объявление добавлено."}));
                if (onSuccess) onSuccess();
            })
            .catch(_error => {
                dispatch(endUpdatingAnnouncement())
                dispatch(setMessage({type: 'warning', text: "Не удалось добавить объявление."}));
            })
    }
}

export const editAnnouncement = (announcementId, params, onSuccess) => {
    return (dispatch) => {
        dispatch(startUpdatingAnnouncement())
        axios.put(`/announcements/${announcementId}`, params)
            .then(_response => {
                dispatch(endUpdatingAnnouncement())
                dispatch(setMessage({type: 'success', text: "Объявление изменено."}));
                if (onSuccess) onSuccess();
            })
            .catch(_error => {
                dispatch(endUpdatingAnnouncement())
                dispatch(setMessage({type: 'warning', text: "Не удалось изменить объявление."}));
            })
    }
}

export const deleteAnnouncement = (announcementId, deleteAllRepeatAnnouncement, onSuccess) => {
    return (dispatch) => {
        dispatch(startUpdatingAnnouncement())
        axios.delete(`/announcements?announcementId=${announcementId}&deleteAllRepeatAnnouncement=${deleteAllRepeatAnnouncement}`)
            .then(_response => {
                dispatch(endUpdatingAnnouncement())
                dispatch(setMessage({type: 'success', text: "Объявление удалено."}));
                if (onSuccess) onSuccess();
            })
            .catch(_error => {
                dispatch(endUpdatingAnnouncement())
                dispatch(setMessage({type: 'warning', text: "Не удалось удалить объявление."}));
            })
    }
}