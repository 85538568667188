export const disableDevtools = () => {
  if (typeof window.__REACT_DEVTOOLS_GLOBAL_HOOK__ === "object") {
    for (let [key, value] of Object.entries(window.__REACT_DEVTOOLS_GLOBAL_HOOK__)) {
      window.__REACT_DEVTOOLS_GLOBAL_HOOK__[key] = typeof value == "function" ? () => { } : null;
    }
  }
}

export const setWordEnding = (n, text_forms) => {
  n = Math.abs(n) % 100;
  const n1 = n % 10;
  if (n > 10 && n < 20) { return text_forms[2]; }
  if (n1 > 1 && n1 < 5) { return text_forms[1]; }
  if (n1 === 1) { return text_forms[0]; }
  return text_forms[2];
}

export const getFio = (lastName, firstName, middleName) => {
  return lastName + (firstName ? ` ${firstName[0]}.` + (middleName ? `${middleName[0]}.` : '') : '');
}

export const EMAIL_REGULAR_EXPRESSION = /^(?=.{3,30}@)[A-Za-z0-9]+([.\-_]?[A-Za-z0-9])*@[A-Za-z0-9-]+(\.[A-Za-z0-9]+)*(\.[A-Za-z]{2,3})$/i;

export const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, {type: contentType});
}

export const getImageDimensions = (file) => {
  return new Promise(function (resolved, _) {
    let i = new Image()
    i.onload = function () {
      resolved({ w: i.width, h: i.height })
    };
    i.src = file
  })
}
