import {Button, Modal, Transfer} from "antd";
import {useEffect, useState} from "react";
import {getFio} from "../../../../services/commonService";
import classes from "./SubgroupDialog.module.scss";
import {useDispatch, useSelector} from "react-redux";
import {assignSubgroups} from "../../../../store/actions/gradeBook";

const SubgroupDialog = (props/*students, termHoursId, onClose*/) => {
    const warningMessage = "У одного или нескольких из выбранных студентов присутствуют часы пропуска в текущей подгруппе, при назначении в другую подгруппу часы пропуска будут удалены"
    const [displayWarning, setDisplayWarning] = useState(false)

    const assigningSubgroups = useSelector(state => state.gradeBook.assigningSubgroups);
    const [students, setStudents] = useState([])

    const dispatch = useDispatch();

    useEffect(() => {
        const studentArr = props.students ? JSON.parse(JSON.stringify(props.students)).sort(compareStudents).map(student => ({
            ...student,
            key: student.id
        })) : [];
        setStudents(studentArr);
        setTargetKeys(getSecondSubgroupStudents().map(student => student.key))
        setSourceKeys(getFirstSubgroupStudents().map(student => student.key))
    }, [props])

    const getSecondSubgroupStudents = () => {
        if (!students) return [];
        const subgroupsAreAssigned = students.some(student => student.subgroup);
        if (!subgroupsAreAssigned) return students.slice(Math.round(students.length / 2));
        else return students.filter(student => student.subgroup === 2);
    }

    const getFirstSubgroupStudents = () => {
        return students.filter(student => !targetKeys.includes(student.key));
    }

    const [targetKeys, setTargetKeys] = useState([]);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [sourceKeys, setSourceKeys] = useState([]);

    const compareStudents = (a, b) => {
        return a.lastName.localeCompare(b.lastName, 'ru')
            || a.firstName.localeCompare(b.firstName, 'ru')
            || a.middleName.localeCompare(b.middleName, 'ru');
    }

    const onChange = (nextTargetKeys, _direction, _moveKeys) => {
        setTargetKeys(
            students
                .filter(student => nextTargetKeys.includes(student.key))
                .sort(compareStudents)
                .map(student => student.key)
        );

        setSourceKeys(
            students
                .filter(student => !nextTargetKeys.includes(student.key))
                .sort(compareStudents)
                .map(student => student.key)
        );
    };

    const onSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
        let checkWarning = false
        let idFirstLessons = props.lessons.filter(les => les.subgroup === 1).map(les => les.id)
        let idSecondLessons = props.lessons.filter(les => les.subgroup === 2).map(les => les.id)
        sourceSelectedKeys.forEach(key => {
            let idStudentLessons = props.students.find(stud => stud.id === key).lessonOmissions.map(les => les.idLesson)
            if (idStudentLessons.some(idLes => idFirstLessons.includes(idLes))) checkWarning = true
        })
        targetSelectedKeys.forEach(key => {
            let idStudentLessons = props.students.find(stud => stud.id === key).lessonOmissions.map(les => les.idLesson)
            if (idStudentLessons.some(idLes => idSecondLessons.includes(idLes))) checkWarning = true
        })
        setDisplayWarning(checkWarning)
        setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
    };

    const onAssignSubgroups = () => {
        dispatch(assignSubgroups(
            props.termHoursId,
            students.map(student => {
                return {
                    studentId: student.id,
                    subgroup: targetKeys.includes(student.key) ? 2 : 1,
                    omissions: student.lessonOmissions
                }
            }),
            props.students,
            props.lessons,
            onModalClose
        ));
    }

    const onModalClose = () => {
        setTargetKeys([]);
        setSelectedKeys([]);
        setDisplayWarning(false);
        props.onClose();
    }

    return <Modal
        className={classes.modal}
        title='Назначение подгрупп'
        open={props.onShow}
        centered={true}
        okButtonProps={{disabled: assigningSubgroups}}
        onCancel={onModalClose}
        destroyOnClose={true}
        width={900}
        footer={[
            displayWarning ? <div className={classes.warningMessage}>{warningMessage}</div> : null,
            <Button onClick={onModalClose}>
                Отменить
            </Button>,
            <Button type="primary" onClick={onAssignSubgroups}>
                Сохранить
            </Button>
        ]}
    >
        <div className={classes.modalBody}>
            <div className={classes.headersDiv}>
                <div className={classes.headerDiv}>1 подгруппа</div>
                <div className={classes.spaceBetweenCell}> </div>
                <div className={classes.headerDiv}>2 подгруппа</div>
            </div>
            <Transfer
                dataSource={students}
                titles={[null, null]}
                targetKeys={targetKeys}
                selectedKeys={selectedKeys}
                onChange={onChange}
                onSelectChange={onSelectChange}
                showSelectAll={true}
                listStyle={{width: '100%', height: '370px'}}
                render={student => <div>
                        <span className={student.subgroup ? ((student.inOffsettingValue !== 0 || student.expelledInCurrentSemester) && classes.haveOffsetingValue) : classes.notAssigned}>
                            {targetKeys.indexOf(student.key) + 1 || sourceKeys.indexOf(student.key) + 1}. {getFio(student.lastName, student.firstName, student.middleName)}
                        </span> 
                        {props.getOffsetLabel(student)}
                    </div>}
                locale={{
                    itemUnit: "студентов",
                    itemsUnit: "студентов",
                    notFoundContent: "Пусто"
                }}
                operationStyle={{width: '100px', flexWrap: 'wrap', alignContent: 'center'}}
            />
        </div>
    </Modal>
}

export default SubgroupDialog