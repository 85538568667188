import axios from '../../services/axios';
import {setMessage} from "./common";
import * as actionTypes from './actionTypes';
import {cloneDeep} from 'lodash';

export const loadingGradeBookData = (tableParams) => {
    return {
        type: actionTypes.LOAD_GRADE_BOOK,
        tableParams: tableParams
    }
}

export const setGradeBookData = (gradeBookData, tableParams) => {
    return {
        type: actionTypes.SET_GRADE_BOOK,
        gradeBookData: gradeBookData,
        tableParams: tableParams
    }
}

export const getGradeBookData = (tableParams) => {
    return (dispatch) => {
        dispatch(loadingGradeBookData(tableParams))
        axios.post(`/gradebook`, tableParams)
            .then(response => {
                dispatch(setGradeBookData(response.data, tableParams));
            })
            .catch(_error => {
                dispatch(setMessage({type: 'warning', text: "Не удалось подключиться к серверу."}))
            });
    }
}

export const updateOmissions = (params, onSuccess, onFinally) => {
    return (dispatch) => {
        axios.post(`/gradebook/update-omissions`, params)
            .then(response => {
                onSuccess(response.data)
            })
            .catch(_error => {
                dispatch(setMessage({type: 'warning', text: "Не удалось сохранить пропуски."}))
            })
            .finally(() => {
                if (onFinally) onFinally()
            })
    }
}

export const saveMarksInGradeSettingMode = (params, isPercentage, onSuccess) => {
    return (dispatch) => {
        axios.post(`${getBaseForGradebookApi(isPercentage)}/save-marks-in-grade-setting-mode`, params)
            .then(response => {
                onSuccess(response.data)
            })
            .catch(_error => {
                dispatch(setMessage({type: 'warning', text: "Не удалось сохранить оценки."}))
            });
    }
}

export const studentPhotosLoaded = () => {
    return {
        type: actionTypes.STUDENT_PHOTOS_LOADED
    }
}

export const getStudentPhotos = (gradebookData, tableParams) => {
    return (dispatch) => {
        const studentsCopy = gradebookData.students.map(s => cloneDeep(s));
        const getPhotoPromises = [];
        for (let student of gradebookData.students) {
            getPhotoPromises.push(axios.get(`/person/student-photo/${student.id}`)
                .then(response => {
                    const studentToUpdate = studentsCopy.find(s => s.id === student.id);
                    studentToUpdate.photo = `data:image/jpeg;base64,${response.data}`;
                    return response;
                }));
        }
        Promise.allSettled(getPhotoPromises)
            .then(() => {
                dispatch(setGradeBookData({...gradebookData, students: studentsCopy}, tableParams));
                dispatch(studentPhotosLoaded());
            })
    }
}

export const setFilterStudentGroupOrEmployee = (studentGroupOrEmployee) => {
    return {
        type: actionTypes.SET_FILTER_STUDENT_GROUP_OR_EMPLOYEE,
        studentGroupOrEmployee: studentGroupOrEmployee
    }
}

export const setIsEmployee = (isEmployee) => {
    return {
        type: actionTypes.SET_IS_EMPLOYEE,
        isEmployee: isEmployee
    }
}

export const loadingGradeBookSubjects = () => {
    return {
        type: actionTypes.LOAD_GRADE_BOOK_SUBJECTS
    }
}

export const loadingStudentGroupsOrEmployees = () => {
    return {
        type: actionTypes.LOAD_STUDENT_GROUPS_OR_EMPLOYEES
    }
}

export const loadingStudentGroupOrEmployee = () => {
    return {
        type: actionTypes.LOAD_STUDENT_GROUP_OR_EMPLOYEE
    }
}

export const setGradeBookSubjects = (gradeBookSubjects) => {
    return {
        type: actionTypes.SET_GRADE_BOOK_SUBJECTS,
        gradeBookSubjects: gradeBookSubjects
    }
}

export const setStudentGroupsOrEmployees = (studentGroupsOrEmployees) => {
    return {
        type: actionTypes.SET_STUDENT_GROUPS_OR_EMPLOYEES,
        studentGroupsOrEmployees: studentGroupsOrEmployees
    }
}

export const setStudentGroupOrEmployee = (studentGroupOrEmployee) => {
    return {
        type: actionTypes.SET_STUDENT_GROUP_OR_EMPLOYEE,
        studentGroupOrEmployee: studentGroupOrEmployee
    }
}

export const getGradeBookSubjects = (year, semester, employeeId) => {
    return (dispatch) => {
        dispatch(loadingGradeBookSubjects())
        const requestParams = {
            year,
            semester,
            employeeId
        }
        axios.get(`/gradebook/filter`, {params: requestParams})
            .then(response => {
                dispatch(setGradeBookSubjects(response.data))
            })
            .catch(_error => {
                dispatch(setMessage({type: 'warning', text: "Не удалось подключиться к серверу."}))
            });

    }
}

export const getStudentGroupsOrEmployees = (studentGroupOrEmployee, isEmployee) => {
    return isEmployee
        ? (dispatch) => {
            dispatch(loadingStudentGroupsOrEmployees())
            axios.get(`/gradebook/teachers?fio=${studentGroupOrEmployee}`)
                .then(response => {
                    dispatch(setStudentGroupsOrEmployees(response.data))
                    dispatch(setIsEmployee(true));
                })
                .catch(_error => {
                    dispatch(setMessage({type: 'warning', text: "Не удалось подключиться к серверу."}))
                })
        }
        : (dispatch) => {
            dispatch(loadingStudentGroupsOrEmployees())
            axios.get(`/gradebook/student-groups?name=${studentGroupOrEmployee}`)
                .then(response => {
                    dispatch(setStudentGroupsOrEmployees(response.data))
                    dispatch(setIsEmployee(false));
                })
                .catch(_error => {
                    dispatch(setMessage({type: 'warning', text: "Не удалось подключиться к серверу."}))
                })
        }
}

export const getStudentGroupOrEmployee = (studentGroupOrEmployee, isEmployee) => {
    return isEmployee
        ? (dispatch) => {
            dispatch(loadingStudentGroupOrEmployee())
            axios.get(`/gradebook/teachers?fio=${studentGroupOrEmployee}`)
                .then(response => {
                    dispatch(setStudentGroupOrEmployee(response.data))
                })
                .catch(_error => {
                    dispatch(setMessage({type: 'warning', text: "Не удалось подключиться к серверу."}))
                })
        }
        : (dispatch) => {
            dispatch(loadingStudentGroupOrEmployee())
            axios.get(`/gradebook/student-groups?name=${studentGroupOrEmployee}`)
                .then(response => {
                    dispatch(setStudentGroupOrEmployee(response.data))
                })
                .catch(_error => {
                    dispatch(setMessage({type: 'warning', text: "Не удалось подключиться к серверу."}))
                })
        }
}

export const setGradeBookLessonsDate = (date) => {
    return {
        type: actionTypes.SET_LESSONS_DATE,
        lessonsDate: date
    }
}

export const setGradeBookLessonsNumber = (number) => {
    return {
        type: actionTypes.SET_LESSONS_NUMBER,
        lessonsNumber: number
    }
}

////////////////////////////////////////////////

const initSubgroupsAssignment = () => {
    return {
        type: actionTypes.ASSIGN_SUBGROUPS
    }
}

const acceptSubgroups = (subgroupsData, gradeBookStudents) => {
    return {
        type: actionTypes.ACCEPT_SUBGROUPS,
        studentSubgroups: subgroupsData,
        gradeBookStudents: gradeBookStudents
    }
}

const skipSubgroups = () => {
    return {
        type: actionTypes.SKIP_SUBGROUPS
    }
}

export const assignSubgroups = (termHoursId, students, gradeBookStudents, lessons, closeModal) => {
    const requestBody = {
        termHoursId: termHoursId,
        students: students,
        lessons: lessons
    };

    return (dispatch) => {
        dispatch(initSubgroupsAssignment());
        axios.put("/gradebook/assign-subgroups", requestBody)
            .then(response => {
                dispatch(acceptSubgroups(response.data, gradeBookStudents));
                setTimeout(() => dispatch(setMessage({type: 'success', text: "Подгруппы назначены."})), 200);
                closeModal();
            })
            .catch(_error => {
                dispatch(skipSubgroups());
                dispatch(setMessage({type: 'warning', text: "Не удалось назначить подгруппы."}));
            })
    }
}

////////////////////////////////////////////////

const initTeamsAssignment = () => {
    return {
        type: actionTypes.ASSIGN_TEAMS
    }
}

const acceptTeams = (teamsData, gradeBookStudents) => {
    return {
        type: actionTypes.ACCEPT_TEAMS,
        studentTeams: teamsData,
        gradeBookStudents: gradeBookStudents
    }
}

const skipTeams = () => {
    return {
        type: actionTypes.SKIP_TEAMS
    }
}

export const assignTeams = (termHoursId, students, gradeBookStudents, closeModal) => {
    const requestBody = {
        termHoursId: termHoursId,
        students: students
    };

    return (dispatch) => {
        dispatch(initTeamsAssignment());
        axios.put("/gradebook/assign-teams", requestBody)
            .then(response => {
                dispatch(acceptTeams(response.data, gradeBookStudents));
                setTimeout(() => dispatch(setMessage({type: 'success', text: "Команды назначены."})), 200);
                closeModal();
            })
            .catch(_error => {
                dispatch(skipTeams());
                dispatch(setMessage({type: 'warning', text: "Не удалось назначить команды."}));
            })
    }
}

////////////////////////////////////////////////

const initAddingNewLesson = () => {
    return {
        type: actionTypes.ADD_NEW_LESSON
    }
}

const acceptNewLesson = () => {
    return {
        type: actionTypes.ACCEPT_NEW_LESSON
    }
}

const skipNewLesson = () => {
    return {
        type: actionTypes.SKIP_NEW_LESSON
    }
}

export const addNewLesson = (lessonParams, onSuccess, isPercentage) => {
    return (dispatch) => {
        dispatch(initAddingNewLesson());
        axios.post(`${getBaseForGradebookApi(isPercentage)}/add-new-lesson-date`, lessonParams)
            .then(_response => {
                dispatch(acceptNewLesson());
                dispatch(setMessage({type: 'success', text: "Занятие добавлено."}));
                if (onSuccess) onSuccess();
            })
            .catch(_error => {
                dispatch(skipNewLesson());
                dispatch(setMessage({type: 'warning', text: "Не удалось добавить занятие."}));
            })
    }
}

////////////////////////////////////////////////

const initEditingNewLesson = () => {
    return {
        type: actionTypes.EDIT_LESSON
    }
}

const acceptEditedLesson = () => {
    return {
        type: actionTypes.ACCEPT_EDITED_LESSON
    }
}

const skipEditedLesson = () => {
    return {
        type: actionTypes.SKIP_EDITED_LESSON
    }
}

export const editLesson = (lessonParams, onSuccess, isPercentage) => {
    return (dispatch) => {
        dispatch(initEditingNewLesson());
        axios.put(`${getBaseForGradebookApi(isPercentage)}/edit-lesson-date`, lessonParams)
            .then(_response => {
                dispatch(acceptEditedLesson());
                dispatch(setMessage({type: 'success', text: "Занятие изменено."}));
                if (onSuccess) onSuccess();
            })
            .catch(_error => {
                dispatch(skipEditedLesson());
                dispatch(setMessage({type: 'warning', text: "Не удалось изменить занятие."}));
            })
    }
}

////////////////////////////////////////////////

const initDeletingNewLesson = () => {
    return {
        type: actionTypes.DELETE_LESSON
    }
}

const acceptDeletedLesson = () => {
    return {
        type: actionTypes.ACCEPT_DELETED_LESSON
    }
}

const skipDeletedLesson = () => {
    return {
        type: actionTypes.SKIP_DELETED_LESSON
    }
}

export const deleteLesson = (lessonId, onSuccess, isPercentage) => {
    return (dispatch) => {
        dispatch(initDeletingNewLesson());
        axios.delete(`${getBaseForGradebookApi(isPercentage)}/delete-lesson?lessonId=${lessonId}`)
            .then(_response => {
                dispatch(acceptDeletedLesson());
                dispatch(setMessage({type: 'success', text: "Занятие удалено."}));
                if (onSuccess) onSuccess();
            })
            .catch(_error => {
                dispatch(skipDeletedLesson());
                dispatch(setMessage({type: 'warning', text: "Не удалось удалить занятие."}));
            })
    }
}

export const loadGradebookReport = () => {
    return {
        type: actionTypes.LOAD_GRADEBOOK_REPORT
    }
}

export const onLoadGradebookReportStopped = () => {
    return {
        type: actionTypes.SAVE_GRADEBOOK_REPORT
    }
}

export const downloadGradebookReportExcel = (semester, year, groupId, employeeId, termHoursId) => {
    const seconds = new Date().getTime() / 1000
    return (dispatch) => {
        dispatch(loadGradebookReport())
        dispatch(setMessage({
            type: 'open',
            text: "Журнал успеваемости скачивается.",
            key: `updatableGradebookReportExcel${seconds}`,
            duration: 0
        }));
        axios.get('/gradebook-reports/get-default-report',
            {
                responseType: 'blob',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                },
                params: {
                    semester,
                    year,
                    groupId,
                    employeeId,
                    termHoursId
                }
            }
        ).then(response => {
            dispatch(saveReport(response, actionTypes.LOAD_GRADEBOOK_REPORT))
            dispatch(setMessage({
                type: 'success',
                text: "Журнал успеваемости загружен.",
                key: `updatableGradebookReportExcel${seconds}`
            }));
        }).catch(_error => {
            dispatch(setMessage({
                type: 'error',
                text: "Не удалось загрузить журнал успеваемости.",
                key: `updatableGradebookReportExcel${seconds}`
            }))
            dispatch(onLoadGradebookReportStopped())
        });
    }
}

export const loadMemoReport = () => {
    return {
        type: actionTypes.LOAD_MEMO_REPORT
    }
}

export const onLoadMemoReportStopped = () => {
    return {
        type: actionTypes.SAVE_MEMO_REPORT
    }
}

export const downloadMemoReportExcel = ({
                                            groupId,
                                            employeeId,
                                            subject,
                                            termHoursId,
                                            controlPoint,
                                            requiredAmountOfLabs,
                                            closeModal
                                        }) => {
    const seconds = new Date().getTime() / 1000
    return (dispatch) => {
        dispatch(loadMemoReport())
        dispatch(setMessage({
            type: 'open',
            text: "Докладная записка скачивается.",
            key: `updatableMemoReportExcel${seconds}`,
            duration: 0
        }));
        axios.get('/gradebook-reports/get-memo-report',
            {
                responseType: 'blob',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                },
                params: {
                    groupId,
                    employeeId,
                    subject,
                    termHoursId,
                    controlPoint,
                    requiredAmountOfLabs,
                }
            }
        ).then(response => {
            dispatch(saveReport(response, actionTypes.LOAD_MEMO_REPORT))
            dispatch(setMessage({
                type: 'success',
                text: "Докладная записка загружена.",
                key: `updatableMemoReportExcel${seconds}`
            }));
            closeModal();
        }).catch(_error => {
            dispatch(setMessage({
                type: 'error',
                text: "Не удалось загрузить докладную записку.",
                key: `updatableMemoReportExcel${seconds}`
            }))
            dispatch(onLoadGradebookReportStopped())
        });
    }
}

export const downloadMemoReportForPercentageExcel = ({
                                                         groupId,
                                                         employeeId,
                                                         subject,
                                                         termHoursId,
                                                         controlPoint,
                                                         requiredPercentage,
                                                         closeModalForPercentage
                                                     }) => {
    const seconds = new Date().getTime() / 1000
    return (dispatch) => {
        dispatch(loadMemoReport())
        dispatch(setMessage({
            type: 'open',
            text: "Докладная записка скачивается.",
            key: `updatableMemoReportExcel${seconds}`,
            duration: 0
        }));
        axios.get('/gradebook-reports/get-memo-report-for-percentage',
            {
                responseType: 'blob',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                },
                params: {
                    groupId,
                    employeeId,
                    subject,
                    termHoursId,
                    controlPoint,
                    requiredPercentage,
                }
            }
        ).then(response => {
            dispatch(saveReport(response, actionTypes.LOAD_MEMO_REPORT))
            dispatch(setMessage({
                type: 'success',
                text: "Докладная записка загружена.",
                key: `updatableMemoReportExcel${seconds}`
            }));
            closeModalForPercentage();
        }).catch(_error => {
            dispatch(setMessage({
                type: 'error',
                text: "Не удалось загрузить докладную записку.",
                key: `updatableMemoReportExcel${seconds}`
            }))
            dispatch(onLoadGradebookReportStopped())
        });
    }
}

export const saveReport = (response, type) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;

    let filename = response.headers['file-name'];
    if (!filename || filename.length === 0) {
        filename = 'Отчёт.xlsx';
    } else {
        filename = decodeURI(filename);
    }

    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    if (type === actionTypes.LOAD_GRADEBOOK_REPORT)
        return onLoadGradebookReportStopped();
    if (type === actionTypes.LOAD_MEMO_REPORT)
        return onLoadMemoReportStopped();
}

////////////////////////////////////////////////

export const startUpdatingLabCount = () => {
    return {
        type: actionTypes.START_UPDATING_LAB_COUNT
    }
}

export const endUpdatingLabCount = (updatedGradeBookSubjects) => {
    return {
        type: actionTypes.END_UPDATING_LAB_COUNT,
        updatedGradeBookSubjects: updatedGradeBookSubjects
    }
}

export const editLabCount = (params, updatedGradeBookSubjects) => {
    return (dispatch) => {
        dispatch(startUpdatingLabCount())
        axios.put(`/gradebook/update-lab-count`, params)
            .then(_response => {
                dispatch(endUpdatingLabCount(updatedGradeBookSubjects))
                dispatch(setMessage({type: 'success', text: "Количество лабораторных изменено."}));
            })
            .catch(_error => {
                dispatch(setMessage({type: 'warning', text: "Не удалось изменить количество лабораторных."}));
            })
    }
}

////////////////////////////////////////////////

export const setLessonPeriods = (lessonPeriods) => {
    return {
        type: actionTypes.SET_LESSON_PERIODS,
        lessonPeriods: lessonPeriods
    }
}

export const getLessonPeriods = () => {
    return (dispatch) => {
        axios.get(`/lessonPeriod`)
            .then(res => {
                dispatch(setLessonPeriods(res.data))
            })
            .catch(_error => {
                dispatch(setMessage({type: 'warning', text: "Не удалось подключиться к серверу."}))
            });
    }
}

////////////////////////////////////////////////

export const getBaseForGradebookApi = (isPercentage) => {
    return `/gradebook${isPercentage ? '/percentage' : ''}`;
}