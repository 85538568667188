import IntegerInput from "./IntegerInput";

const PercentageInput = (props) => {
    const renderPercents = value => (value || value === 0) ? `${value} %` : '';
    const mergeValues = (oldValue, newRenderedValue) => {
        const newValueAsString = `${newRenderedValue}`.replace(/\D/g,'');

        const oldRenderedValue = renderPercents(oldValue);
        const oldValueAsString = `${oldRenderedValue}`.replace(/\D/g,'');

        if (newValueAsString === oldValueAsString && newRenderedValue.length < oldRenderedValue.length) {
            return oldValueAsString.slice(0, oldValueAsString.length - 1);
        } else {
            return newValueAsString;
        }
    }

    return <IntegerInput
        min={0}
        max={100}
        value={props.value}
        isNullable={true}
        className={props.className}
        isJournal={props.isJournal}
        style={props.style}
        render={renderPercents}
        mergeValues={mergeValues}
        onBlur={props.onBlur}
        onFocus={props.onFocus}
        onKeyDown={props.onKeyDown}
        onKeyUp={props.onKeyUp}
        onClick={props.onClick}
        onChange={props.onChange}
        needToFocus={props.needToFocus}
        onLongPress={props.onLongPress}
        status={props.status}
    />
}

export default PercentageInput;