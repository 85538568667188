import React, {useEffect, useRef, useState} from 'react';

import classes from './BugReportModal.module.scss';
import {Button, Form, Modal} from "antd";
import Input from "antd/es/input/Input";
import TextArea from "antd/es/input/TextArea";
import {useDispatch, useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
import DrawingTools from "../../UI/DrawingTools/DrawingTools";
import {setMessage} from "../../../store/actions";
import {browserName, fullBrowserVersion} from "react-device-detect";
import routes from "../../../services/routes";
import axios from "../../../services/axios";
import {EMAIL_REGULAR_EXPRESSION} from "../../../services/commonService";
import {FaTimes} from "react-icons/fa";

const BugReportModal = (props) => {

    const MAX_TOTAL_FILES_SIZE = 52428800;
    const ALLOWED_FILE_EXTENSIONS = [".txt", ".htm", ".html", ".zip", ".doc", ".docx", "image/*", "video/*",
        "audio/*", ".xml", ".xls", ".xlsx", ".pptx", ".rtf", ".csv", ".pub", ".odt", ".pdf"];
    const PROHIBITED_FILE_EXTENSIONS = [".ade", ".adp", ".apk", ".appx", ".appxbundle", ".bat", ".cab", ".chm",
        ".cmd", ".com", ".cpl", ".dll", ".dmg", ".exe", ".hta", ".ins", ".isp", ".iso", ".jar", ".js", ".jse", ".lib",
        ".lnk", ".mde", ".msc", ".msi", ".msix", ".msixbundle", ".msp", ".mst", ".nsh", ".pif", ".psl", ".scr", ".sct",
        ".shb", ".sys", ".vb", ".vbe", ".vbs", ".vxd", ".wsc", ".wsf", ".wsh"];

    const user = useSelector(state => state.auth.user)

    const dispatch = useDispatch()
    const screenshotComponentRef = useRef()
    const routerLocation = useLocation()

    const [browserInformation] = useState(`${browserName} ${fullBrowserVersion}`)

    const [form] = Form.useForm()

    const [bugDescription, setBugDescription] = useState('');
    const [email, setEmail] = useState(user?.ldapEmail);
    const [fio, setFio] = useState(user?.fio);
    const [screenshot] = useState(props.image);

    const [confirmLoading, setConfirmLoading] = useState(false);

    const inputFile = useRef(null);
    const [attachments, setAttachments] = useState([]);
    const [totalAttachmentsSize, setTotalAttachmentsSize] = useState(0);

    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true)

    useEffect(() => {
        form.validateFields()
            .then(() => {
                setIsSubmitDisabled(false)
            })
            .catch(() => {
                setIsSubmitDisabled(true)
            })
    }, [bugDescription, fio, email])

    const sendBugReportRequest = async () => {
        setConfirmLoading(true);
        form.validateFields()
            .then(async (_values) => {
                const resultScreenshot = await parseScreenshot()
                const formData = new FormData();
                for (let i = 0; i < attachments?.length; i++) {
                    formData.append(`files[${i}]`, attachments[i])
                }
                if (resultScreenshot) {
                    formData.append(`files[${attachments?.length ?? 0}]`, resultScreenshot)
                }
                formData.append("bugDescription", bugDescription);
                formData.append("subject", routes.find(route => route.pathname === routerLocation.pathname)?.name ?? 'Личный кабинет сотрудника')
                formData.append("details", JSON.stringify([{title: 'Браузер', value: browserInformation}, {title: 'Ссылка', value: window.location.href}]));
                formData.append("email", email);
                formData.append("fio", fio);
                axios.post("/service-desk", formData).then(() => {
                    dispatch(setMessage({type: 'success', text: "Ваша заявка успешно отправлена."}))
                    props.onClose();
                    setConfirmLoading(false);
                }).catch(() => {
                    dispatch(setMessage({type: 'error', text: "Не удалось отправить заявку."}))
                    setConfirmLoading(false);
                })
            })
            .catch((_errorInfo) => {
                setConfirmLoading(false);
            });
    }

    const parseScreenshot = async () => {
        let blob = null
        try {
            blob = await screenshotComponentRef.current.getResultBlob()
        } catch {
            dispatch(setMessage({type: 'warning', text: "Не удалось прикрепить скриншот экрана."}))
        }
        if (blob) return new File([blob], "screenshot.jpg", { lastModified: new Date() })
    }

    const addAttachment = (e) => {
        const files = [...(e.target.files)].filter((el) => canAddFile(el));
        const totalAddedSize = files.reduce(
            (accumulator, currentValue) => accumulator + currentValue.size,
            0
        );
        setAttachments([...attachments, ...files]);
        setTotalAttachmentsSize(totalAttachmentsSize + totalAddedSize);
        e.target.value = null;
    }

    const deleteAttachment = (element, index) => {
        const newSize = totalAttachmentsSize - element.size;
        setTotalAttachmentsSize(newSize);
        if (newSize <= MAX_TOTAL_FILES_SIZE) {
            form.setFields([{name: "attachments", errors: null},]);
        }
        setAttachments(oldAttachments => {
            return oldAttachments.filter((_, i) => i !== index)
        })
    }

    const canAddFile = (file) => {
        if (totalAttachmentsSize + file.size > MAX_TOTAL_FILES_SIZE) {
            form.setFields([{name: "attachments", errors: ["Суммарный размер вложений превышает допустимый предел (50 МБ)"]},]);
            return true
        }
        if (file.size === 0) {
            form.setFields([{name: "attachments", errors: ["Прикрепляемый файл не должен быть пустым!"]},]);
            return false
        }
        if (file.name.includes('..')) {
            form.setFields([{name: "attachments", errors: ["Недопустимое имя файла!"]},]);
            return false
        }
        if (!isFileExtensionAllowed(file.name)) {
            form.setFields([{name: "attachments", errors: ["Недопустимый формат файла!"]},]);
            return false
        }
        form.setFields([{name: "attachments", errors: null},]);
        return true;
    }

    const isFileExtensionAllowed = (fileName) => {
        let fileExtension = "";
        if (fileName.lastIndexOf(".") !== -1) {
            fileExtension = fileName.substring(fileName.lastIndexOf("."));
        }
        return fileExtension.trim().length > 0 && PROHIBITED_FILE_EXTENSIONS.indexOf(fileExtension) === -1;
    }

    const getAttachmentsTotalSize = () => {
        const kiloBytes = totalAttachmentsSize / 1024;
        if (kiloBytes < 1024) {
            return `(${kiloBytes.toFixed(2)} КБ)`;
        } else {
            const megaBytes = kiloBytes / 1024;
            return `(${megaBytes.toFixed(2)} МБ)`;
        }
    }

    const getFileNameForDisplay = (fullFileName) => {
        const lastDotIndex = fullFileName.lastIndexOf(".");
        const extensionStartIndex = Math.min(lastDotIndex + 1, fullFileName.length - 1);
        const fileName = fullFileName.substring(0, lastDotIndex);
        const extension = fullFileName.substring(extensionStartIndex);
        if (fileName.length > 11) {
            return fileName.substring(0, 5) + "..." + fileName.substring(fileName.length - 4, fileName.length) + "." + extension;
        } else {
            return fullFileName;
        }
    }

    return <Modal
        className={classes.bugReportModal}
        title="Сообщить об ошибке"
        open={props.showBugReportModal}
        okText="Отправить"
        onOk={sendBugReportRequest}
        cancelText="Отменить"
        onCancel={() => props.onClose()}
        destroyOnClose={true}
        width={1050}
        centered={true}
        confirmLoading={confirmLoading}
        okButtonProps={{ disabled: isSubmitDisabled }}
    >
        <Form
            form={form}
            name="bugreport"
            scrollToFirstError={true}
            layout={"vertical"}
            initialValues={{
                email: email,
                fio: fio
            }}
            validateTrigger={["onChange", "onBlur"]}
        >
            <Form.Item
                className={classes.bugDescription}
                name="bugDescription"
                label="Описание проблемы"
                rules={[
                    {
                        required: true, message: 'Данное поле является обязательным!'
                    },
                ]}
            >
                <TextArea
                    rows={2}
                    placeholder="Описание проблемы"
                    value={bugDescription}
                    onChange={(e) => setBugDescription(e.target.value.trim())}/>
            </Form.Item>

            <Form.Item
                className={classes.fio}
                name="fio"
                label="ФИО"
                rules={[
                    {
                        required: true, message: 'Данное поле является обязательным!'
                    },
                ]}
            >
                <Input
                    value={fio}
                    onChange={(e) => setFio(e.target.value.trim())}/>
            </Form.Item>

            <Form.Item
                className={classes.email}
                name="email"
                label="Почта"
                rules={[
                    {
                        required: true, message: 'Данное поле является обязательным!'
                    },
                    {
                        pattern: EMAIL_REGULAR_EXPRESSION,
                        message: 'Некорректный формат электронной почты!'
                    }
                ]}>
                <Input
                    placeholder="Почта"
                    value={email}
                    onChange={(e) => setEmail(e.target.value.trim())}/>
            </Form.Item>

            <Form.Item
                className={classes.attachments}
                name="attachments"
                label="Вложения"
            >
                <input
                    style={{display: "none"}}
                    type={"file"}
                    multiple
                    ref={inputFile}
                    onChange={(e) => addAttachment(e)}
                    accept={ALLOWED_FILE_EXTENSIONS.join(",")}
                />
                <div className={classes.buttonWithMessage}>
                    <Button
                        onClick={() => inputFile.current.click()}
                    >
                        Загрузить файлы
                    </Button>
                    {attachments.length > 0 &&
                        <span>
                            Всего вложений: {attachments.length} {getAttachmentsTotalSize()}
                        </span>
                    }
                </div>
                {attachments.length > 0 &&
                    <div className={classes.selectedFiles}>
                        {attachments.map((value, index) => {
                            return <span style={{display: "flex", alignItems: "center"}} title={value.name}>
                                    {getFileNameForDisplay(value.name)}
                                <FaTimes style={{marginLeft: "3px"}} size={14}
                                         onClick={() => deleteAttachment(value, index)} title="Удалить файл"/>
                                </span>
                        })
                        }
                    </div>
                }
            </Form.Item>
        </Form>

        {screenshot
            ? <div className={classes.imageContainer}>
                {screenshot && <DrawingTools screenshot={screenshot} ref={screenshotComponentRef}/>}
            </div>
            : null}
    </Modal>
}

export default BugReportModal;
