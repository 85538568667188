import {Button, Modal, Pagination, Radio, Space} from 'antd';
import axios from '../../../services/axios';
import {useEffect, useState} from 'react';
import AnnouncementCard from './AnnouncementCard/AnnouncementCard';
import classes from './AnnouncementMobile.module.scss';
import {useDispatch} from 'react-redux';
import {setMessage} from '../../../store/actions';
import Loader from '../../UI/Loader/Loader';
import {TbPlus} from 'react-icons/tb';
import AnnouncementDialog from './AddEditAnnouncementDialog/AnnouncementDialog';
import {AiFillExclamationCircle} from "react-icons/ai";
import {deleteAnnouncement} from "../../../store/actions/announcements";


const AnnouncementMobile = () => {

    const ITEMS_PER_PAGE = 10;

    const dispatch = useDispatch();

    const [totalItems, setTotalItems] = useState(null);
    const [pageData, setPageData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);

    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const [selectedItem, setSelectedItem] = useState();
    const [openToEdit, setOpenToEdit] = useState(false);

    const [isDeleteRepeatConfirm, setIsDeleteRepeatConfirm] = useState(false)
    const [repeatedTaskCount, setRepeatedTaskCount] = useState('one')

    useEffect(() => {
        const params = {
            page: 0,
            size: ITEMS_PER_PAGE,
            sort: 'date,desc'
        }
        loadPage(params);
    }, [])

    const handlePageChange = (page, pageSize) => {
        loadPage({
            page: page - 1,
            size: pageSize,
            sort: 'date,desc'
        });
    }

    const handleOk = () => {
        setIsDialogOpen(false);
        setSelectedItem(null);
        loadPage({
            page: currentPage - 1,
            size: ITEMS_PER_PAGE,
            sort: 'date,desc'
        });
    }

    const loadPage = (params) => {
        setIsLoading(true);
        axios.get('/announcements', { params: params })
            .then(response => {
                setPageData(response.data.content);
                setTotalItems(response.data.totalElements);
                setCurrentPage(response.data.number + 1);
            })
            .catch(_error => {
                dispatch(setMessage({ type: 'error', text: 'Не удалось загрузить объявления.' }));
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const getAnnouncementEndTime = (item) => {
        const announcementEndTime = new Date(Date.parse(item.date));
        if (item.endTime) {
            const colonIndex = item.endTime.indexOf(":");
            const hours = +item.endTime.substring(0, colonIndex);
            const minutes = +item.endTime.substring(colonIndex + 1);
            announcementEndTime.setHours(hours, minutes);
        }
        return announcementEndTime;
    }

    const getDeleteDialogButton = (isEditRepeatAnnouncement) => {
        let resultClick = isEditRepeatAnnouncement
            ? () => setIsDeleteRepeatConfirm(true)
            : () => dispatch(deleteAnnouncement(selectedItem.id, repeatedTaskCount === 'all', handleOk))
        return <Button danger className={classes.deleteButton} onClick={resultClick}>
            Удалить
        </Button>
    }

    const getDeleteModal = (isRepeatAnnouncement, announcement) => {
        if (isRepeatAnnouncement) setIsDeleteRepeatConfirm(true)
        else getDeleteOneModal(announcement)
    }

    const getDeleteOneModal = (announcement) => {
        Modal.confirm({
            title: 'Вы действительно хотите удалить?',
            centered: true,
            onOk() {
                dispatch(deleteAnnouncement(announcement.id, repeatedTaskCount === 'all', handleOk))
            }
        });
    }

    const getDeleteRepeatModal = () => {
        return <Modal centered
                      okButtonProps={{style: {height: '35px'}}}
                      cancelButtonProps={{style: {height: '35px'}}}
                      title={<div className={classes.deleteModalContainer}>
                          <AiFillExclamationCircle size={25} className={classes.iconDeleteModal}/>
                          <span className={classes.titleDeleteModal}>Удаление повторяющегося объявления</span>
                      </div>}
                      open={true}
                      closable={false}
                      onOk={() => {
                          dispatch(deleteAnnouncement(selectedItem.id, repeatedTaskCount === 'all', handleOk))
                          setIsDeleteRepeatConfirm(false)
                      }}
                      onCancel={() => setIsDeleteRepeatConfirm(false)}
        >
            <Radio.Group onChange={(e) => setRepeatedTaskCount(e.target.value)} value={repeatedTaskCount}>
                <Space direction="vertical">
                    <Radio className={classes.radioIcon} value='one'>это объявление</Radio>
                    <Radio className={classes.radioIcon} value='all'>все объявления</Radio>
                </Space>
            </Radio.Group>
        </Modal>
    }

    return (<div className={classes.container}>
        <Loader loading={isLoading}></Loader>
        <div className={classes.header}>
            <Button className={classes.addButton} icon={<TbPlus size={18} />}
                onClick={(_e) => {
                    setOpenToEdit(false);
                    setSelectedItem(null);
                    setIsDialogOpen(true);
                }}>
                Добавить
            </Button>
        </div>
        <div className={classes.cards}>
            {
                pageData?.map(item => <AnnouncementCard
                    key={item.id}
                    handleClick={() => {
                        setOpenToEdit(true);
                        setSelectedItem(item);
                        setIsDialogOpen(true);
                    }}
                    handleCopy={() => {
                        setOpenToEdit(false)
                        setSelectedItem(item)
                        setIsDialogOpen(true)
                    }}
                    handleDelete={(isRepeatAnnouncement) => {
                        setSelectedItem(item)
                        getDeleteModal(isRepeatAnnouncement, item)
                    }}
                    item={item}
                    alreadyPassed={(getAnnouncementEndTime(item) < new Date())}
                    isRepeatAnnouncement={item.repeatGroup && pageData.filter(an => an.repeatGroup === item.repeatGroup).length > 1}/>)
            }
        </div>
        <Pagination 
            className={classes.pagination}
            current={currentPage}
            defaultCurrent={1}
            pageSize={ITEMS_PER_PAGE}
            total={totalItems}
            onChange={(page, pageSize) => handlePageChange(page, pageSize)} />

            {isDialogOpen && <AnnouncementDialog
                edit={openToEdit}
                {...selectedItem}
                announcements={pageData}
                getDeleteButton={getDeleteDialogButton}
                onOk={handleOk}
                onCancel={() => setIsDialogOpen(false)}/>}
            {isDeleteRepeatConfirm && getDeleteRepeatModal()}
            {
                (pageData && pageData.length === 0) &&
                <div className={classes.noAnnouncements}>
                    Нет объявлений
                </div>
            }
    </div>
    );
}

export default AnnouncementMobile;