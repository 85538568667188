import * as actionTypes from '../actions/actionTypes';

const initialState = {
    message: null,
    isSidebarDesktopCollapsed: true,
    ifServerUnavailable: false,
};

const setMessage = (state, action) => {
    if (action.message) {
        let tmp = { ...action.message }
        return {
            ...state,
            message: tmp,
        }
    } else {
        return {
            ...state,
            message: null,
        }
    }
};

const setSidebarDesktopCollapsed = (state, action) => {
    return {
        ...state,
        isSidebarDesktopCollapsed: action.isSidebarDesktopCollapsed
    }
}

const setServerError = (state, action) => {
    return {
        ...state,
        ifServerUnavailable: action.ifServerUnavailable
    }
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_MESSAGE: return setMessage(state, action);   
        case actionTypes.SET_SIDEBAR_DESKTOP_COLLAPSED: return setSidebarDesktopCollapsed(state, action);
        case actionTypes.SET_SERVER_UNAVAILABLE: return setServerError( state, action );
        default: return state;
    }
};
export default reducer;