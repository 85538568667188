import classes from './ContainerWithIconHeader.module.scss'

const ContainerWithIconHeader = ({ children, icon, title, containerStyles, footer, color }) => {
    return <div
        className={classes.container}
        style={{ ...containerStyles }}>
        <div
            className={classes.headerIcon}
            style={{ backgroundColor: color ? color : '#3A547F' }}>
            {icon}
        </div>
        <div className={classes.header}>{title}</div>
        <div className={classes.content}>{children}</div>
        {footer && <div className={classes.footer}>
            {footer}
        </div>}
    </div>
}

export default ContainerWithIconHeader;