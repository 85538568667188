import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { AiFillUnlock } from "react-icons/ai";
import { Form, Skeleton, Spin } from 'antd';
import ReactCodeInput from "react-code-input";

import classes from './CreatePasswordViaCode.module.scss';

import axios from '../../../../services/axios'
import { setMessage } from '../../../../store/actions';
import { BAN_DURATION, CODE_LENGTH, CODE_LIFETIME } from '../../../../services/constants';

import PasswordRecoveryWrapper from '../PasswordRecoveryWrapper';
import SubmitButton from '../../../UI/SubmitButton/SubmitButton';
import CountdownTimer from '../../../UI/CountdownTimer/CountdownTimer';
import { passwordPattern, ConfirmPassword, PasswordRequirements } from '../../../UI/ConfirmPassword/ConfirmPassword';

const CreatePasswordViaCode = () => {
    const navigate = useNavigate()
    const location = useLocation()

    const [contact, setContact] = useState(null)
    const [newPassword, setNewPassword] = useState('')
    const [confirmedPassword, setConfirmedPassword] = useState('')
    const [confirmationCode, setConfirmationCode] = useState('')
    const [codeRequestLoading, setCodeRequestLoading] = useState(false)
    const [createPasswordLoading, setCreatePasswordLoading] = useState(false)
    const [isConfirmationCodeValid, setIsConfirmationCodeValid] = useState(true)
    const [isConfirmationCodeSend, setIsConfirmationCodeSend] = useState(false)
    const [confirmationCodeExpirationTime, setConfirmationCodeExpirationTime] = useState(null)
    const [ifUnmount, setIfUnmount] = useState(false)
    const [numberOfAttempts, setNumberOfAttempts] = useState(4)

    const dispatch = useDispatch()

    useEffect(() => {
        const unloadCallback = (event) => {
            event.preventDefault();
            event.returnValue = "";
            return "";
        };

        window.addEventListener("beforeunload", unloadCallback);
        return () => window.removeEventListener("beforeunload", unloadCallback);
    }, []);

    useEffect(() => {
        if (location?.state?.prevPath !== '/password-recovery/mask')
            navigate("/password-recovery", { replace: true })
    }, [location, navigate])

    useEffect(() => {
        if (numberOfAttempts === 0) 
            navigate("/password-recovery/error", {
                replace: true,
                state: { time: new Date(new Date().getTime() + BAN_DURATION) }
            })
    }, [numberOfAttempts, navigate])

    useEffect(() => {
        if (location?.state?.fullContactValue && location?.state?.login && location?.state?.contact) {
            axios.post('/password-reset/phone-exists', {
                contactValue: location.state.fullContactValue,
                login: location.state.login
            })
                .then((response) => {
                    setNumberOfAttempts(response.data.remainingAttempts)
                    setContact(location.state.contact)
                    if (response.data.codeExpirationTime) {
                        setIsConfirmationCodeSend(true)
                        setConfirmationCodeExpirationTime(Date.parse(response.data.codeExpirationTime))
                    } else {
                        setIsConfirmationCodeSend(false)
                        setConfirmationCodeExpirationTime(null)
                    }
                })
                .catch((error) => {
                    if (error?.response?.status === 429 && error?.response?.data.banExpirationTime)
                        navigate("/password-recovery/error", {
                            replace: true,
                            state: { time: error.response.data.banExpirationTime ?? new Date(new Date().getTime() + BAN_DURATION) }
                        })
                    else dispatch(setMessage({ type: 'warning', text: "Не удалось подключиться к серверу." }))
                })
        } else navigate("/password-recovery", { replace: true })
    }, [location, navigate, dispatch])

    const sendConfirmationCode = () => {
        if (contact && location.state.login && location.state.fullContactValue) {
            setIsConfirmationCodeValid(true)
            setCodeRequestLoading(true)
            setIsConfirmationCodeSend(false)

            axios.post('/password-reset/send-confirm', {
                login: location.state.login,
                contactValue: location.state.fullContactValue,
                contactTypeId: contact.typeId
            })
                .then(() => {
                    setCodeRequestLoading(false)
                    setNumberOfAttempts(oldValue => oldValue - 1)
                    setConfirmationCodeExpirationTime(new Date(new Date().getTime() + CODE_LIFETIME))
                    setIsConfirmationCodeSend(true)
                    dispatch(setMessage({ type: 'success', text: `Код подтверждения отправлен на указанный ${location.state.contact.name.toLowerCase()}` }))
                })
                .catch((error) => {
                    setCodeRequestLoading(false)
                    setIsConfirmationCodeSend(false)
                    if (error?.response?.status === 429 && error?.response?.data.banExpirationTime)
                        navigate("/password-recovery/error", {
                            replace: true,
                            state: { time: error.response.data.banExpirationTime ?? new Date(new Date().getTime() + BAN_DURATION) }
                        })
                    else dispatch(setMessage({ type: 'error', text: "Не удалось отправить код подтверждения." }))
                })
        } else dispatch(setMessage({ type: 'error', text: "Не удалось подключиться к серверу." }))
    }

    const createPasswordViaCodeRequest = () => {
        if (contact && location.state.login && location.state.fullContactValue) {
            setCreatePasswordLoading(true)
            axios.post('/password-reset/reset', {
                confirmCode: confirmationCode,
                newPassword: newPassword,
                login: location.state.login,
                contactValue: location.state.fullContactValue
            })
                .then(() => {
                    setCreatePasswordLoading(false)
                    setIfUnmount(true)
                    setTimeout(() => {
                        navigate("/password-recovery/success", {
                            replace: true,
                            state: { prevPath: location.pathname }
                        })
                    }, 400);
                })
                .catch((error) => {
                    setCreatePasswordLoading(false)
                    setNumberOfAttempts(oldValue => oldValue - 1)
                    switch (error.response.status) {
                        case 409:
                            setIsConfirmationCodeValid(false)
                            dispatch(setMessage({ type: 'error', text: 'Неверный код. Пожалуйста, введите код, который Вы только что получили.' }))
                            break;
                        case 410:
                            setIsConfirmationCodeValid(false)
                            setIsConfirmationCodeSend(true)
                            dispatch(setMessage({ type: 'error', text: 'Код подтверждения истек.' }))
                            break;
                        case 429:
                            navigate("/password-recovery/error", {
                                replace: true,
                                state: { time: error.response.data.banExpirationTime ?? new Date(new Date().getTime() + BAN_DURATION) }
                            })
                            break;
                        default:
                            dispatch(setMessage({ type: 'error', text: 'Не удалось создать пароль.' }))
                    }
                })
        } else dispatch(setMessage({ type: 'error', text: "Не удалось подключиться к серверу." }))
    }

    const onCodeExpireHandler = () => {
        setConfirmationCodeExpirationTime(0)
        setIsConfirmationCodeSend(false)
        setConfirmationCode('')
        dispatch(setMessage({ type: 'error', text: "Срок действия кода подтверждения истек." }))
        if (numberOfAttempts <= 1)
            navigate("/password-recovery/error", {
                replace: true,
                state: { time: new Date(new Date().getTime() + BAN_DURATION) }
            })
    }

    const setCodeRequestButtonText = () => {
        if (codeRequestLoading && !isConfirmationCodeSend) return <Spin size='xs' />
        else return <React.Fragment>Получить код</React.Fragment>
    }

    return (
        <PasswordRecoveryWrapper icon={<AiFillUnlock size={35} />} title="Создание нового пароля">
            <div className={!ifUnmount ? classes.container : classes[`container--unmounted`]}>
                {!contact && <Skeleton active />}
                {contact && <Form
                    name="new_password"
                    scrollToFirstError={true}
                    labelCol={{ span: 7 }}
                    labelAlign="left"
                    labelWrap
                    colon={false}
                    onFinish={createPasswordViaCodeRequest}>

                    <PasswordRequirements />

                    <ConfirmPassword
                        newPassword={newPassword}
                        confirmedPassword={confirmedPassword}
                        setNewPassword={(e) => setNewPassword(e.target.value.trim())}
                        setConfirmedPassword={(e) => setConfirmedPassword(e.target.value.trim())} />

                    <Form.Item
                        name="code"
                        label="Код"
                        rules={[
                            { required: true, message: 'Данное поле является обязательным!' },
                        ]}>
                        <div className={classes.codeContainer}>
                            <div key={isConfirmationCodeSend}>
                                <ReactCodeInput
                                    id="confirmationCode"
                                    type="number"
                                    inputMode="numeric"
                                    className={isConfirmationCodeValid ? classes.codeInputContainer : classes.codeInputContainerInvalid}
                                    fields={CODE_LENGTH}
                                    autoFocus={false}
                                    disabled={!isConfirmationCodeSend}
                                    isValid={isConfirmationCodeValid}
                                    onChange={(value) => setConfirmationCode(value)}
                                    value={confirmationCode}
                                />
                                <div className={classes.attemptsContainer}>
                                    {`Осталось попыток: `}
                                    <span className={classes.errorMessage}>{numberOfAttempts}</span>
                                </div>
                            </div>
                            {
                                !isConfirmationCodeSend
                                    ? <SubmitButton
                                        type="button"
                                        text={setCodeRequestButtonText()}
                                        disabled={!(passwordPattern.test(newPassword) && (newPassword === confirmedPassword)) || (codeRequestLoading && !isConfirmationCodeSend)}
                                        onClickHandler={() => sendConfirmationCode()}
                                    />
                                    : <div className={classes.timerContainer}>
                                        {confirmationCodeExpirationTime && <CountdownTimer targetDate={confirmationCodeExpirationTime} onExpireHandler={() => onCodeExpireHandler()} />}
                                    </div>
                            }
                        </div>
                    </Form.Item>

                    <Form.Item>
                        <SubmitButton
                            type="submit"
                            text="Создать"
                            block
                            disabled={!(passwordPattern.test(newPassword) && (newPassword === confirmedPassword) && confirmationCode.length === CODE_LENGTH)}
                            loading={createPasswordLoading}
                        />
                    </Form.Item>
                </Form>}
            </div>
        </PasswordRecoveryWrapper>
    )
};

export default CreatePasswordViaCode;