import * as actionTypes from '../actions/actionTypes';

const initialState = {
    announcementsData: null,
    loadingAnnouncements: false,
    updatingAnnouncement: false,
};

const loadAnnouncements = (state) => {
    return {
        ...state,
        loadingAnnouncements: true
    }
}

const setAnnouncements = (state, action) => {
    let tmp = {...action.announcementsData}
    return {
        ...state,
        announcementsData: tmp,
        loadingAnnouncements: false
    }
};

const startUpdatingAnnouncement = (state) => {
    return {
        ...state,
        updatingAnnouncement: true
    }
}

const endUpdatingAnnouncement = (state) => {
    return {
        ...state,
        updatingAnnouncement: false
    }
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOAD_ANNOUNCEMENTS: return loadAnnouncements(state);
        case actionTypes.SET_ANNOUNCEMENTS: return setAnnouncements(state, action);
        case actionTypes.START_UPDATING_ANNOUNCEMENT: return startUpdatingAnnouncement(state);
        case actionTypes.END_UPDATING_ANNOUNCEMENT: return endUpdatingAnnouncement(state);
        default: return state;
    }
};
export default reducer;