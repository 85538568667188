import * as actionTypes from './actionTypes';
import axios from '../../services/axios';
import {setMessage} from "./common";

export const loadEmployeeDetails = () => {
    return {
        type: actionTypes.LOAD_EMPLOYEE_DETAILS
    }
}

export const setEmployeeDetails = (employeeDetails) => {
    return {
        type: actionTypes.SET_EMPLOYEE_DETAILS,
        employeeDetails: employeeDetails
    }
}

export const getEmployeeDetails = () => {
    return (dispatch) => {
        dispatch(loadEmployeeDetails())
        axios.get(`/personal-page`)
            .then(response => {
                dispatch(setEmployeeDetails(response.data))
            })
            .catch(_error => {
                dispatch(setMessage({type: 'warning', text: "Не удалось подключиться к серверу."}))
            });
    }
}

export const saveProfileLinkRequest = (link, dto) => {
    return (dispatch) => {
        axios.post(`/personal-page/add-profile-link`, link)
            .then(response => {
                dispatch(setEmployeeDetails({...dto, profileLinks: response.data}))
                dispatch(setMessage({type: 'success', text: "Ссылка успешно добавлена."}))
            })
            .catch(_error => {
                dispatch(setMessage({type: 'warning', text: "Не удалось добавить ссылку."}))
            });
    }
}

export const deleteProfileLinkRequest = (profileLinkEmployeeId) => {
    return (dispatch) => {
        axios.delete(`/personal-page/delete-profile-link`, {params: {profileLinkEmployeeId}})
            .then(_response => {
                dispatch(setMessage({type: 'success', text: "Ссылка успешно удалена."}))
            })
            .catch(_error => {
                dispatch(setMessage({type: 'warning', text: "Не удалось удалить ссылку."}))
            });
    }
}

export const updateAdditionalInformationRequest = (dto, additionalInformation) => {
    return (dispatch) => {
        axios.put(`/personal-page/additional-information`, additionalInformation)
            .then(response => {
                dispatch(setEmployeeDetails({...dto, additionalInformation: response.data}))
                dispatch(setMessage({type: 'success', text: "Дополнительная информация успешно обновлена."}))
            })
            .catch(_error => {
                dispatch(setMessage({type: 'warning', text: "Не удалось обновить дополнительную информацию."}))
            });
    }
}

export const loadPhones = () => {
    return {
        type: actionTypes.LOAD_PHONES
    }
}

export const setPhones = (phonesData) => {
    return {
        type: actionTypes.SET_PHONES,
        phonesData: phonesData
    }
}

export const getPhones = () => {
    return (dispatch) => {
        dispatch(loadPhones)
        axios.get(`/personal-page/phones`)
            .then(response => {
                dispatch(setPhones(response.data))
            })
            .catch(_error => {
                dispatch(setMessage({type: 'warning', text: "Не удалось подключиться к серверу."}))
            });
    }
}

export const loadFormerJobPositions = () => {
    return {
        type: actionTypes.LOAD_FORMER_JOB_POSITIONS
    }
}

export const setFormerJobPositions = (formerJobPositionsData) => {
    return {
        type: actionTypes.SET_FORMER_JOB_POSITIONS,
        formerJobPositionsData
    }
}

export const getFormerJobPositions = () => {
    return (dispatch) => {
        dispatch(loadFormerJobPositions)
        axios.get(`/personal-page/former-job-positions`)
            .then(response => {
                dispatch(setFormerJobPositions(response.data))
            })
            .catch(_error => {
                dispatch(setMessage({type: 'warning', text: "Не удалось подключиться к серверу."}))
            });
    }
}

export const addPhoneRequest = (jobPosition) => {
    return (dispatch) => {
        axios.post(`/personal-page/add-phone`, jobPosition)
            .then(_response => {
                dispatch(setMessage({type: 'success', text: "Номер телефона успешно добавлен."}))
            })
            .catch(_error => {
                dispatch(setMessage({type: 'warning', text: "Не удалось добавить номер телефона."}))
            });
    }
}

export const deletePhoneRequest = (jobPosition) => {
    console.log(jobPosition)
    return (dispatch) => {
        axios.delete(`/personal-page/delete-phone`, { data: jobPosition })
            .then(_response => {
                dispatch(setMessage({type: 'success', text: "Номер телефона успешно удален."}))
            })
            .catch(_error => {
                dispatch(setMessage({type: 'warning', text: "Не удалось удалить номер телефона."}))
            });
    }
}