import {Input} from "antd";
import {useEffect, useRef, useState} from "react";
import useLongPress from "../../../hooks/longPress";

const NumberInput = (props) => {

    const [value, setValue] = useState(props.value);
    const inputRef = useRef(null);

    useEffect(() => {
        setValue(props.value);
    }, [props.value])

    useEffect(() => {
        if (props.needToFocus && inputRef) {
            inputRef.current.focus();
        }
    }, [props.needToFocus]);

    const isNumberInRange = (input) => {
        if (input.length > 1 && input[0] == '0') {
            return false;
        }
        return input <= props.max && input >= props.min;
    }

    const longPressEvent = useLongPress(() => {
        if (props.onLongPress) {
            props.onLongPress();
        }
    }, () => {
        if (props.onClick) {
            props.onClick();
        }
    });

    return <Input type='number'
                  className={props.className}
                  style={props.style}
                  disabled={props.disabled}
                  value={value}
                  status={props.status}
                  onChange={(e) => {
                      if (isNumberInRange(e.target.value)) {
                          setValue(e.target.value);
                          if (props.onChange) {
                              props.onChange(e);
                          }
                      }
                  }}
                  ref={inputRef}
                  {...longPressEvent}>
    </Input>
}

export default NumberInput;