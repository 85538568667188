import React, { useState } from "react";
import classes from './DateButton.module.scss';
import { getStatusOfDate } from "../../../services/util";

const DateButton = ({
    date,
    showStatusOfDate = false,
    onAction,
    isWide,
}) => {
    const [dateButtonAnimation, setDateButtonAnimation] = useState(null);

    return (
        <div className={isWide && classes.wide} >
            <button
                className={`${classes.dateButton} ${dateButtonAnimation} ${isWide && classes.bold}`}
                onAnimationEnd={() => {
                    onAction();
                    setDateButtonAnimation(null);
                }}
                onClick={() => setDateButtonAnimation(classes.dateButtonAnimation)}
            >
                {date}
            </button>
            {
                showStatusOfDate &&
                <div className={classes.statusDate}>
                    {getStatusOfDate(date)}
                </div>
            }
        </div>
    )
}

export default DateButton;