import * as actionTypes from './actionTypes';
import axios from '../../services/axios';
import {setMessage} from "./common";

export const loadUsers = () => {
    return {
        type: actionTypes.LOAD_USERS
    }
}

export const setUsers = (usersData) => {
    return {
        type: actionTypes.SET_USERS,
        usersData: usersData
    }
}

export const getUsers = (usersFilterDto) => {
    return (dispatch) => {
        dispatch(loadUsers())
        axios.post(`/users`, usersFilterDto)
            .then(response => {
                dispatch(setUsers(response.data))
            })
            .catch(() => {
                dispatch(setUsers(null))
                dispatch(setMessage({type: 'warning', text: "Не удалось получить данные."}))
            })
    }
}