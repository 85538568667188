import {useCallback, useRef, useState} from "react";

const useLongPress = (onLongPress, onClick, delay = 700) => {
    const [longPressTriggered, setLongPressTriggered] = useState(false);
    const timeout = useRef();

    const start = useCallback(
        (e) => {
            window.onscroll = (_e) => {
                clear();
            }
            timeout.current = setTimeout(() => {
                onLongPress();
                e.target.blur();
                setLongPressTriggered(true);
            }, delay);
        },
        [onLongPress, delay]
    );

    const clear = useCallback(
        (shouldTriggerClick = true) => {
            if (timeout.current) {
                clearTimeout(timeout.current);
            }
            if (shouldTriggerClick && !longPressTriggered && onClick) {
                onClick();
            }
            setLongPressTriggered(false);
        },
        [onClick, longPressTriggered]
    );

    return {
        onMouseDown: e => start(e),
        onTouchStart: e => start(e),
        onMouseUp: _e => clear(),
        onMouseLeave: _e => clear(false),
        onTouchEnd: _e => clear(),
        onSelect: e => e.preventDefault()
    };
};

export default useLongPress;