import { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { MdArrowForwardIos } from "react-icons/md";
import { Tooltip } from 'antd';

import classes from './Sidebar.module.scss'
import { createSidebarNavigationItems } from '../Menus';
import {useSelector, useDispatch} from "react-redux";
import {useCookies} from "react-cookie";
import {getMonthExpirationDate} from "../../../services/dateUtils";
import { setSidebarDesktopCollapsed } from '../../../store/actions/common';

const SidebarDesktop = () => {
    const dispatch = useDispatch();
    const [cookies, setCookie] = useCookies(["toggleButton"])
    const isCollapsed = useSelector(state => state.common.isSidebarDesktopCollapsed)
    const user = useSelector(state => state.auth.user);

    useEffect(() => {
        dispatch(setSidebarDesktopCollapsed(cookies.toggleButton && cookies.toggleButton.isCollapsed !== null ? cookies.toggleButton.isCollapsed : true))
    }, [])

    return <div className={isCollapsed ? classes['container--closed'] : classes['container--opened']}>
        <div className={classes.navigationItems}>
            {
                createSidebarNavigationItems(user).map((navItem) =>
                    isCollapsed
                        ? <Tooltip
                            title={navItem.text}
                            placement="right"
                            key={navItem.path}
                            overlayClassName='sidebar-desktop-tooltip'>
                            <NavLink to={navItem.path} className={({ isActive }) => isActive ? classes.selectedNavigationItem : null}>
                                <div className={classes.navigationItemIcon}>{navItem.icon}</div>
                                <div className={classes.navigationItemText}>{navItem.text}</div>
                            </NavLink>
                        </Tooltip>
                        : <NavLink to={navItem.path} className={({ isActive }) => isActive ? classes.selectedNavigationItem : null} key={navItem.path}>
                        <div className={classes.navigationItemIcon}>{navItem.icon}</div>
                        <div className={classes.navigationItemText}>{navItem.text}</div>
                    </NavLink>
                )
            }
        </div>

        <div className={classes.toggleContainer}>
            <button
                aria-label="collapse sidebar"
                onClick={() => {
                    dispatch(setSidebarDesktopCollapsed(!isCollapsed))
                    setCookie("toggleButton", JSON.stringify({isCollapsed: !isCollapsed}), {
                        path: "/",
                        expires: getMonthExpirationDate()
                    });
                }}>
                <MdArrowForwardIos className={isCollapsed ? null : classes.toggleArrow} />
            </button>
        </div>
    </div>
}

export default SidebarDesktop;