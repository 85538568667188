import classes from './OmissionNoteInputModal.module.scss';
import {Modal} from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, {useEffect, useState} from "react";

const OmissionNoteInputModal = (props) => {

    const [noteText, setNoteText] = useState(null);

    useEffect(() => {
        setNoteText(props.omissionParams?.omission.note);
    }, [props.omissionParams, props.isOpen]);

    const updateOmission = () => {
        if (props.updateOmission && noteText !== props.omissionParams.note) {
            const updatedOmission = {
                lessonId: props.omissionParams.lessonId,
                studentIds: [props.omissionParams.studentId],
                missedHours: props.omissionParams.omission.number,
                note: noteText
            };
            props.updateOmission(props.omissionParams.studentId, updatedOmission);
        }
    }

    return <div>
        <Modal title={"Добавить примечание"}
               open={props.isOpen}
               onOk={() => {
                   updateOmission();
                   if (props.onOk) {
                       props.onOk();
                   }
               }}
               onCancel={props.onCancel}
               centered
               destroyOnClose={true}
               wrapClassName={classes.container}>

            <TextArea className={classes.noteInput}
                      rows={3}
                      placeholder='Примечание'
                      value={noteText}
                      onChange={(e) => {
                          setNoteText(e.target.value);
                      }}/>
        </Modal>
    </div>
}

export default OmissionNoteInputModal;