import React, {useState} from 'react';
import { Tabs } from 'antd';
import {IoCamera, IoLockClosedOutline} from "react-icons/io5";
import { IoIosPhonePortrait } from "react-icons/io";

import classes from './ProfileSettings.module.scss'
import useWindowDimensions from '../../hooks/windowDimensions';

import ChangePassword from './ChangePassword/ChangePassword';
import ManageContacts from './ManageContacts/ManageContacts';
import ChangePhoto from "./ChangePhoto/ChangePhoto";

const ProfileSettings = () => {
    const { width } = useWindowDimensions()

    const [activeTab, setActiveTab] = useState(1)

    const tabs = [
        {
            key: 0,
            label: 'Пароль',
            icon: <IoLockClosedOutline />,
            contentTitle: "Изменение пароля",
            content: <ChangePassword />
        },
        {
            key: 1,
            label: 'Контакты',
            icon: <IoIosPhonePortrait />,
            contentTitle: "Редактирование контактных данных",
            content: <ManageContacts />
        },
        {
            key: 2,
            label: 'Фото',
            icon: <IoCamera />,
            contentTitle: "Загрузить фото",
            content: <ChangePhoto />
        }
    ]

    return <div className={classes.container}>
        <Tabs
            centered={width <= 500}
            className={classes.tabList}
            defaultActiveKey={activeTab}
            onChange={(key) => setActiveTab(parseInt(key, 10))}
            items={tabs.map(tab => ({
                key: tab.key,
                label: <span>{tab.icon}{tab.label}</span>,
                children: tab.content
            }))
            }
        />
    </div>
}

export default ProfileSettings;