import * as actionTypes from '../actions/actionTypes';

const initialState = {
    positionsData: null,
    loadingPositions: false,
};

const loadPositions = (state) => {
    return {
        ...state,
        loadingPositions: true
    }
}

const setPositions = (state, action) => {
    let tmp = [...action.positionsData]
    return {
        ...state,
        positionsData: tmp,
        loadingPositions: false
    }
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOAD_POSITIONS: return loadPositions(state);
        case actionTypes.SET_POSITIONS: return setPositions(state, action);
        default: return state;
    }
};
export default reducer;