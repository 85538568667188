import React from "react";
import { Select } from "antd";
import classes from "./GroupedSelector.module.scss";

function isOptionIncluded(inputValue, option) {
    if (option.filterValue === null || option.filterValue === undefined) { 
        return false;
    }
    const inputValueParts = inputValue.split(' ');
    for (const part of inputValueParts) {
        if (!option.filterValue.toLowerCase().includes(part.toLowerCase())) {
            return false;
        }
    }
    return true;
}

const GroupedSelector = ({
    groupedOptions,
    className,
    placeholder,
    notFoundContent,
    onChange,
    value,
    disabled,
    showSearch,
}) => {
    return (
        <Select
            className={className}
            placeholder={placeholder}
            optionLabelProp="title"
            notFoundContent={notFoundContent}
            onChange={onChange}
            value={value}
            disabled={disabled}
            showSearch={showSearch}
            filterOption={isOptionIncluded}
        >
            {groupedOptions.map(item => {
                const optGroupClass = item && item.options.some(option => option.value === value) ? classes.groupSelected : undefined
                return (
                    <Select.OptGroup label={<div className={optGroupClass}>{item?.label}</div>} key={item?.label}>
                        {
                            item && item.options.map(option =>
                                <Select.Option key={option.value} title={option.title} value={option.value} filterValue={option.filterValue}>
                                    {option.label}
                                </Select.Option>
                            )
                        }
                    </Select.OptGroup>
                )
            })}
        </Select>
    )
}

export default GroupedSelector