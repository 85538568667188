import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import ContainerWithIconHeader from "../../UI/ContainerWithIconHeader/ContainerWithIconHeader";
import UnderlinedLink from "../../UI/UnderlinedLink/UnderlinedLink";

const PasswordRecoveryWrapper = ({ children, icon, title, color, containerStyles, minHeight = '415px', showFooter = true }) => {
    const user = useSelector(state => state.auth.user)

    const setRedirectPath = () => {
        let redirectPath
        if (user?.authorities.indexOf("ROLE_ADMIN") !== -1) redirectPath = '/admin'
        else redirectPath = '/settings'

        if (!redirectPath) redirectPath = '/'
        return redirectPath
    }

    return (
        !user
            ? <React.Fragment>
                <ContainerWithIconHeader
                    color={color}
                    containerStyles={{ minHeight: minHeight, ...containerStyles }}
                    icon={icon}
                    title={title}
                    footer={showFooter && <React.Fragment>
                        {`Вернуться ко `}
                        <UnderlinedLink routing text="входу в личный кабинет" href="/" />
                    </React.Fragment>}>
                    {children}
                </ContainerWithIconHeader>
            </React.Fragment>
            : <Navigate to={setRedirectPath()} replace />)
}

export default PasswordRecoveryWrapper