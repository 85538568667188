import {MdOutlineAdminPanelSettings, MdOutlineAnnouncement, MdOutlineSettings, MdPersonOutline, MdBusinessCenter} from "react-icons/md";
import {BsJournalCheck} from "react-icons/bs";

export const createSidebarNavigationItems = (user) => {

    const getGradebookPage = () => {
        let tmp = []
        if (user?.anyLessons || haveGradebookPermission())
            tmp.push({
                        text: "Журнал успеваемости",
                        path: `/gradebook`,
                        icon: <BsJournalCheck size={25}/>,
            })
        return tmp
    }

    const haveGradebookPermission = () => {
        return user?.authorities?.includes("ROLE_ADMIN") || user?.authorities?.includes("PERMISSION_JOURNAL_OF_CURRENT_PERFORMANCE_VIEW_ALL_TEACHERS");
    }

    const getAnnouncementsPage = () => {
        let tmp = []
        if (user?.anyLessons || user?.authorities.indexOf("ROLE_ADMIN") !== -1)
            tmp.push({
                        text: "Объявления",
                        path: `/announcements`,
                        icon: <MdOutlineAnnouncement size={25}/>,
            })
        return tmp
    }

    const getUsersPage = () => {
        let tmp = []
        if (user?.authorities.indexOf("ROLE_ADMIN") !== -1)
            tmp.push({
                        text: "Пользователи",
                        path: `/users`,
                        icon: <MdOutlineAdminPanelSettings size={25}/>,
            })
        return tmp
    }

    const getPersonalPage = () => {
        let tmp = []
        tmp.push({
            text: "Личная страница",
            path: `/personal-page`,
            icon: <MdPersonOutline size={25}/>,
        })
        return tmp
    }

    const getPositionsPage = () => {
        let tmp = []
        tmp.push({
            text: "Должности",
            path: `/positions`,
            icon: <MdBusinessCenter size={25}/>,
        })
        return tmp
    }

    return [
        ...getGradebookPage(),
        ...getAnnouncementsPage(),
        ...getUsersPage(),
        ...getPersonalPage(),
        ...getPositionsPage(),
        {
            text: "Настройки",
            path: `/settings`,
            icon: <MdOutlineSettings size={25} />,
        },
    ];
}