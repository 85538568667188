import classes from './AnnouncementDesktop.module.scss';
import {useDispatch, useSelector} from "react-redux";
import {Button, DatePicker, Input, Pagination, Popconfirm, Radio, Space, Table, Tooltip} from "antd";
import React, {useEffect, useState} from "react";
import Spinner from "../../UI/Spinner/Spinner";
import NoSearchResults from "../../../assets/images/NoSearchResults.png";
import {deleteAnnouncement, getAnnouncements} from "../../../store/actions/announcements";
import {TbEdit, TbPlus} from "react-icons/tb";
import {getFullFormattedDateStringFromTimestamp} from "../../../services/dateUtils";
import {IoSearch} from "react-icons/io5";
import EditAnnouncementDialog from "./EditAnnouncementDialog/EditAnnouncementDialog";
import {MdContentCopy, MdDeleteOutline} from "react-icons/md";
import {getFilteredRepeatDates, getRepeatMessage, getRepeatWeeks} from "../../../services/Announcements/announcementsService";
import {BsArrowRepeat} from "react-icons/bs";

const {RangePicker} = DatePicker;

const AnnouncementDesktop = () => {
    const PAGE_SIZE = 20
    const dispatch = useDispatch();
    const announcements = useSelector(state => state.announcements.announcementsData)
    const loadingAnnouncements = useSelector(state => state.announcements.loadingAnnouncements)

    const [currentPage, setCurrentPage] = useState(1)
    const [startDateFilter, setStartDateFilter] = useState(null)
    const [endDateFilter, setEndDateFilter] = useState(null)
    const [searchEvent, setSearchEvent] = useState("")
    const [editAnnouncement, setEditAnnouncement] = useState(null)
    const [isNewAnnouncementDialogOpen, setIsNewAnnouncementDialogOpen] = useState(false);
    const [isEditAnnouncementDialogOpen, setIsEditAnnouncementDialogOpen] = useState(false);
    const [repeatedTaskCount, setRepeatedTaskCount] = useState('one')


    const loadAnnouncement = () => {
        const params = {
            description: searchEvent,
            dateFrom: startDateFilter ? new Date(startDateFilter).toISOString() : null,
            dateTo: endDateFilter ? new Date(endDateFilter).toISOString() : null,
        }
        dispatch(getAnnouncements(params, currentPage - 1, PAGE_SIZE))
    }

    useEffect(() => {
        loadAnnouncement()
    }, [dispatch, startDateFilter, endDateFilter, searchEvent, currentPage])

    const openEditAnnouncementDialog = (row) => {
        if (row.repeatGroup && announcements.content.filter(an => an.repeatGroup === row.repeatGroup).length > 1) setRepeatedTaskCount('all')
        setEditAnnouncement(row)
        setIsEditAnnouncementDialogOpen(true)
    }

    const onDeleteAnnouncement = (id) => {
        dispatch(deleteAnnouncement(id, repeatedTaskCount === 'all', onEditSuccess))
    }

    const clearDialogData = () => {
        setRepeatedTaskCount('one')
        setEditAnnouncement(null)
    }

    const onEditSuccess = () => {
        loadAnnouncement()
        setIsEditAnnouncementDialogOpen(false)
        clearDialogData()
    }

    const handleCopyClick = (row) => {
        setEditAnnouncement(row)
        setIsNewAnnouncementDialogOpen(true)
    }

    const getPopconfirm = (title, button, isDisabledButton, onConfirm, ) => {
        return <Popconfirm placement="topLeft"
                           icon={null}
                           title={<div>
                               <div style={{fontWeight: '600', marginBottom: '5px'}}>{title}</div>
                               <Radio.Group onChange={(e) => setRepeatedTaskCount(e.target.value)}
                                            value={repeatedTaskCount}>
                                   <Space direction="vertical">
                                       <Radio value='one'>это объявление</Radio>
                                       <Radio value='all'>все объявления</Radio>
                                   </Space>
                               </Radio.Group>
                           </div>}
                           okText="Ок"
                           cancelText="Отмена"
                           disabled={isDisabledButton}
                           onConfirm={onConfirm}
        >
            {button}
        </Popconfirm>
    }

    const getDeleteButton = (idAnn, repeatGroup, announcementList, button, buttonWithClick, displayTooltip, isDisabledButton) => {
        let titleTooltip = displayTooltip ? "Удалить" : ''
        return repeatGroup && announcementList.filter(an => an.repeatGroup === repeatGroup).length > 1 ? <Tooltip title={titleTooltip}>
                {getPopconfirm('Удаление повторяющегося объявления', button, isDisabledButton, () => onDeleteAnnouncement(idAnn))}
            </Tooltip>
            : <Tooltip title={titleTooltip}>
                {buttonWithClick}
            </Tooltip>
    }

    const getRepeatTooltipTitle = (row) => {
        let repeatDates = getFilteredRepeatDates(row.repeatGroup, announcements.content)
        return 'Повторяется ' + getRepeatMessage(new Date(row.date), getRepeatWeeks(repeatDates), new Date(repeatDates[repeatDates.length - 1]))
    }

    const columns =
        [
            {
                title: '№',
                dataIndex: 'id',
                width: '5%',
                align: 'center',
                fixed: 'left',
                render: (_, _row, index) => {
                    return {
                        children: <div>{((currentPage - 1) * PAGE_SIZE) + index + 1}</div>
                    }
                }
            },
            {
                title: 'Дата',
                dataIndex: 'date',
                width: '12%',
                align: 'center',
                fixed: 'left',
                render: (date, row) => {
                    return {
                        children: <div className={classes.dateColumn}>
                            <div style={{width: '85px', display: 'inline-block'}}>{getFullFormattedDateStringFromTimestamp(date)}
                                {row.startTime && row.endTime && <div>{`${row.startTime}-${row.endTime}`}</div>}
                            </div>
                            {row.repeatGroup && announcements.content.filter(an => an.repeatGroup === row.repeatGroup).length > 1
                                && <Tooltip className={classes.repeatIcon} placement={"right"}
                                            title={getRepeatTooltipTitle(row)}>
                                    <BsArrowRepeat size={15}/>
                                </Tooltip>}
                        </div>
                    }
                }
            },
            {
                title: 'Аудитория',
                dataIndex: 'auditory',
                width: '8%',
                align: 'center',
                render: (auditory) => {
                    return {
                        children: <div className={classes.auditoryColumn}>{auditory && `${auditory.name}-${auditory.buildingNumber}`}</div>
                    }
                }
            },
            {
                title: 'Событие',
                dataIndex: 'name',
                width: '40%',
                align: 'center',
                render: (name) => {
                    return {
                        children: <div style={{textAlign: 'left'}}>{name}</div>
                    }
                }
            },
            {
                title: 'Группы',
                dataIndex: 'groups',
                width: '25%',
                align: 'center',
                render: (groups) => {
                    return {
                        children: <div className={classes.groupsColumn}>
                            {groups.length !== 0 && groups.map(group => <div className={classes.group}>{group.name}</div>)}
                        </div>
                    }
                }
            },
            {
                title: 'Действия',
                dataIndex: 'id',
                width: '10%',
                align: 'center',
                render: (id, row) => {
                    return {
                        children: <div className={classes.actionsColumn}>
                            <Tooltip title="Редактировать">
                                <Button type={"link"} icon={<TbEdit size={25}/>} onClick={() => openEditAnnouncementDialog(row)}></Button>
                            </Tooltip>
                            <Tooltip title="Копировать">
                                <Button type={"link"} icon={<MdContentCopy size={24}/>} onClick={() => handleCopyClick(row)}></Button>
                            </Tooltip>
                            {getDeleteButton(id, row.repeatGroup, announcements.content,
                                <Button type={"link"} danger icon={<MdDeleteOutline size={25}/>}/>,
                                <Button type={"link"} danger icon={<MdDeleteOutline size={25}/>} onClick={() => onDeleteAnnouncement(id)}/>, true, false)}
                        </div>
                    }
                }
            },
        ]

    const handleDateFilter = (dates) => {
        setCurrentPage(1)
        if (dates === null) {
            setStartDateFilter(null)
            setEndDateFilter(null)
        } else {
            setStartDateFilter(dates[0])
            setEndDateFilter(dates[1])
        }
    }

    const handleSearchEvent = (e) => {
        setCurrentPage(1)
        setSearchEvent(e.target.value)
    }

    const getPaginator = () => {
        return <Pagination className={classes.paginator}
                           defaultCurrent={currentPage}
                           current={currentPage}
                           pageSize={PAGE_SIZE}
                           total={announcements.totalElements}
                           onChange={(page) => {
                               if (page !== currentPage) setCurrentPage(page)
                           }}
                           showSizeChanger={false}/>
    }

    const setAnnouncementsTable = () => {
        return <div className={classes.announcementsContainer}>
            <div className={classes.tableContainer}>
                <Table className={classes.table}
                       rowClassName={(row) => Date.parse(row.date) < new Date().setHours(0, 0, 0, 0) ? classes.pastAnnouncement : null}
                       columns={columns}
                       sticky
                       pagination={false}
                       scroll={{x: 'max-content'}}
                       dataSource={announcements.content}/>
            </div>
            {getPaginator()}
        </div>
    }

    const setAnnouncementsContainer = () => {
        if (announcements && announcements.totalElements === 0) {
            return <div className={classes.noSearchContainer}>
                <img width={100} alt={"NoSearchResults"} src={NoSearchResults} />
                <div>Результаты не найдены.</div>
            </div>
        }
        if (announcements && announcements.totalElements !== 0) {
            return setAnnouncementsTable()
        }
    }

    return <div className={classes.container}>
        <div className={classes.filtersContainer}>
            <div style={{marginRight: '70px'}} className={classes.filterContainer}>
                <div className={classes.filterName}>Дата</div>
                <RangePicker style={{width: '285px'}}
                             className={classes.filterButton}
                             onChange={(dates) => handleDateFilter(dates)}/>
            </div>
            <div style={{flex: 1}} className={classes.filterContainer}>
                <div className={classes.filterName}>Событие</div>
                <Input className={classes.filterButton}
                       suffix={searchEvent.length === 0 ? <IoSearch color={'rgb(0 0 0 / 30%)'} size={16} /> : null}
                       placeholder="Введите событие"
                       value={searchEvent}
                       onChange={(e) => handleSearchEvent(e)}
                       allowClear={searchEvent.length !== 0}/>
            </div>
            <div className={classes.addContainer}>
                <Button className={classes.addButton} icon={<TbPlus size={18}/>} onClick={() => setIsNewAnnouncementDialogOpen(true)}>Добавить</Button>
            </div>
        </div>
        {isNewAnnouncementDialogOpen && <EditAnnouncementDialog
            isAdd={true}
            editAnnouncement={editAnnouncement}
            onClose={() => {
                setIsNewAnnouncementDialogOpen(false)
                clearDialogData()
            }}
            onSuccess={() => {
                loadAnnouncement()
                setIsNewAnnouncementDialogOpen(false)
                clearDialogData()
            }}
        />}
        {isEditAnnouncementDialogOpen && <EditAnnouncementDialog
            isAdd={false}
            updateAllRepeatAnnouncement={repeatedTaskCount === 'all'}
            editAnnouncement={editAnnouncement}
            announcements={announcements.content}
            onClose={() => {
                setIsEditAnnouncementDialogOpen(false)
                clearDialogData()
            }}
            onSuccess={onEditSuccess}
            onDeleteAnnouncement={onDeleteAnnouncement}
            getDeleteButton={getDeleteButton}
        />}
        {!loadingAnnouncements && announcements ? setAnnouncementsContainer() : <Spinner/>}
    </div>

}

export default AnnouncementDesktop;