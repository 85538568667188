import { Button } from 'antd';

import classes from './CancelButton.module.scss'

const CancelButton = ({ key, block, type, text, cancelButtonStyles, onClickHandler, disabled, loading }) => {
    return <Button
        key={key}
        block={block}
        htmlType={type}
        disabled={disabled}
        loading={loading}
        onClick={onClickHandler}
        style={{ ...cancelButtonStyles }}
        className={classes.cancelButton}>
        {text}
    </Button>
}

export default CancelButton;