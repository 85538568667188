import classes from './LessonCard.module.scss';
import {MdOutlineKeyboardArrowRight} from "react-icons/md";
import {useNavigate} from "react-router-dom";
import withRippleAnimation from '../../../../hoc/RippleAnimation/WithRippleAnimation';
import {getCombinedGroupNames} from "../../../../services/util";

const LessonCard = ({ item, onClick, key }) => {

    const navigate = useNavigate();

    const setLessonType = (lesson) => {
        let itemClass = ''

        if (lesson.lessonTypeAbbrev === 'ЛК' || lesson.lessonTypeAbbrev === 'УЛк') itemClass = classes.markAsLecture
        if (lesson.lessonTypeAbbrev === 'ПЗ' || lesson.lessonTypeAbbrev === 'УПз') itemClass = classes.markAsSeminar
        if (lesson.lessonTypeAbbrev === 'ЛР' || lesson.lessonTypeAbbrev === 'УЛР') itemClass = classes.markAsLabWork
        if (lesson.lessonTypeAbbrev === 'Консультация') itemClass = classes.markAsConsultation
        if (lesson.lessonTypeAbbrev === 'Экзамен' || lesson.lessonTypeAbbrev === 'Кандидатский экзамен') itemClass = classes.markAsExam
        if (lesson.lessonTypeAbbrev === 'Зачет' || lesson.lessonTypeAbbrev === 'Кандидатский зачет') itemClass = classes.markAsOffset
        if (lesson.lessonTypeAbbrev === 'КПР(Р)') itemClass = classes.markAsCourseProject

        return itemClass
    }

    const getGroupNamesElements = (groups) => {
        const groupNames = getCombinedGroupNames(groups);
        const groupElements = [];
        for (let i = 0; i < groupNames.length; i++) {
            groupElements.push(<span>
                { groupNames[i] }
                { i !== groupNames.length - 1 && ', ' }
            </span>)
        }
        return groupElements;
    }

    const navigateToLesson = (item) => {
        if ((item[0].lessonTypeAbbrev === 'ЛК' || item[0].lessonTypeAbbrev === 'УЛк' || item[0].lessonTypeAbbrev === 'КПР(Р)') && item.length > 1) {
            navigate("/gradebook/byLesson/groups", { state: { lessons: [...item] } });
        } else {
            navigate("/gradebook/byLesson", { state: { selectedLesson: { ...item[0] } } });
        }
    }

    return (<div className={classes.subjectDetails} key={key} onClick={(e) => onClick(e)}
        onAnimationEnd={() => navigateToLesson(item)}>
        <div className={setLessonType(item[0])} />
        <MdOutlineKeyboardArrowRight size={30} style={{marginRight: '20px', float: 'right', marginTop: '35px', color: '#1677ff'}}/>
        <div className={classes.subjectName}>
            <text>{item[0].lessonName}</text>
            {' (' + item[0].lessonTypeAbbrev + ')'}
        </div>
        <div className={classes.info}>
            {
                item[0].startLessonTime &&
                <div>
                    {item[0].startLessonTime + '-'}
                    { item[0].endLessonTime + ' ' }
                </div>
            }
            <div style = {{paddingTop: '4px'}}>
                { ' гр. ' }
                { getGroupNamesElements(item.map( l => ({...l.group, subgroup: l.subgroup}) )) }
                <span>{item[0].auditory && ', ' + item[0].auditory}</span>
            </div>
        </div>
    </div>);
}

export default withRippleAnimation(LessonCard);