import {Checkbox} from "antd";
import {useEffect, useState} from "react";

const OmissionHoursCommonCheckbox = (props) => {
    const [stateCheckBox, setStateCheckBox] = useState(false)

    useEffect(() => {
        if (props.students && (!props.subgroup || (props.subgroup && props.students.find(stud => stud.subgroup === props.subgroup)))) {
            let studCount = props.students.filter(stud => stud.lessonOmissions.find(les => les.idLesson === props.currentLesson.id)).length
            if (studCount === 0) setStateCheckBox(false)
            else if (studCount === props.students.length - props.students.filter(stud => stud.inOffsettingValue !== 0).length) setStateCheckBox(true)
        }
    }, [props.students])

    return (<Checkbox
        className={props.classNameCheckBox}
        checked={stateCheckBox}
        onChange={(e) => {
            const allShouldHaveOmissions = e.target.checked;
            const studentsForUpdate = props.students
                    .filter(stud => allShouldHaveOmissions !== stud.lessonOmissions.some(om => om.idLesson === props.currentLesson.id) && stud.inOffsettingValue === 0)
                    .map(stud => stud.id);

            if (studentsForUpdate.length === 0) {
                setStateCheckBox(false);
            } else {
                const params = {
                    missedHours: allShouldHaveOmissions ? props.currentLesson.lessonPeriodHours || 2 : 0,
                    lessonId: props.currentLesson.id,
                    studentIds: studentsForUpdate
                };
                setStateCheckBox(allShouldHaveOmissions);
                props.updateOmissions(params);
            }
        }}
    />)
}

export default OmissionHoursCommonCheckbox