import React from "react";
import { Form, Input } from 'antd';
import { AiOutlineLock } from "react-icons/ai";

export const ConfirmPasswordItem = ({dependencie, value, onChange, placeholder}) => (
    <Form.Item
        name="confirmPassword"
        label="Подтвердите пароль"
        dependencies={[dependencie]}
        rules={[
            { required: true, message: 'Данное поле является обязательным!' },
            ({ getFieldValue }) => ({
                validator(_, fieldValue) {
                    if (!fieldValue || getFieldValue(dependencie) === fieldValue) {
                        return Promise.resolve();
                    }
                    return Promise.reject(new Error('Пароли должны совпадать!'));
                },
            }),
        ]}>
        <Input.Password
            prefix={<AiOutlineLock />}
            placeholder={placeholder}
            value={value}
            onChange={onChange} />
    </Form.Item>
)
