import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { IoKeySharp } from "react-icons/io5";
import { MdKeyboardBackspace } from "react-icons/md";
import { Alert, Form, Input } from 'antd';

import classes from './MaskRecovery.module.scss';
import axios from '../../../../services/axios';
import { setMessage } from "../../../../store/actions";

import PasswordRecoveryWrapper from "../PasswordRecoveryWrapper";
import SubmitButton from "../../../UI/SubmitButton/SubmitButton";
import UnderlinedLink from "../../../UI/UnderlinedLink/UnderlinedLink";

const MaskRecovery = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [contact, setContact] = useState(null)
    const [loading, setLoading] = useState(false)
    const [ifError, setIfError] = useState(false)
    const [contactMissingPart, setContactMissingPart] = useState('')
    const [contactValueStart, setContactValueStart] = useState('')
    const [contactValueEnd, setContactValueEnd] = useState('')
    const [ifUnmount, setIfUnmount] = useState(false)

    useEffect(() => {
        if (location?.state?.contact && location?.state?.login) {
            setContact(location.state.contact)

            const maskedValue = location.state.contact.contactValue
            setContactValueStart(maskedValue.substring(0, maskedValue.indexOf('*')))
            setContactValueEnd(maskedValue.substring(maskedValue.lastIndexOf('*') + 1))
        }
        else navigate("/password-recovery", { replace: true })
    }, [navigate, location])

    const checkContact = () => {
        setIfError(false)
        setLoading(true)
        axios.post('/password-reset/phone-exists', {
            contactValue: `${contactValueStart}${contactMissingPart}${contactValueEnd}`,
            login: location.state.login,
        })
            .then(() => contactIsValid())
            .catch((error) => contactIsInvalid(error))
    }

    const contactIsValid = () => {
        setLoading(false)
        setIfUnmount(true)
        setTimeout(() => {
            navigate("/new-password", {
                replace: true,
                state: {
                    contact: location.state.contact,
                    login: location.state.login,
                    prevPath: location.pathname,
                    fullContactValue: `${contactValueStart}${contactMissingPart}${contactValueEnd}`,
                }
            })
        }, 400);
    }

    const contactIsInvalid = (error) => {
        setLoading(false)
        if (error.response.status === 404) setIfError(true)
        else dispatch(setMessage({ type: 'warning', text: "Не удалось подключиться к серверу." }))
    }

    return <PasswordRecoveryWrapper
        icon={<IoKeySharp size={35} />}
        title={location.state?.contact ? `Подтвердите ${location.state.contact?.name?.toLowerCase()}` : 'Подтвердите контакт'}>

        <button className={classes.goBackButton} onClick={() => navigate(-1, { replace: true })}>
            <MdKeyboardBackspace size={35} />
        </button>

        {contact && <div className={!ifUnmount ? classes.container : classes[`container--unmounted`]}>
            <div className={classes.details}>
                Данный контакт будет использо&shy;ваться для восстановления пароля.
                Введите <b><u>недостающую часть</u></b> для подтверждения.
            </div>

            <Form
                name="mask_recovery"
                scrollToFirstError={true}
                onFinish={checkContact}>

                <Form.Item>
                    <div className={classes.contactValue}>{contact.contactValue}</div>
                </Form.Item>

                <Form.Item
                    name="contact"
                    rules={contact.maskRecoveryValidationRules}
                    hasFeedback={true}>
                    <Input
                        className={classes.maskInput}
                        autoComplete="off"
                        maxLength={contact.maxLength}
                        addonBefore={contactValueStart}
                        addonAfter={contactValueEnd}
                        value={contactMissingPart}
                        onChange={(e) => setContactMissingPart(e.target.value.trim())}
                    />
                </Form.Item>

                <Form.Item>
                    <SubmitButton
                        type="submit"
                        text="Отправить"
                        block
                        loading={loading} />
                </Form.Item>

                {(ifError) && <Form.Item>
                    <Alert
                        message="Ошибка!"
                        description={
                            <div>
                                {`Неверный контакт. Для актуализации данных Вы можете обратиться в `}
                                <UnderlinedLink href="https://iis.bsuir.by/support" rel="noopener" target="_blank" text="техподдержку" />.
                            </div>}
                        type="error"
                        showIcon
                        className={classes.errorContainer}
                    />
                </Form.Item>}
            </Form>
        </div>}
    </PasswordRecoveryWrapper>
}

export default MaskRecovery;