import { useState } from 'react';
import { MdOutlineKeyboardArrowLeft } from 'react-icons/md';
import { useLocation, useNavigate } from 'react-router';
import classes from './StudentGroupPicker.module.scss';
import StudentGroupLessonCard from "./StudentGroupLessonCard/StudentGroupLessonCard";
import DateButton from '../../../UI/DateButton/DateButton';


const StudentGroupPicker = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const lessons = location.state?.lessons;
    const [backButtonAnimation, setBackButtonAnimation] = useState(null);

    const getLessonTypeColor = (lesson) => {
        let itemClass = ''
        if (lesson.lessonTypeAbbrev === 'ЛК' || lesson.lessonTypeAbbrev === 'УЛк') itemClass = '#33db24'
        if (lesson.lessonTypeAbbrev === 'КПР(Р)') itemClass = '#3039d4'
        return itemClass
    }

    return (<div className={classes.container}>
        <div className={classes.header}>
            <MdOutlineKeyboardArrowLeft size={35} className={`${classes.backButton} ${backButtonAnimation}`}
                                        onAnimationEnd={() => {
                                                navigate("/gradebook")
                                            setBackButtonAnimation(null);
                                        }}
                                        onClick={() => {
                                            setBackButtonAnimation(classes.wave)
                                        }}/>

            <span
                className={classes.subjectName}
                style={{ textDecorationColor: `${getLessonTypeColor(lessons[0])}` }}
            >
                {lessons[0]?.lessonName}
            </span>
            
            <DateButton 
                date={lessons[0]?.lessonDate}
                onAction={() => navigate("/gradebook/calendar")}
            />
        </div>

        <div className={classes.cards}>
            {
                lessons?.map(item => (
                    <StudentGroupLessonCard key={item.id} item={item} />
                ))
            }
        </div>
    </div>);
}

export default StudentGroupPicker;