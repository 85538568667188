import { configureStore } from '@reduxjs/toolkit'

import authenticationReducer from './reducers/authentication'
import commonReducer from './reducers/common'
import gradeBookReducer from './reducers/gradeBook'
import usersReducer from './reducers/users'
import positionsReducer from './reducers/positions'
import announcementsReducer from './reducers/announcements'
import auditoryReducer from './reducers/auditory'
import groupReducer from './reducers/group'
import employeeReducer from './reducers/employee'

const reducer = {
    common: commonReducer,
    auth: authenticationReducer,
    gradeBook: gradeBookReducer,
    users: usersReducer,
    positions: positionsReducer,
    announcements: announcementsReducer,
    auditory: auditoryReducer,
    group: groupReducer,
    employee: employeeReducer,
}

export const store = configureStore({
    reducer,
    devTools: process.env.NODE_ENV !== 'production',
});