import useWindowDimensions from "../../hooks/windowDimensions";
import AnnouncementMobile from "./AnnouncementMobile/AnnouncementMobile";
import AnnouncementDesktop from "./AnnouncementDesktop/AnnouncementDesktop";

const Announcements = () => {
    const {width} = useWindowDimensions();

    return (<div>
        {
            width <= 500 ?
                <AnnouncementMobile/> :
                <AnnouncementDesktop/>
        }
    </div>);
}

export default Announcements;