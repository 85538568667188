import classes from './SubgroupSeparator.module.scss';
import {useLocation} from "react-router";
import {useNavigate} from "react-router-dom";
import axios from '../../../services/axios';
import {useEffect, useState} from 'react';
import {formatFioWithInitials} from '../../../services/util';
import {MdOutlineKeyboardArrowLeft} from "react-icons/md";
import {setMessage} from '../../../store/actions/common';
import Loader from '../../UI/Loader/Loader';
import {useDispatch} from 'react-redux';
import {Button, Switch, Table} from 'antd';

const SubgroupSeparator = () => {

    const [students, setStudents] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [backButtonAnimation, setBackButtonAnimation] = useState(null);
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const lesson = location.state.lesson;

    useEffect(() => {
        setIsLoading(true);
        axios.get('/gradebook/students',
          {
            params: {
              lessonId: lesson.id,
            }
          })
          .then((response) => {
            setStudents(response.data);
          })
          .catch((_error) => {
            dispatch(setMessage({ type: 'warning', text: "Не удалось подключиться к серверу" }));
          })
          .finally(() => {
            setIsLoading(false);
          })
    }, [])

    const initialSubgroups = () => {
        let index = 0;
        for (let student of students) {
            if (student.subgroup === null) {
                student.subgroup = (index < students.length / 2) ? 1 : 2;
            }
            index += 1;
        }
    }

    const checkSubgroup = (student, index) => {
        return student.subgroup === 2 || (!student.subgroup && index >= students.length / 2)
    }

    const subgroupColumns = [
        {
            title: 'ФИО',
            dataIndex: 'fio',
            render: (_text, student, index) => {
              return (<div className={`${!student.subgroup && classes.studentWithNoSubGroup}`}>
                <span style={{ marginRight: '10px' }}>{index + 1}</span>
                <span>{formatFioWithInitials(student)}</span>
              </div>)
            },
        },
        {
            title: 'Подгруппа',
            dataIndex: 'subgroups',
            render: (_, student, index) => {
                return (
                    <div>
                        <Switch
                            checkedChildren={2}
                            unCheckedChildren={1}
                            defaultChecked={checkSubgroup(student, index)}
                            key={student.id}
                            onChange={(e) => {
                                if(e) 
                                {
                                    student.subgroup = 2;
                                }
                                else {
                                    student.subgroup = 1;
                                }
                            }}>
                        </Switch>
                    </div>
                )
            },
            width: '40%'
        }
    ];

    const updateSubgroups = () => {
        initialSubgroups()
        const requestBody = {
            termHoursId: lesson.group.termHoursId,
            students: students.map(student => { return { studentId: student.id, subgroup: student.subgroup } })
        };
        setIsLoading(true);
        axios.put('/gradebook/assign-subgroups', requestBody)
            .then(_response => {
                navigate("/gradebook/byLesson", {state: {selectedLesson: lesson}});
            })
            .catch(() => {
                dispatch(setMessage({ type: 'warning', text: "Не удалось назначить подгруппы" }));
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    return(<div className={classes.container}>
        <Loader loading={isLoading}/>
        <div className={classes.menuButtons}>
            <div>
                <MdOutlineKeyboardArrowLeft size={35} className={`${classes.backButton} ${backButtonAnimation}`}
                    onAnimationEnd={() => {
                        navigate("/gradebook/byLesson", {state: {selectedLesson: lesson}});
                        setBackButtonAnimation(null);
                    }}
                    onClick={() => { setBackButtonAnimation(classes.wave) }}
                />
            </div>
                <div className={classes.save}>
                    <Button style={{backgroundColor: '#1890ff', color: 'white'}}
                        onClick={updateSubgroups}>
                        Сохранить
                    </Button>
                </div>
        </div> 
        <Table className={classes.subgroupStudents}
            dataSource={students}
            columns={subgroupColumns}
            pagination={false}
        />
    </div>);

}

export default SubgroupSeparator;