import * as actionTypes from '../actions/actionTypes';
import {dateToString} from "../../services/util";

const initialState = {
    gradeBookData: null,
    loadingGradeBookData: false,
    tableParams: null,
    currentLessonsDate: dateToString(new Date()),
    lessonsNumber: 0,
    isEmployee: true,
    selectedStudentGroupOrEmployee: null,
    gradeBookSubjects: [],
    studentGroupsOrEmployees: [],
    studentGroupOrEmployee: [],
    loadingGradeBookSubjects: false,
    loadingStudentGroupsOrEmployees: false,
    assigningSubgroups: false,
    assigningTeams: false,
    addingNewLesson: false,
    editingLesson: false,
    deletingLesson: false,
    editingLabCount: false,
    lessonPeriods: []
};

const loadingGradeBookData = (state, action) => {
    return {
        ...state,
        loadingGradeBookData: true,
        tableParams: action.tableParams
    }
}

const setGradeBookData = (state, action) => {
    const dataIsActual = JSON.stringify(state.tableParams) === JSON.stringify(action.tableParams);//чтобы сетить данные только для последних выставленных в фильтрах параметров

    if (dataIsActual) {
        const tmp = {...action.gradeBookData};

        return {
            ...state,
            gradeBookData: tmp,
            loadingGradeBookData: false,
            assigningSubgroups: false,//чтобы не начать менять отображаемые подгруппы для другого предмета, например
            assigningTeams: false,
            addingNewLesson: false,
            editingLesson: false,
            deletingLesson: false,
            studentPhotosLoaded: false
        }
    } else {
        return state;
    }
};

const studentPhotosLoaded = (state) => {
    return {
        ...state,
        studentPhotosLoaded: true
    }
}

const loadingGradeBookSubjects = (state) => {
    return {
        ...state,
        loadingGradeBookSubjects: true
    }
}

const loadingStudentGroupsOrEmployees = (state) => {
    return {
        ...state,
        loadingStudentGroupsOrEmployees: true
    }
}

const loadingStudentGroupOrEmployee = (state) => {
    return {
        ...state,
        loadingStudentGroupOrEmployee: true
    }
}

const setGradeBookSubjects = (state, action) => {
    let tmp = {...action.gradeBookSubjects}
    return {
        ...state,
        gradeBookSubjects: tmp.subjects,
        loadingGradeBookSubjects: false
    }
};

const setStudentGroupsOrEmployees = (state, action) => {
    let tmp = [...action.studentGroupsOrEmployees]
    return {
        ...state,
        studentGroupsOrEmployees: tmp,
        loadingStudentGroupsOrEmployees: false
    }
};

const setGradeBookStudentGroupOrEmployee = (state, action) => {
    let tmp = [...action.studentGroupOrEmployee]
    return {
        ...state,
        studentGroupOrEmployee: tmp,
        loadingStudentGroupOrEmployee: false
    }
};

const setGradeBookLessonsDate = (state, action) => {
    return {
        ...state,
        currentLessonsDate: action.lessonsDate
    }
}

const setGradeBookLessonsNumber = (state, action) => {
    return {
        ...state,
        lessonsNumber: action.lessonsNumber
    }
}

const setSelectedStudentGroupOrEmployee = (state, action) => {
    return {
        ...state,
        selectedStudentGroupOrEmployee: action.studentGroupOrEmployee
    }
}

const setIsEmployee = (state, action) => {
    return {
        ...state,
        isEmployee: action.isEmployee
    }
}

////////////////////////////////////////////////

const assignSubgroups = (state) => {
    return {
        ...state,
        assigningSubgroups: true
    }
}

const skipSubgroups = (state) => {
    return {
        ...state,
        assigningSubgroups: false
    }
}

const acceptSubgroups = (state, action) => {
    const gradebookDataStudents = action.gradeBookStudents.map(element => { return {...element} });//копирование значений
    if (state.assigningSubgroups) {
        for (let studentSubgroup of action.studentSubgroups) {
            const student = gradebookDataStudents.find(stud => stud.id === studentSubgroup.studentId);
            if (student) {
                student.subgroup = studentSubgroup.subgroup;
                student.lessonOmissions = studentSubgroup.omissions;
            }
        }
        gradebookDataStudents.sort((a, b) => {
            const aSubgroupCompareToBSubgroup = (a.subgroup || 1) - (b.subgroup || 1);
            if (aSubgroupCompareToBSubgroup) return aSubgroupCompareToBSubgroup;
            else return a.lastName.localeCompare(b.lastName, 'ru')
                || a.firstName.localeCompare(b.firstName, 'ru')
                || a.middleName.localeCompare(b.middleName, 'ru');
        })
    }

    return {
        ...state,
        gradeBookData: {...state.gradeBookData, students: gradebookDataStudents},
        assigningSubgroups: false
    }
}

////////////////////////////////////////////////

const assignTeams = (state) => {
    return {
        ...state,
        assigningTeams: true
    }
}

const skipTeams = (state) => {
    return {
        ...state,
        assigningTeams: false
    }
}

const acceptTeams = (state, action) => {
    const gradebookDataStudents = action.gradeBookStudents.map(element => { return {...element} });//копирование значений
    if (state.assigningTeams) {
        for (let studentTeam of action.studentTeams) {
            const student = gradebookDataStudents.find(stud => stud.id === studentTeam.studentId);
            if (student) student.team = studentTeam.team;
        }
    }

    return {
        ...state,
        gradeBookData: {...state.gradeBookData, students: gradebookDataStudents},
        assigningTeams: false
    }
}

////////////////////////////////////////////////

const addNewLesson = (state) => {
    return {
        ...state,
        addingNewLesson: true
    }
}

const acceptNewLesson = (state) => {
    return {
        ...state,
        addingNewLesson: false
    }
}

const skipNewLesson = (state) => {
    return {
        ...state,
        addingNewLesson: false
    }
}

////////////////////////////////////////////////

const editLesson = (state) => {
    return {
        ...state,
        editingLesson: true
    }
}

const acceptEditedLesson = (state) => {
    return {
        ...state,
        editingLesson: false
    }
}

const skipEditedLesson = (state) => {
    return {
        ...state,
        editingLesson: false
    }
}

////////////////////////////////////////////////

const deleteLesson = (state) => {
    return {
        ...state,
        deletingLesson: true
    }
}

const acceptDeletedLesson = (state) => {
    return {
        ...state,
        deletingLesson: false
    }
}

const skipDeletedLesson = (state) => {
    return {
        ...state,
        deletingLesson: false
    }
}

////////////////////////////////////////////////

const startUpdatingLabCount = (state) => {
    return {
        ...state,
        editingLabCount: true
    }
}

const endUpdatingLabCount = (state, action) => {
    return {
        ...state,
        gradeBookSubjects: action.updatedGradeBookSubjects,
        editingLabCount: false
    }
}

////////////////////////////////////////////////

function setLessonPeriods(state, action) {
    return {
        ...state,
        lessonPeriods: action.lessonPeriods
    };
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOAD_GRADE_BOOK: return loadingGradeBookData(state, action);
        case actionTypes.SET_GRADE_BOOK: return setGradeBookData(state, action);
        case actionTypes.LOAD_GRADE_BOOK_SUBJECTS: return loadingGradeBookSubjects(state);
        case actionTypes.LOAD_STUDENT_GROUPS_OR_EMPLOYEES: return loadingStudentGroupsOrEmployees(state);
        case actionTypes.LOAD_STUDENT_GROUP_OR_EMPLOYEE: return loadingStudentGroupOrEmployee(state);
        case actionTypes.SET_GRADE_BOOK_SUBJECTS: return setGradeBookSubjects(state, action);
        case actionTypes.SET_STUDENT_GROUPS_OR_EMPLOYEES: return setStudentGroupsOrEmployees(state, action);
        case actionTypes.SET_STUDENT_GROUP_OR_EMPLOYEE: return setGradeBookStudentGroupOrEmployee(state, action);
        case actionTypes.SET_IS_EMPLOYEE: return setIsEmployee(state, action);
        case actionTypes.SET_LESSONS_DATE: return setGradeBookLessonsDate(state, action);
        case actionTypes.STUDENT_PHOTOS_LOADED: return studentPhotosLoaded(state);
        case actionTypes.SET_LESSONS_NUMBER: return setGradeBookLessonsNumber(state, action);
        case actionTypes.SET_FILTER_STUDENT_GROUP_OR_EMPLOYEE: return setSelectedStudentGroupOrEmployee(state, action);
        case actionTypes.ASSIGN_SUBGROUPS: return assignSubgroups(state);
        case actionTypes.SKIP_SUBGROUPS: return skipSubgroups(state);
        case actionTypes.ACCEPT_SUBGROUPS: return acceptSubgroups(state, action);

        case actionTypes.ASSIGN_TEAMS: return assignTeams(state);
        case actionTypes.SKIP_TEAMS: return skipTeams(state);
        case actionTypes.ACCEPT_TEAMS: return acceptTeams(state, action);

        case actionTypes.ADD_NEW_LESSON: return addNewLesson(state);
        case actionTypes.ACCEPT_NEW_LESSON: return acceptNewLesson(state);
        case actionTypes.SKIP_NEW_LESSON: return skipNewLesson(state);

        case actionTypes.EDIT_LESSON: return editLesson(state);
        case actionTypes.ACCEPT_EDITED_LESSON: return acceptEditedLesson(state);
        case actionTypes.SKIP_EDITED_LESSON: return skipEditedLesson(state);

        case actionTypes.DELETE_LESSON: return deleteLesson(state);
        case actionTypes.ACCEPT_DELETED_LESSON: return acceptDeletedLesson(state);
        case actionTypes.SKIP_DELETED_LESSON: return skipDeletedLesson(state);

        case actionTypes.START_UPDATING_LAB_COUNT: return startUpdatingLabCount(state);
        case actionTypes.END_UPDATING_LAB_COUNT: return endUpdatingLabCount(state, action);

        case actionTypes.SET_LESSON_PERIODS: return setLessonPeriods(state, action);

        default: return state;
    }
};
export default reducer;