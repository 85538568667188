import {useDispatch, useSelector} from 'react-redux';
import {Layout, notification, Spin} from 'antd';
import {useEffect, useState} from 'react';
import {Outlet, useLocation} from 'react-router-dom';
import {useScreenshot} from "use-react-screenshot";
import classes from './MainLayout.module.scss'
import {setMessage} from '../store/actions';
import useWindowDimensions from '../hooks/windowDimensions';

import NavigationHeader from '../components/Navigation/NavigationHeader/NavigationHeader';
import Footer from '../components/Navigation/Footer/Footer';
import SidebarDesktop from '../components/Navigation/Sidebar/SidebarDesktop';
import SidebarMobile from '../components/Navigation/Sidebar/SidebarMobile';
import ScrollUpButton from '../components/UI/ScrollUpButton/ScrollUpButton';
import ErrorBoundary from 'antd/lib/alert/ErrorBoundary';
import SplashScreen from "../components/UI/SplashScreen/SplashScreen";

const { Content } = Layout;

const MainLayout = (props) => {
    const location = useLocation();
    const { width } = useWindowDimensions()

    const [mobileSidebarState, setMobileSidebarState] = useState(false)
    const [notificationApi, notificationContextHolder] = notification.useNotification();

    const dispatch = useDispatch()
    const message = useSelector(state => state.common.message)
    const user = useSelector(state => state.auth.user)

    const [touchStartX, setTouchStartX] = useState(null);
    const [swipeDetected, setSwipeDetected] = useState(null);

    const [loadingImage, setLoadingImage] = useState(false)
    const [image, takeScreenShot] = useScreenshot(
      {
          type: 'image/jpeg',
          quality: 1.0,
      }
    );

    useEffect(() => {
        if (message) {
            notificationApi[message.type]({
                key: message?.key,
                message: <b>Внимание!</b>,
                description: message.text,
                placement: 'bottomRight',
                icon: message?.key && message.type === 'open' && <Spin/>,
                duration: message?.duration,
                onClose: () => dispatch(setMessage(null))
            })
        }
    }, [message, dispatch, notificationApi])

    const onTouchStartHandler = (e) => {
        setTouchStartX(e.targetTouches[0].clientX);
        setSwipeDetected(false);
    }

    const onTouchMoveHandler = (e) => {
        if (!swipeDetected) {
            const touchCurrentX = e.targetTouches[0].clientX;
            const distance = touchStartX - touchCurrentX;

            const minSwipeDistance = 50;
            if (distance > minSwipeDistance) {
                // left swipe
                setMobileSidebarState(false);
                setSwipeDetected(true);
            }
        }
    }

    const onTouchEndHandler = (_e) => {
        setTouchStartX(null);
    }

    const getImage = () => {
        setLoadingImage(true)
        takeScreenShot(document.body).then(() => {
            setLoadingImage(false);
        })
    }

    return (
      <div onTouchStart={onTouchStartHandler} onTouchMove={onTouchMoveHandler}
           onTouchEnd={onTouchEndHandler} className={classes.layoutContainer}>
          {loadingImage
            ? <SplashScreen/>
            : <Layout>
                <ErrorBoundary/>
                {(user && width <= 500) && <SidebarMobile
                  mobileSidebarState={mobileSidebarState}
                  mobileToggleClicked={() => setMobileSidebarState(!mobileSidebarState)}/>}
                <Layout>
                    <NavigationHeader {...props} mobileToggleClicked={() => setMobileSidebarState(!mobileSidebarState)}
                                      image={image}
                                      getImage={getImage}
                    />
                    <div className={classes.siderAndContentContainer}>
                        {(user && width > 500) && <SidebarDesktop/>}
                        <Content className={classes.mainContent}
                                 style={{padding: (user && width <= 500) ? '0 10px' : ''}}>
                            <Outlet/>
                        </Content>
                    </div>
                    {notificationContextHolder}
                    {location.pathname !== '/' && <ScrollUpButton/>}
                    <Footer/>
                </Layout>
            </Layout>}
      </div>
    )
}

export default MainLayout;
