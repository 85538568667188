import {Checkbox, Dropdown} from "antd";
import classes from './TableViewButton.module.scss';
import {MdOutlineSettings} from "react-icons/md";

const TableViewButton = (props) => {

    const getSettingCheckbox = (isChecked, typeSetting, checkboxName) => {
        return <Checkbox
            checked={isChecked}
            onChange={(e) => props.setTableViewSettings(e.target.checked, typeSetting)}
        >
            {checkboxName}
        </Checkbox>
    }

    const items = [
        (props.selectedSubject && props.isContainSubGroup)
            ? {
                key: '1',
                label: getSettingCheckbox(props.displaySubgroupSetting, "displaySubgroupSetting", "Отображать все занятия")
            } : null,
        (props.selectedSubject && props.isContainSubGroup)
            ? {
                type: 'divider',
            } : null,
        {
            key: '2',
            label: getSettingCheckbox(props.subgroupSetting, "subgroupSetting", "Подгруппы")
        },
        {
            key: '3',
            label: getSettingCheckbox(props.teamSetting, "teamSetting", "Команды")
        },
        {
            key: '4',
            label: getSettingCheckbox(props.markAmountSetting, "markAmountSetting", "Кол-во оценок")
        },
        {
            key: '5',
            label: getSettingCheckbox(props.hourAmountSetting, "hourAmountSetting", "Кол-во часов")
        },
        {
            key: '6',
            label: getSettingCheckbox(props.gradePointAverageSetting, "gradePointAverageSetting", "Средний балл")
        }
    ];

    return (
        <div className={props.className}>
            <Dropdown disabled={props.disabled} trigger={['click']} menu={{items}} placement="bottomRight">
                <div className={`${classes.comment} ${props.disabled && classes.comment_disabled}`}>
                    <div className={`${classes.commentText} ${props.disabled && classes.comment_disabled}`}>Настройки</div>
                    <div className={`${classes.commentIcon} ${props.disabled && classes.comment_disabled}`}><MdOutlineSettings size={25}/></div>
                </div>
            </Dropdown>
        </div>
    )
}

export default TableViewButton