import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Alert, Form, Input} from "antd";
import {AiOutlineLock, AiOutlineUser} from "react-icons/ai";
import {FaUniversity} from "react-icons/fa";

import classes from './LoginForm.module.scss'
import {login, resetAuthMessage} from "../../../store/actions";
import { useAuthenticate } from "../../../hooks/useAuthenticate";

import ContainerWithIconHeader from "../../UI/ContainerWithIconHeader/ContainerWithIconHeader";
import SubmitButton from "../../UI/SubmitButton/SubmitButton";
import UnderlinedLink from "../../UI/UnderlinedLink/UnderlinedLink";


const LoginForm = () => {
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')

    const dispatch = useDispatch()
    const isLoginProcessing = useSelector(state => state.auth.isLoginProcessing)
    const message = useSelector(state => state.auth.message)
    const isBlocked = useSelector(state => state.auth.isBlocked);
    const description = isBlocked 
    ? "Попробуйте позже или обратитесь в техподдержку" 
    : "Для входа в систему используйте те же учетные данные, что и для доступа в интернет";

    const resetAlert = () => {
        if (message) {
            dispatch(resetAuthMessage())
        }
    }

    const authenticate = useAuthenticate(() => login(username.toLowerCase(), password))

    return <ContainerWithIconHeader
        icon={<FaUniversity size={40} />}
        title="Кабинет сотрудника БГУИР">
        <Form
            name="loginForm"
            initialValues={{ remember: false }}
            scrollToFirstError={true}
            onFinish={authenticate}>

            <Form.Item
                name="login"
                rules={[
                    {
                        required: true, message: 'Данное поле является обязательным!'
                    },
                    {
                        pattern: /^[a-zA-Z0-9_\-.]+$/g,
                        message: 'Некорректный формат логина!'
                    }
                ]}>
                <Input
                    prefix={<AiOutlineUser />}
                    placeholder="Логин"
                    value={username}
                    onChange={(e) => { setUsername(e.target.value.trim()); resetAlert() }} />
            </Form.Item>

            <Form.Item
                name="password"
                rules={[
                    {
                        required: true,
                        message: 'Данное поле является обязательным!'
                    }
                ]}>
                <Input.Password
                    prefix={<AiOutlineLock />}
                    type="password"
                    placeholder="Пароль"
                    value={password}
                    onChange={(e) => { setPassword(e.target.value.trim()); resetAlert() }} />
            </Form.Item>

            <Form.Item>
                <SubmitButton
                    type="submit"
                    text="Войти"
                    block
                    loading={isLoginProcessing}
                />
            </Form.Item>

            {message && <Form.Item>
                <Alert
                    className={classes.errorContainer}
                    message={message}
                    description={description}
                    type="error"
                    showIcon
                />
            </Form.Item>}

            <Form.Item>
                <div className={classes.controlsContainer}>
                    <UnderlinedLink text="Забыли пароль?" routing href="/password-recovery" />
                </div>
            </Form.Item>
        </Form>
    </ContainerWithIconHeader>
}

export default LoginForm;