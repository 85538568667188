import * as actionTypes from './actionTypes';
import axios from '../../services/axios';
import {setMessage} from "./common";

export const loadFilteredAuditory = () => {
    return {
        type: actionTypes.LOAD_FILTERED_AUDITORY
    }
}

export const setFilteredAuditory = (filteredAuditory) => {
    return {
        type: actionTypes.SET_FILTERED_AUDITORY,
        filteredAuditory: filteredAuditory
    }
}

export const getFilteredAuditory  = (searchValue) => {
    return (dispatch) => {
        dispatch(loadFilteredAuditory())
        axios.get(`/auditory/filter?searchValue=${searchValue}`)
            .then(response => {
                dispatch(setFilteredAuditory(response.data))
            })
            .catch(() => {
                dispatch(setMessage({type: 'warning', text: "Не удалось получить данные."}))
            })
    }
}