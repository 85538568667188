import classes from './Footer.module.scss';
import useWindowDimensions from '../../../hooks/windowDimensions';

import UnderlinedLink from '../../UI/UnderlinedLink/UnderlinedLink';

const Footer = () => {
    const { width } = useWindowDimensions()

    return (
        <div className={width > 500 ? classes['container-footer'] : classes['container-footer--mobile']}>
            <div className={classes.information}>
                <div>
                    УО "Белорусский государственный
                    университет информатики
                    и радиоэлектроники"
                </div>
                <div>УНН: 100383945</div>
            </div>

            <div className={classes.support}>
                <div className={classes.supportLinks}>
                    <UnderlinedLink href="tel:+375172932222" text="+375 (17) 293-22-22" />
                    <UnderlinedLink href="https://iis.bsuir.by/support" target="_blank" rel="noreferrer" text="Техподдержка" />
                </div>
                <div>
                    {`© 2009-${(new Date()).getFullYear()} `}
                    <UnderlinedLink href="https://iis.bsuir.by/about" target="_blank" rel="noreferrer" text="ОИТ ЦИИР БГУИР" />
                    {`. Все права защищены.`}
                </div>
            </div>
        </div>
    )
};

export default Footer;