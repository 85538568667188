import classes from "./ProfilePhotoInput.module.scss"
import Avatar from "react-avatar-edit";
import DefaultIcon from "../../../assets/images/default_photo.png";
import {MdOutlineAddAPhoto} from "react-icons/md";
import {Button, Checkbox, Form, Skeleton} from "antd";
import SubmitButton from "../../UI/SubmitButton/SubmitButton";
import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import axios from "../../../services/axios";
import {loginSuccess} from "../../../store/actions/authentication";
import {setMessage} from "../../../store/actions";

const ProfilePhotoInput = () => {
    const dispatch = useDispatch()

    const [loadingPhoto, setLoadingPhoto] = useState(false)
    const [showPhoto, setShowPhoto] = useState(false);
    const [photo, setPhoto] = useState(null)
    const [visibleEdit, setVisibleEdit] = useState(false)
    const [preview, setPreview] = useState(null)
    const [viewPhoto, setViewPhoto] = useState(null)
    const [size, setSize] = useState({width: null, height: null})
    const [radius, setRadius] = useState(null)

    const maxSize = {width: 600, height: 350}

    const filePicker = useRef(null)

    const user = useSelector(state => state.auth.user);

    useEffect(() => {
        setLoadingPhoto(true)
        axios.get('/settings/photo')
            .then((response) => {
                setPhoto(`data:image/jpeg;base64,${response.data}`)

                axios.get('/settings/show-photo')
                    .then((responseShowPhoto) => {
                        setShowPhoto(responseShowPhoto.data)
                    })
            })
            .finally(() => setLoadingPhoto(false))
    }, [])

    const submitUpdatePhoto = () => {
        const photoBase64String = viewPhoto.substr(viewPhoto.indexOf(",") + 1)
        axios.put(`/settings/change-photo`, {photoBase64String})
            .then((response) => {
                setPhoto(`data:image/jpeg;base64,${response.data}`)

                dispatch(loginSuccess({
                    ...user,
                    photoUrl: `${viewPhoto}`
                }))

                onClose()

                dispatch(setMessage({type: 'success', text: 'Фотография профиля успешно обновлена.'}))
            })
            .catch(() => {
                dispatch(setMessage({type: 'error', text: 'Не удалось обновить фотографию профиля.'}))
            })
    }
    const handleChangePhoto = async (event) => {
        const file = event.target.files[0];
        const base64Url = await convertToBase64(file)

        let image = document.createElement("img")
        image.setAttribute("src", base64Url)

        image.onload = () => {
            const resolution = parseInt(image.height) / parseInt(image.width)
            let width = parseInt(maxSize.height) / resolution
            let height = maxSize.height

            if (resolution < 1 && width > maxSize.width) {
                width = maxSize.width
                height = width * resolution
            }

            setSize({width: width, height: height})
            setRadius(Math.min(parseInt(width), parseInt(height)) / 2)
            setPreview(base64Url)
            setVisibleEdit(true)
        };
    }

    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader()
            fileReader.readAsDataURL(file)

            fileReader.onload = () => {
                resolve(fileReader.result)
            }

            fileReader.onerror = (error) => {
                reject(error)
            }
        })
    }

    const onClose = () => {
        setVisibleEdit(false)
        setPreview(null)
        setViewPhoto(null)
    }

    const onCrop = view => {
        setViewPhoto(view)
    }

    const handleChange = () => {
        axios.put(`/settings/change-show-photo`, {showPhoto: !showPhoto})
            .then((response) => {
                setShowPhoto(response.data)
                dispatch(setMessage({type: 'success', text: 'Настройки фото успешно изменены.'}))
            })
            .catch(() => {
                dispatch(setMessage({type: 'error', text: 'Не удалось изменить настройки фото.'}))
            })
    };

    return (loadingPhoto
        ? <Skeleton active block/>
        : <div>
            <div className={classes.imageContainer}>
                {visibleEdit
                    ? <Avatar width='100%' height={size.height || maxSize.height}
                              cropRadius={radius || parseInt(maxSize.height) / 2} onCrop={onCrop}
                              onClose={onClose}
                              src={preview} exportAsSquare exportSize={size.height || maxSize.height}/>
                    : <div className={classes.changePhoto}>
                        <img style={{width: 230, height: 230, borderRadius: "50%", objectFit: "cover"}}
                             src={photo ?? DefaultIcon} alt='profileIcon' className={classes.image}/>
                        <label role="button" tabIndex="0" title="Сменить фото" className={classes.changePhoto}>
                            <input type="file" accept="image/*, .png, .jpg, .gif, .web" ref={filePicker}
                                   onChange={handleChangePhoto} style={{display: "none"}}/>
                            <div className={classes.overlay}>
                                <MdOutlineAddAPhoto className={classes.icon}/>
                            </div>
                        </label>
                    </div>
                }
            </div>
            <div style={{textAlign: "center"}}>
                <Checkbox className={classes.photoCheckbox}
                          checked={showPhoto}
                          onChange={handleChange}>
                    Отображать фото в телефонном справочнике и на личной странице
                </Checkbox>
            </div>
            <Form
                className={classes.photoForm}
                scrollToFirstError={true}
                onFinish={() => submitUpdatePhoto()}>

                {visibleEdit
                    ? <div>
                        <Form.Item style={{padding: '0'}}
                                   wrapperCol={{span: 24}}
                                   className={classes.animateAppearance}>
                            <Button type='secondary'
                                    className={classes.cancelButton}
                                    onClick={onClose}
                                    loading={loadingPhoto}>
                                Отменить
                            </Button>
                        </Form.Item>
                        <Form.Item style={{padding: '0'}}
                                   wrapperCol={{span: 24}}
                                   className={classes.animateAppearance}>
                            <SubmitButton
                                type="submit"
                                text="Подтвердить"
                                block
                                loading={loadingPhoto}
                                disabled={!visibleEdit}
                            />
                        </Form.Item>
                    </div>
                    : null
                }
            </Form>
        </div>)
}

export default ProfilePhotoInput;
