import {Modal, Radio} from "antd";
import classes from "./MemoReportModal.module.scss";
import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {downloadMemoReportExcel, downloadMemoReportForPercentageExcel} from "../../../../store/actions/gradeBook";
import NumberInput from "../../../UI/NumberInput/NumberInput";

const MemoReportModal = (props) => {

    const [controlPoint, setControlPoint] = useState(1);
    const [requiredAmountOfLabs, setRequiredAmountOfLabs] = useState(0);
    const [requiredPercentage, setRequiredPercentage] = useState(0);
    const dispatch = useDispatch();

    const onChange = (e) => {
        setControlPoint(+e.target.value);
    };

    const closeModal = () => {
        setControlPoint(1);
        setRequiredAmountOfLabs(0);
        props.onCancelHandler();
    }
    const closeModalForPercentage = () => {
        setControlPoint(1);
        setRequiredPercentage(0);
        props.onCancelHandler();
    }

    const downloadReport = () => {
        const reportProps = {...props, controlPoint, requiredAmountOfLabs, closeModal}
        dispatch(downloadMemoReportExcel(reportProps))
    }

    const downloadReportForPercentage = () => {
        const reportProps = {...props, controlPoint, requiredPercentage, closeModalForPercentage}
        dispatch(downloadMemoReportForPercentageExcel(reportProps))
    }

    return <Modal
        open={props.showModal}
        width={800}
        title="Докладная записка"
        okText='Скачать'
        onOk={props.isCourseProject ? downloadReportForPercentage : downloadReport}
        cancelText='Отмена'
        onCancel={props.isCourseProject ? closeModalForPercentage : closeModal}
        destroyOnClose={true}
    >
        <div className={classes.formContainer}>
            <div className={classes.countLabs}>
                {props.isCourseProject ? "Требуемый процент выполнения:" : "Всего должно быть ЛР:"}
                <NumberInput
                    min={0}
                    max={100}
                    value={props.isCourseProject ? requiredPercentage : requiredAmountOfLabs}
                    onChange={props.isCourseProject ? (e) => setRequiredPercentage(e.target.value) : (e) => setRequiredAmountOfLabs(e.target.value)}
                    className={classes.numberInput}
                />

            </div>
            <div className={classes.radioButtons}>
                Контрольная точка:
                <Radio.Group onChange={onChange} value={controlPoint}>
                    <Radio className={classes.radio} value={1}>1</Radio>
                    <Radio className={classes.radio} value={2}>2</Radio>
                    <Radio className={classes.radio} value={3}>3</Radio>
                    <Radio className={classes.radio} value={0}>Текущий момент</Radio>
                </Radio.Group>
            </div>

        </div>
    </Modal>
}

export default MemoReportModal