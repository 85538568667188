import * as actionTypes from '../actions/actionTypes';

const initialState = {
    filteredGroups: null,
    loadingFilteredGroups: false
};

const loadFilteredGroups = (state) => {
    return {
        ...state,
        loadingFilteredGroups: true
    }
}

const setFilteredGroups = (state, action) => {
    let tmp = [...action.filteredGroups]
    return {
        ...state,
        filteredGroups: tmp,
        loadingFilteredGroups: false
    }
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOAD_FILTERED_GROUPS: return loadFilteredGroups(state);
        case actionTypes.SET_FILTERED_GROUPS: return setFilteredGroups(state, action);
        default: return state;
    }
};
export default reducer;