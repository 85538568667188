import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router';
import {useDispatch} from 'react-redux';
import {AiFillUnlock} from "react-icons/ai";
import {MdOutlineClose} from "react-icons/md";
import {Form} from 'antd';
import dayjs from 'dayjs';
import {isEmpty} from 'lodash';

import classes from './CreatePasswordViaHash.module.scss';

import axios from '../../../../services/axios';
import {setMessage} from '../../../../store/actions/common';
import {useQuery} from '../../../../services/useQueryHook';

import CustomRecoveryWrapper from './CustomRecoverWrapper';
import SubmitButton from '../../../UI/SubmitButton/SubmitButton';
import {CreateConfirmPasswordComponent} from '../../../UI/CreateConfirmPassword/CreateConfirmPassword';
import UnderlinedLink from '../../../UI/UnderlinedLink/UnderlinedLink';
import {NavLink} from 'react-router-dom';

const CreatePasswordViaHash = () => {
    const query = useQuery()
    const dispatch = useDispatch()
    const routerLocation = useLocation()
    const navigate = useNavigate()

    const [ifUnmount, setIfUnmount] = useState(false)

    const [linkParamsState, setLinkParamsState] = useState(null)
    const [linkLifetimeState, setLinkLifetimeState] = useState(null)
    const [hash, setHash] = useState('')
    const [hashExpireTime, setHashExpireTime] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [confirmedPassword, setConfirmedPassword] = useState('')
    const [createPasswordLoading, setCreatePasswordLoading] = useState(false)
    const [birthDate, setBirthDate] = useState(null);

    useEffect(() => {
        if (isEmpty(query) && !query.get('t') && !query.get('h')) setLinkParamsState(false)
        else {
            setLinkParamsState(true)
            setHash(query.get('h'))
            setHashExpireTime(query.get('t'))
            if (dayjs().valueOf() >= parseInt(query.get('t')))
                setLinkLifetimeState(false)
        }
    }, [query])

    useEffect(() => {
        if(hash !== '' && hashExpireTime !== ''){
            checkHashRequest();
        }

    }, [hash, hashExpireTime])


    const checkHashRequest = () => {
        console.log(hash)
        console.log(hashExpireTime)
        axios.get('/set-initial-password/check-hash', { params: {
            hash: hash,
            hashExpirationTime: hashExpireTime
        }})
        .then(() => {
            setLinkLifetimeState(true)
        })
        .catch((error) => {
            switch (error.response.status) {
                case 404:
                    dispatch(setMessage({ type: 'error', text: 'Ссылка недействительна.' }))
                    setLinkLifetimeState(false)
                    break;
                case 410:
                    dispatch(setMessage({ type: 'error', text: 'Срок действия ссылки истёк.' }))
                    setLinkLifetimeState(false)
                    break;
                default:
                    dispatch(setMessage({ type: 'error', text: 'Ссылка недействительна' }))
                    setLinkLifetimeState(false)
            }
        })
    }

    const setContainerStylesOnUnmount = () => {
        if (ifUnmount) return { opacity: 0, transform: 'translateY(20px)' }
        else return { opacity: 1, transform: 'translateY(0)' }
    }

    const createPasswordViaHashRequest = () => {
        setCreatePasswordLoading(true)
        axios.post('/set-initial-password', {
            hash: hash,
            birthDate: birthDate.format('DD-MM-YYYY'),
            password: newPassword,
            hashExpirationTime: hashExpireTime,
        })
            .then(() => {
                setCreatePasswordLoading(false)
                setIfUnmount(true)
                setTimeout(() => {
                    navigate("/password-recovery/success", {
                        replace: true,
                        state: { prevPath: routerLocation.pathname }
                    })
                }, 400);
            })
            .catch((error) => {
                switch (error.response.status) {
                    case 404:
                        setCreatePasswordLoading(false)
                        dispatch(setMessage({ type: 'error', text: 'Ссылка недействительна.' }))
                        break;
                    case 410:
                        setCreatePasswordLoading(false)
                        dispatch(setMessage({ type: 'error', text: "Время действия ссылки истекло." }))
                        break;
                    case 409:
                        setCreatePasswordLoading(false)
                        dispatch(setMessage({ type: 'error', text: "Неверная дата рождения. Обратитесь к администратору" }))
                        break;
                    default:
                        setCreatePasswordLoading(false)
                        dispatch(setMessage({ type: 'error', text: 'Не удалось создать новый пароль.' }))
                }
            })
    }

    return (
        <React.Fragment>
            {(linkParamsState === false) && <NavLink to="/" />}
            {(linkParamsState === true && linkLifetimeState !== null) &&
                <CustomRecoveryWrapper
                    icon={linkLifetimeState !== false ? <AiFillUnlock size={35} /> : <MdOutlineClose size={45} />}
                    title={linkLifetimeState !== false ? "Создание нового пароля" : "Ссылка недействительна!"}
                    color={linkLifetimeState !== false ? "#3A547F" : "#f44336"}
                    minHeight={linkLifetimeState !== false ? "415px" : "250px"}
                    showFooter={linkLifetimeState}>
                    <div className={`${classes.container} NewPassword_container_color`} style={setContainerStylesOnUnmount()}>
                        {(linkLifetimeState === true) &&
                            <Form
                                name="new_password"
                                scrollToFirstError={true}
                                labelCol={{ span: 8 }}
                                labelAlign="left"
                                labelWrap
                                colon={false}
                                onFinish={createPasswordViaHashRequest}>

                                {<CreateConfirmPasswordComponent
                                    newPassword={newPassword}
                                    confirmedPassword={confirmedPassword}
                                    birthDate={birthDate}
                                    setNewPassword={(e) => setNewPassword(e.target.value.trim())}
                                    setConfirmedPassword={(e) => setConfirmedPassword(e.target.value.trim())}
                                    setBirthDate={setBirthDate} />}

                                <Form.Item style = {{marginTop:"20px"}}>
                                    <SubmitButton
                                        type="submit"
                                        text="Создать"
                                        block
                                        loading={createPasswordLoading}
                                    />
                                </Form.Item>
                            </Form>}
                        {(linkLifetimeState === false) &&
                            <div className={classes.errorMessageContainer}>
                                {`Срок действия ссылки истек или ссылка недействительна. Вы можете воспользоваться функцией `}
                                <br />
                                <UnderlinedLink routing href="/password-recovery" text="Забыли пароль?" />
                            </div>
                        }
                    </div>
                </CustomRecoveryWrapper>}
        </React.Fragment>
    )
}

export default CreatePasswordViaHash