import React, {useEffect, useState} from 'react';
import {FaBug} from "react-icons/fa";

import classes from './BugReportButton.module.scss';
import BugReportModal from "../BugReportModal/BugReportModal";

const BugReportButton = (props) => {

  const [image, setImage] = useState(props.image);
  const [isBugReportModalOpen, setIsBugReportModalOpen] = useState(false);

  useEffect(() => {
    if ((image !== null && image !== undefined) || (props.image !== null && props.image !== undefined)) {
      if (image === null || image === undefined) {
        setImage(props.image);
      }
      setIsBugReportModalOpen(true);
    }
  }, [image])

  return (
        <React.Fragment>
            <button
              className={classes.bugReportButton}
              onClick={() => {
                setImage(props.getImage?.())
              }}
              disabled={isBugReportModalOpen}>
                <FaBug size={21} />
                <div>Сообщить об ошибке</div>
            </button>
            <button
              className={classes.mobileBugReportButton}
              aria-label="send bug report"
              onClick={() => {
                  setImage(props.getImage?.())
              }}
              disabled={isBugReportModalOpen}>
                <FaBug size={23} />
            </button>
            <BugReportModal
                image={image}
                showBugReportModal={isBugReportModalOpen}
                onClose={() => setIsBugReportModalOpen(!isBugReportModalOpen)}
              />
        </React.Fragment>
    )
}

export default BugReportButton;
