import React from 'react';

import classes from './SplashScreen.module.css';

const SplashScreen = () => {
    return (
      <div className={classes.container}>
          <div className={classes.title}>ИИС «БГУИР: Университет»</div>
          <div className={classes.imageContainer}>
              <img src={process.env.PUBLIC_URL + '/fox2.gif'} width="100%" alt="mascot"/>
          </div>
      </div>
    );
}

export default SplashScreen;
