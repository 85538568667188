import {Checkbox, Input, Popover, Select, Table, Tooltip} from "antd";
import classes from './JournalTable.module.scss';
import React, {forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState} from "react";
import {getFormattedDateStringFromTimestamp} from "../../../../services/dateUtils";
import IntegerInput from "../../../UI/Input/IntegerInput";
import {useDispatch} from "react-redux";
import axios from "../../../../services/axios";
import {setMessage} from "../../../../store/actions";
import {cloneDeep} from 'lodash';
import EditCellDialog from "../EditCellDialog/EditCellDialog";
import EditLessonDialog from "../LessonDialogs/EditLessonDialog";
import OmissionHoursInput from "../../../UI/Input/OmissionHoursInput";
import {IoSearch} from "react-icons/io5";

import {
    getAllLabNumbers,
    getBookedLabNumbers,
    getFreeLabNumbers
} from "../../../../services/Gradebook/gradeBookService";
import {
    getBaseForGradebookApi,
    saveMarksInGradeSettingMode,
    updateOmissions
} from "../../../../store/actions/gradeBook";
import PercentageInput from "../../../UI/Input/PercentageInput";
import {getLocalizedValue} from "../../../../services/localeService";
import JournalLegend from "./JournalLegend";
import {MdStarRate} from "react-icons/md";

const {Option} = Select

const JournalTable = (props, ref) => {
    const [gradeBookStudents, setGradeBookStudents] = useState([])
    const [dates, setDates] = useState([])
    const [maxDateColumnWidth, setMaxDateColumnWidth] = useState(0);
    const [lessonStudentCount, setLessonStudentCount] = useState([])

    const [isEditCellDialogOpen, setIsEditCellDialogOpen] = useState(false)
    const [editStudent, setEditStudent] = useState(null)
    const [editStudentLessons, setEditStudentLessons] = useState(null)
    const [editDate, setEditDate] = useState(null)

    const [isEditLessonDialogOpen, setIsEditLessonDialogOpen] = useState(false)
    const [isEditableLessonWithMarks, setIsEditableLessonWithMarks] = useState()

    const [fioFilter, setFioFilter] = useState('')

    const dispatch = useDispatch()

    const [currentColumnDependency, setCurrentColumnDependency] = useState('')
    const [prevSubgroupSetting, setPrevSubgroupSetting] = useState(props.subgroupSetting)
    const [prevTeamSetting, setPrevTeamSetting] = useState(props.teamSetting)
    const [prevMarkAmountSetting, setPrevMarkAmountSetting] = useState(props.markAmountSetting)
    const [prevHourAmountSetting, setPrevHourAmountSetting] = useState(props.hourAmountSetting)
    const [prevGradePointAverageSetting, setPrevGradePointAverageSetting] = useState(props.gradePointAverageSetting)
    const [prevIsOmissionsMode, setPrevIsOmissionsMode] = useState(props.isOmissionsMode)
    const [prevIsGradeSettingMode, setPrevIsGradeSettingMode] = useState(props.isGradeSettingMode)
    const [prevLessons, setPrevLessons] = useState([])
    const [prevGradebookStudents, setPrevGradebookStudents] = useState([])
    const [prevSelectedSubgroup, setPrevSelectedSubgroup] = useState(props.selectedSubgroup)
    const [prevDisplaySubgroupSetting, setPrevDisplaySubgroupSetting] = useState(props.displaySubgroupSetting)
    const inputRef = useRef(null)

    const [currentCellRowIndex, setCurrentCellRowIndex] = useState(null)
    const [currentCellColumnIndex, setCurrentCellColumnIndex] = useState(null)
    const [indexFilteredStudents, setIndexFilteredStudents] = useState([])
    const [allLabNumbers, setAllLubNumbers] = useState([])
    const [isOpenLabDialog, setIsOpenLabDialog] = useState(false)
    const [checkNewLab, setNewCheckLab] = useState(true)
    const [optionNewLab, setOptionNewLab] = useState(null)
    const [newMark, setNewMark] = useState(null)
    const [isNotClickedSelectLabNumber, setIsNotClickedSelectLabNumber] = useState(true)

    const [selectedStudentRows, setSelectedStudentRows] = useState([])
    const [isUpdatingData, setIsUpdatingData] = useState(false)

    const setTableData = (student) => {
        let tmp = {}
        for (let i = 0; i < props.lessons.length; i++) {
            let lessonMark = student.lessonMarks.find(lesMarl => lesMarl.idLesson === props.lessons[i].id)
            let lessonOmission = student.lessonOmissions?.find(lesOmis => lesOmis.idLesson === props.lessons[i].id)
            const lessonData = {
                id: props.lessons[i].id,
                marks: lessonMark ? lessonMark.marks : [],
                omission: lessonOmission ? lessonOmission.omission : null
            }
            tmp = {
                ...tmp,
                [`date${i}`]: lessonData,
            }
        }
        return tmp
    }

    const compareStudents = (a, b) => {
        return a.lastName.localeCompare(b.lastName, 'ru')
            || a.firstName.localeCompare(b.firstName, 'ru')
            || a.middleName.localeCompare(b.middleName, 'ru');
    }

    const setPeoplePresentNumber = () => {
        let studentCount = []
        props.lessons.forEach(lesson => {
            let count = 0
            const studentsOnLesson = props.students.filter(student => lesson.subgroup === 0 || lesson.subgroup === student.subgroup)
            for (const student of studentsOnLesson) {
                if (!student.lessonOmissions?.some(om => om.idLesson === lesson.id)) {
                    count++;
                }
            }
            studentCount.push(count)
        });
        setLessonStudentCount(studentCount)
    }

    const getIndexFilteredStudents = (studFioFilter, students) => {
        return students.filter(student => !studFioFilter || student.fio.toLowerCase().includes(studFioFilter.toLowerCase())).map(item => item.index)
    }

    useEffect(() => {
        let allLubNumbersTmp = []
        if (props.selectedLabCount) {
            allLubNumbersTmp = getAllLabNumbers(props.selectedLabCount)
            setAllLubNumbers(allLubNumbersTmp)
        }
        if (props.students.length !== 0) {
            let gradeBookStudentsTmp = []
            let students = props.students.slice(0)
            if (!props.displaySubgroupSetting) students.sort(compareStudents)
            students.forEach((student, index) => (gradeBookStudentsTmp.push(
                {
                    key: student.id,
                    index: index + 1,
                    fio: student.lastName + ' ' + student.firstName + (student.middleName !== null ? ` ${student.middleName}` : ''),
                    photo: student.photo,
                    email: student.email,
                    subgroup: student.subgroup,
                    team: student.team,
                    isHeadman: student.headman,
                    markAmount: getMarkAmount(student),
                    hourAmount: getOmissionAmount(student),
                    gradePointAverage: getGradePointAverage(student),
                    inOffsettingValue: student.inOffsettingValue,
                    expelledInCurrentSemester: student.expelledInCurrentSemester,
                    freeLabNumbers: props.isLab ? getFreeLabNumbers(getBookedLabNumbers(student.id, props.students), allLubNumbersTmp) : [],
                    ...setTableData(student)
                })))
            setGradeBookStudents(gradeBookStudentsTmp)
            setIndexFilteredStudents(getIndexFilteredStudents(fioFilter, gradeBookStudentsTmp))
            setDates(props.lessons)
            setPeoplePresentNumber()
        } else {
            setGradeBookStudents([])
        }
        if (props.lessons.length !== 0) {
            setDates(props.lessons)
            setPeoplePresentNumber()
        } else {
            setDates([])
        }
    }, [props.students, props.lessons]);

    const updateStudentOmission = (studentId, updatedOmission, dateIndex) => {
        setGradeBookStudents(oldValue => {
            return oldValue.map(stud => {
                const copiedStudent = cloneDeep(stud);
                if (studentId === stud.key) {
                    copiedStudent[`date${dateIndex}`].omission = updatedOmission;
                }
                return copiedStudent;
            });
        })
        props.setGradeBookStudents(oldValue => {
            return oldValue.map(v => {
                const copiedStudent = cloneDeep(v);
                if (studentId === copiedStudent.id) {
                    let indexLes = copiedStudent.lessonOmissions.findIndex(les => les.idLesson === dates[dateIndex].id)
                    if (updatedOmission && indexLes !== -1) copiedStudent.lessonOmissions.find(les => les.idLesson === dates[dateIndex].id).omission = updatedOmission
                    else if (updatedOmission) copiedStudent.lessonOmissions.push({
                        idLesson: dates[dateIndex].id,
                        omission: updatedOmission
                    })
                    else if (indexLes !== -1) copiedStudent.lessonOmissions = copiedStudent.lessonOmissions.filter((_, ind) => ind !== indexLes);
                }
                return copiedStudent;
            });
        })
    }

    const updateStudentMarks = (studentId, newMarks, dateIndex) => {
        setGradeBookStudents(oldValue => {
            return oldValue.map(stud => {
                const copiedStudent = cloneDeep(stud)
                if (studentId === stud.key) {
                    copiedStudent[`date${dateIndex}`].marks = newMarks
                }
                return copiedStudent
            });
        })
        props.setGradeBookStudents(oldValue => {
            return oldValue.map(v => {
                const copiedStudent = cloneDeep(v);
                if (studentId === copiedStudent.id) {
                    let indexLes = copiedStudent.lessonMarks.findIndex(les => les.idLesson === dates[dateIndex].id)
                    if (newMarks.length !== 0 && indexLes !== -1) copiedStudent.lessonMarks.find(les => les.idLesson === dates[dateIndex].id).marks = newMarks
                    else if (newMarks.length !== 0) copiedStudent.lessonMarks.push({
                        idLesson: dates[dateIndex].id,
                        marks: newMarks
                    })
                    else if (indexLes !== -1) copiedStudent.lessonMarks = copiedStudent.lessonMarks.filter((_, ind) => ind !== indexLes)
                }
                return copiedStudent;
            });
        })
    }

    const updateOmissionRequest = (params, dateIndex) => {
        dispatch(updateOmissions(params, updatedStudents => {
            const updatedStudent = updatedStudents[0];
            updateStudentOmission(updatedStudent.studentId, updatedStudent.omission, dateIndex);
        }));
    }

    const saveMarkRequest = (params, dateIndex) => {
        axios.post(`${getBaseForGradebookApi(props.isPercentage)}/save-marks`, params)
            .then(response => {
                const student = response.data[0];
                updateStudentMarks(student.studentId, student.marks, dateIndex);
            })
            .catch(_error => {
                dispatch(setMessage({type: 'warning', text: "Не удалось сохранить оценку."}))
            });
    }

    const updateMarkRequest = (params, dateIndex, stud) => {
        axios.put(`${getBaseForGradebookApi(props.isPercentage)}/update-mark`, params)
            .then(response => {
                const updatedMark = response.data;
                const copiedStudent = cloneDeep(stud);
                const markToUpdate = copiedStudent[`date${dateIndex}`].marks.find(m => m.id === updatedMark.id);
                markToUpdate.number = updatedMark.number;
                markToUpdate.note = updatedMark.note;
                markToUpdate.labNumber = updatedMark.labNumber
                updateStudentMarks(copiedStudent.key, copiedStudent[`date${dateIndex}`].marks, dateIndex);
            })
            .catch(_error => {
                dispatch(setMessage({type: 'warning', text: "Не удалось обновить оценку."}))
            });
    }

    const deleteMarkRequest = (markId, dateIndex, stud) => {
        axios.delete(`${getBaseForGradebookApi(props.isPercentage)}/delete-mark?markId=${markId}`,)
            .then(() => {
                const copiedStudent = cloneDeep(stud);
                const updatedMarks = copiedStudent[`date${dateIndex}`].marks.filter(m => m.id !== markId);
                updateStudentMarks(copiedStudent.key, updatedMarks, dateIndex);
            })
            .catch(_error => {
                dispatch(setMessage({type: 'warning', text: "Не удалось удалить оценку."}))
            });
    }

    const getSaveMarkRequestBody = (markValue, labNumber, lessonId, studentId) => {
        return props.isPercentage
            ? {
                mark: markValue,
                lessonId: lessonId,
                studentIds: [studentId],
                labNumber: labNumber
            }
            : {
                mark: markValue,
                lessonId: lessonId,
                studentLabNumberDtoList: [{
                    studentId: studentId,
                    labNumber: labNumber
                }],
            }
    }

    const updateMarkCell = (value, row, index, labNumber) => {
        const lesson = row[`date${index}`]
        if ((value !== null && lesson.marks.length === 0)
            || (lesson.marks.length !== 0 && (lesson.marks[0].number !== value || (labNumber !== undefined && lesson.marks[0].labNumber !== labNumber)))) {
            let params
            if (lesson.marks.length === 0) {
                saveMarkRequest(getSaveMarkRequestBody(value, labNumber, lesson.id, row.key), index)
            } else if (lesson.marks.length === 1 && value !== null) {
                params = {
                    id: lesson.marks[0].id,
                    number: value,
                    note: lesson.marks[0].note,
                    labNumber: labNumber
                }
                updateMarkRequest(params, index, row)
            } else deleteMarkRequest(lesson.marks[0].id, index, row)
        }
    }

    const updateOmissionCell = (value, row, index) => {
        const lesson = row[`date${index}`];
        const omission = lesson.omission;
        if (value && value !== omission?.number
            || !value && omission) {
            const params = {
                missedHours: value || 0,
                lessonId: lesson.id,
                studentIds: [row.key],
                note: null
            };
            updateOmissionRequest(params, index);
        }
    }

    const getMarksString = (marks, marksToDisplayCount) => {
        return marks.slice(0, marksToDisplayCount).map((mark, index) => {
            if (index === marksToDisplayCount - 1) return marksToDisplayCount >= marks.length ? <span>{mark.number}</span> : <span>{mark.number}...</span>
            else return <span>{mark.number}, </span>
        })
    }

    const setEditStudentInformation = (student) => {
        let studentLessons = []
        dates.forEach((date, index) => {
            if (student[`date${index}`].marks.length !== 0 || student[`date${index}`].omission) {
                studentLessons.push(
                    {
                        key: `rowDate${index}`,
                        date: date,
                        mark: null,
                        omission: null,
                        isRowDate: true
                    })
                if (student[`date${index}`].marks.length !== 0) {
                    student[`date${index}`].marks.map((mark, ind) => studentLessons.push(
                        {
                            key: mark.id,
                            date: date,
                            mark: mark,
                            lengthMarksDate: student[`date${index}`].marks.length,
                            isFirstMark: ind === 0 && ind !== student[`date${index}`].marks.length - 1,
                            omission: student[`date${index}`].omission,
                            isRowDate: false
                        }))
                } else {
                    studentLessons.push(
                        {
                            key: student[`date${index}`].omission.id,
                            date: date,
                            mark: null,
                            omission: student[`date${index}`].omission,
                            isRowDate: false
                        })
                }
            }
        })
        setEditStudent(student)
        setEditStudentLessons(studentLessons)
    }

    useEffect(() => {
        if (isEditCellDialogOpen) setEditStudentInformation(gradeBookStudents.find(stud => stud.key === editStudent.key))
    }, [gradeBookStudents])

    const getNearestDate = () => {
        const currentDate = new Date()
        const currentDateString = currentDate.getFullYear() + '-'
            + currentDate.toLocaleDateString('default', {month: '2-digit'}) + '-'
            + currentDate.toLocaleDateString('default', {day: '2-digit'})
        let nearestDateIndex = dates.findIndex(date => date.date.slice(0, 10) >= currentDateString)
        if (nearestDateIndex === -1) nearestDateIndex = 0
        return nearestDateIndex
    }

    const openEditCellDialog = (student, index) => {
        if (dates.length && !props.isGradeSettingMode) {
            let newDateIndex = index
            if (index === 0) {
                newDateIndex = getNearestDate()
            }
            setEditStudentInformation(student);
            setEditDate(dates[newDateIndex]);
            setIsEditCellDialogOpen(true);
        }
    }

    const getColorOfInput = (lessonDate, studentSubgroup, lessonSubgroup) => {
        let color = '';
        if (isCurrentDate(lessonDate))
        {
            color = "#e6f7ff";
        }
        if (studentSubgroup !== lessonSubgroup && lessonSubgroup !== 0 && studentSubgroup !== null) {
            color = "#f3f3f3";
        }
        return color;
    }

    const getStudentSubgroup = (fio) => {
        return gradeBookStudents.find(s => s.fio === fio)?.subgroup;
    }

    const getCalloutContainers = (studentLessonData, isOmissions) => {
        const getMarksInfoForOmissionsCell = () => {
            return studentLessonData.marks[0].number + (studentLessonData.marks.length > 1 ? '...' : '');
        }
        const marksContainerValue = !!studentLessonData.marks?.length ? getMarksInfoForOmissionsCell() : null
        const omissionContainerValue = studentLessonData.omission ? `${studentLessonData.omission.number} ч` : null
        let omissionOrMarkCalloutContainer
        let hiddenCalloutContainer
        if (isOmissions) {
            omissionOrMarkCalloutContainer = <div className={classes.marksContainer}>{marksContainerValue}</div>
            hiddenCalloutContainer = <div className={studentLessonData.omission?.respectfulOmission
                ? classes.hiddenRespectfulOmissionContainer
                : classes.hiddenOmissionContainer}>{omissionContainerValue}</div>
        } else {
            omissionOrMarkCalloutContainer = <div className={getStyleForOmissionContainer(studentLessonData)}>{omissionContainerValue}</div>
            hiddenCalloutContainer = <div className={classes.hiddenMarksContainer}>{marksContainerValue}</div>
        }
        return [omissionOrMarkCalloutContainer, hiddenCalloutContainer]
    }

    const getStyleForOmissionContainer = (studentLessonData) => {
        return studentLessonData.omission?.respectfulOmission ? classes.respectfulOmissionContainer : classes.omissionContainer;
    }

    const getCell = (studentLessonData, lesson, row, index, isOmissions) => {
        const displayableMarksCount = 2;
        const withTooltip = studentLessonData.omission?.note || (studentLessonData.marks.length > displayableMarksCount || studentLessonData.marks.some(mark => mark.note));
        const cellColor = getColorOfInput(lesson.date, getStudentSubgroup(row.fio), lesson.subgroup);
        const [omissionOrMarkCalloutContainer, hiddenCalloutContainer] = getCalloutContainers(studentLessonData, isOmissions)

        const cell = <div
            className={classes.inputContainer}
            onContextMenu={(event) => {
                event.preventDefault();
                openEditCellDialog(row, index);
            }}
            onClick={(event) => {
                if (studentLessonData.marks.length > 1 && !isOmissions) {
                    event.preventDefault()
                    openEditCellDialog(row, index)
                }
            }}
            ref={index === currentCellColumnIndex && row.index === currentCellRowIndex ? inputRef : null}
            key={index + (row.index - 1) * dates.length}
            style={{backgroundColor: cellColor, height: row.fio.length > 36 ? '45px' : '32px', cursor: studentLessonData.marks.length > 1 ? 'pointer' : 'default'}}
        >
            {getCellInput(studentLessonData, row, index, isOmissions, displayableMarksCount, cellColor)}

            <div className={classes.calloutContainer}>
                {withTooltip && <div className={classes.tooltipSign}/>}
                {omissionOrMarkCalloutContainer}
            </div>
            {props.isGradeSettingMode && hiddenCalloutContainer}
        </div>

        return withTooltip ? getWithOverlayTrigger(cell, getCellTooltip(studentLessonData)) : cell;
    }

    const getCellInput = (studentLessonData, row, index, forOmissions, displayableMarksCount, backgroundColor) => {
        if (!forOmissions) {
            if (studentLessonData.marks.length <= 1 || props.isGradeSettingMode) {
                return getMarkCellInputWithNotification(studentLessonData, row, index, backgroundColor);
            } else {
                return <div className={classes.markContainer}>{getMarksString(studentLessonData.marks, displayableMarksCount)}</div>
            }
        } else {
            return getOmissionCellInput(studentLessonData, row, index, backgroundColor);
        }
    }

    useEffect(() => {
        if (inputRef.current &&
            inputRef.current[Object.keys(inputRef.current)[0]].key === (dates.length * (currentCellRowIndex - 1) + currentCellColumnIndex).toString()) {
            let input = inputRef.current.children[0]
            input.focus()
        }
        setCurrentColumnDependency('cellIndex')
    }, [currentCellRowIndex, currentCellColumnIndex])

    useEffect(() => {
        let currentDayIndex = -1;
        for (let i = 0; i < dates.length; i++) {
            if (isCurrentDate(dates[i].date)) {
                currentDayIndex = i;
                break;
            }
        }
        if (currentDayIndex !== -1) {
            const tableBody = document.querySelector('.ant-table-body');
            if (tableBody) {
                const dateElements = Array.from(document.querySelectorAll(
                    '.ant-table-thead>tr>th:not(.ant-table-cell-fix-sticky)'));

                const cellWidth = getComputedStyle(dateElements[0]).width;
                const cellWidthNumber = Number(cellWidth.substring(0, 7));
                if (cellWidthNumber > maxDateColumnWidth) {
                    tableBody.scrollLeft = currentDayIndex * cellWidthNumber;
                    setMaxDateColumnWidth(cellWidthNumber);
                }
            }
        }
    }, [dates]);

    const getMovingIndex = (code, studentData, columnIndex, rowIndex, cellStatus) => {
        let movingIndex
        if (code === 'ArrowUp') movingIndex = getMovingUpIndex(rowIndex, columnIndex, cellStatus)
        if (code === 'ArrowDown') movingIndex = getMovingDownIndex(rowIndex, columnIndex, cellStatus)
        if (code === 'ArrowLeft') movingIndex = getMovingLeftIndex(studentData, columnIndex)
        else if (code === 'ArrowRight') movingIndex = getMovingRightIndex(studentData, columnIndex)
        return movingIndex
    }

    const getMovingUpIndex = (rowIndex, columnIndex, cellStatus) => {
        while (true) {
            if (rowIndex !== 0
                && ((cellStatus === 'mark' && gradeBookStudents[indexFilteredStudents[rowIndex - 1] - 1][`date${columnIndex}`].marks.length <= 1)
                    || cellStatus === 'omission')
                && gradeBookStudents[indexFilteredStudents[rowIndex - 1] - 1].inOffsettingValue === 0
                && !gradeBookStudents[indexFilteredStudents[rowIndex - 1] - 1].expelledInCurrentSemester) break
            else if (rowIndex === 0
                && ((cellStatus === 'mark' && gradeBookStudents[indexFilteredStudents[indexFilteredStudents.length - 1] - 1][`date${columnIndex}`].marks.length <= 1)
                    || cellStatus === 'omission')
                && gradeBookStudents[indexFilteredStudents[indexFilteredStudents.length - 1] - 1].inOffsettingValue === 0
                && !gradeBookStudents[indexFilteredStudents[indexFilteredStudents.length - 1] - 1].expelledInCurrentSemester) break
            else if (rowIndex === 0) rowIndex = indexFilteredStudents.length - 1
            else --rowIndex
        }
        return rowIndex
    }

    const getMovingDownIndex = (rowIndex, columnIndex, cellStatus) => {
        while (true) {
            if (rowIndex !== indexFilteredStudents.length - 1
                && ((cellStatus === 'mark' && gradeBookStudents[indexFilteredStudents[rowIndex + 1] - 1][`date${columnIndex}`].marks.length <= 1)
                    || cellStatus === 'omission')
                && gradeBookStudents[indexFilteredStudents[rowIndex + 1] - 1].inOffsettingValue === 0
                && !gradeBookStudents[indexFilteredStudents[rowIndex + 1] - 1].expelledInCurrentSemester) break
            else if (rowIndex === indexFilteredStudents.length - 1
                && ((cellStatus === 'mark' && gradeBookStudents[indexFilteredStudents[0] - 1][`date${columnIndex}`].marks.length <= 1)
                    || cellStatus === 'omission')
                && gradeBookStudents[indexFilteredStudents[0] - 1].inOffsettingValue === 0
                && !gradeBookStudents[indexFilteredStudents[0] - 1].expelledInCurrentSemester) break
            else if (rowIndex === indexFilteredStudents.length - 1) rowIndex = 0
            else ++rowIndex
        }
        return rowIndex
    }

    const getMovingLeftIndex = (studentData, columnIndex) => {
        while (true) {
            if (columnIndex !== 0 && studentData[`date${columnIndex - 1}`].marks.length <= 1) break
            else if (columnIndex === 0 && studentData[`date${dates.length - 1}`].marks.length <= 1) break
            else if (columnIndex === 0) columnIndex = dates.length - 1
            else --columnIndex
        }
        return columnIndex
    }

    const getMovingRightIndex = (studentData, columnIndex) => {
        while (true) {
            if (columnIndex !== dates.length - 1 && studentData[`date${columnIndex + 1}`].marks.length <= 1) break
            else if (columnIndex === dates.length - 1 && studentData['date0'].marks.length <= 1) break
            else if (columnIndex === dates.length - 1) columnIndex = 0
            else ++columnIndex
        }
        return columnIndex
    }

    const handleCellKeyDown = (e, studentData, columnIndex, cellStatus) => {
        if (e.code === 'ArrowUp') handleArrowUpKey(cellStatus, studentData, columnIndex)
        else if (e.code === 'ArrowDown') handleArrowDownKey(cellStatus, studentData, columnIndex)
        else if (e.code === 'ArrowLeft' && e.target.selectionStart === 0) handleArrowLeftKey(cellStatus, studentData, columnIndex)
        else if (e.code === 'ArrowRight' && e.target.selectionStart === e.target.value.length) handleArrowRightKey(cellStatus, studentData, columnIndex)
    }

    const handleArrowUpKey = (cellStatus, studentData, columnIndex) => {
        let movingIndex = getMovingIndex('ArrowUp', studentData, columnIndex,
            indexFilteredStudents.findIndex(id => id === studentData.index), cellStatus)
        setCurrentCellRowIndex(movingIndex !== 0 ? indexFilteredStudents[movingIndex - 1] : indexFilteredStudents[indexFilteredStudents.length - 1])
        setIsOpenLabDialog(false)
    }

    const handleArrowDownKey = (cellStatus, studentData, columnIndex) => {
        let movingIndex = getMovingIndex('ArrowDown', studentData, columnIndex,
            indexFilteredStudents.findIndex(id => id === studentData.index), cellStatus)
        setCurrentCellRowIndex(movingIndex !== indexFilteredStudents.length - 1 ? indexFilteredStudents[movingIndex + 1] : indexFilteredStudents[0])
        setIsOpenLabDialog(false)
    }

    const handleArrowLeftKey = (cellStatus, studentData, columnIndex) => {
        let movingIndex = cellStatus === "mark" ? getMovingIndex('ArrowLeft', studentData, columnIndex) : columnIndex
        setCurrentCellColumnIndex(movingIndex !== 0 ? movingIndex - 1 : dates.length - 1)
        setIsOpenLabDialog(false)
    }

    const handleArrowRightKey = (cellStatus, studentData, columnIndex) => {
        let movingIndex = cellStatus === "mark" ? getMovingIndex('ArrowRight', studentData, columnIndex) : columnIndex
        setCurrentCellColumnIndex(movingIndex !== dates.length - 1 ? movingIndex + 1 : 0)
        setIsOpenLabDialog(false)
    }

    useEffect(() => {
        if (isNotClickedSelectLabNumber && inputRef.current) {
            let input = inputRef.current.children[0]
            input.focus()
        }
    }, [isNotClickedSelectLabNumber])

    const handleBlurMarkCellInput = (value, studentLessonData, row, index) => {
        if (isNotClickedSelectLabNumber) {
            if (studentLessonData.marks.length === 0 && props.isLab) handleChangeLabCheckbox(value, row, index, row.freeLabNumbers.length !== 0 ? checkNewLab : null, optionNewLab)
            else updateMarkCell(value, row, index, props.isLab ? studentLessonData.marks[0].labNumber : null)
            setIsOpenLabDialog(false)
            setNewCheckLab(true)
            setOptionNewLab(null)
            setNewMark(null)
            const headerCell = getHeaderDates()[index].firstChild.firstChild
            headerCell.classList.remove(classes.selectedColumn)
        }
    }

    const getMarkCellInput = (studentLessonData, row, index, backgroundColor) => {
        const className = classes.markInput;
        const value = studentLessonData.marks.length === 0 || props.isGradeSettingMode ? '' : studentLessonData.marks[0].number;
        const style = {backgroundColor: backgroundColor};
        const onBlur = (val) => handleBlurMarkCellInput(val, studentLessonData, row, index);
        const onFocus = () => {
            setCurrentCellColumnIndex(index)
            setCurrentCellRowIndex(row.index)
            const headerCell = getHeaderDates()[index].firstChild.firstChild;
            headerCell.classList.add(classes.selectedColumn);
        }
        const onKeyDown = (e) => handleCellKeyDown(e, row, index, "mark")
        const onKeyUp = (e) => {
            if (e.currentTarget.value) setIsOpenLabDialog(true)
            if (e.currentTarget.value && e.code !== 'ArrowRight' && e.code !== 'ArrowLeft' && e.code !== 'ArrowUp' && e.code !== 'ArrowDown') {
                setOptionNewLab(row.freeLabNumbers.length !== 0 ? row.freeLabNumbers[0] : null)
            } else if (e.code !== 'ArrowRight' && e.code !== 'ArrowLeft' && e.code !== 'ArrowUp' && e.code !== 'ArrowDown') setIsOpenLabDialog(false)
        }
        const onClick = (e) => {
            setIsNotClickedSelectLabNumber(true)
            if (e.currentTarget.value) setIsOpenLabDialog(true)
        }
        const onChange = (val) => {
            setNewMark(val)
        }

        return !props.isPercentage ? <IntegerInput
            min={0}
            max={10}
            value={value}
            className={className}
            isJournal={true}
            style={style}
            onBlur={onBlur}
            onFocus={onFocus}
            onKeyDown={onKeyDown}
            onKeyUp={onKeyUp}
            onClick={onClick}
            onChange={onChange}
        />
        : <PercentageInput
            value={value}
            className={className}
            isJournal={true}
            style={style}
            onBlur={onBlur}
            onFocus={onFocus}
            onKeyDown={onKeyDown}
            onKeyUp={onKeyUp}
            onClick={onClick}
            onChange={onChange}
        />
    }

    const handleChangeLabCheckbox = (mark, row, index, check, labNumber = null) => {
        let labNumberTmp = null
        let freeLabNumber = row.freeLabNumbers.length !== 0 ? row.freeLabNumbers[0] : null
        if (check) labNumberTmp = labNumber ? labNumber : freeLabNumber
        updateMarkCell(mark, row, index, labNumberTmp)
    }

    const getMarkCellDialogCheckbox = (studentLessonData, row, index, isDisabledLabNumberItems) => {
        return <div style={{marginBottom: '5px'}} onMouseDown={() => setIsNotClickedSelectLabNumber(true)}>
            <Checkbox
                checked={(studentLessonData.marks.length !== 0 && studentLessonData.marks[0].labNumber)
                    || (row.freeLabNumbers.length !== 0 && studentLessonData.marks.length === 0 && checkNewLab)}
                disabled={isDisabledLabNumberItems}
                onChange={(e) => {
                    if (studentLessonData.marks.length !== 0) handleChangeLabCheckbox(newMark ? newMark : studentLessonData.marks[0].number, row, index, e.target.checked)
                    else {
                        setNewCheckLab(e.target.checked)
                        if (!e.target.checked) setOptionNewLab(null)
                        else setOptionNewLab(row.freeLabNumbers.length !== 0 ? row.freeLabNumbers[0] : null)
                    }
                }}/>
            <span style={{marginLeft: '5px'}}>в зачет лр</span>
        </div>
    }

    const getMarkCellDialogSelect = (studentLessonData, row, index, isDisabledLabNumberItems) => {
        return <div>
            <span onMouseDown={() => setIsNotClickedSelectLabNumber(true)}>№ лр: </span>
            <Select className={classes.selectLabNumber}
                    popupClassName={classes.selectLabNumberOption}
                    autoFocus={false}
                    disabled={isDisabledLabNumberItems || (studentLessonData.marks.length !== 0 && !studentLessonData.marks[0].labNumber) || !checkNewLab}
                    value={(studentLessonData.marks.length !== 0 && studentLessonData.marks[0].labNumber) || (row.freeLabNumbers.length !== 0 && studentLessonData.marks.length === 0 && optionNewLab)}
                    onMouseDown={() => setIsNotClickedSelectLabNumber(false)}
                    onClick={() => setIsNotClickedSelectLabNumber(false)}
                    onSelect={(value) => {
                        if (studentLessonData.marks.length !== 0) handleChangeLabCheckbox(newMark ? newMark : studentLessonData.marks[0].number, row, index, true, value)
                        else setOptionNewLab(value)
                    }}>
                {allLabNumbers.map(item => (
                    <Option value={item} label={item} disabled={!row.freeLabNumbers.includes(item)}
                            children={item}/>
                ))}
            </Select>
        </div>
    }

    const getHeaderDates = () => {
        let elements = document.querySelectorAll(".ant-table-thead>tr>th");
        return Array.from(elements).filter(el => el.className === "ant-table-cell");
    }

    const getMarkCellInputWithNotification = (studentLessonData, row, index, backgroundColor) => {
        const isDisabledLabNumberItems = row.freeLabNumbers.length === 0 && (studentLessonData.marks.length === 0 || (studentLessonData.marks.length !== 0 && !studentLessonData.marks[0].labNumber))
        return <Popover open={props.isLab && index === currentCellColumnIndex && row.index === currentCellRowIndex && isOpenLabDialog}
                        content={<div onMouseDown={(e) => e.preventDefault()}
                                      onBlur={() => {
                                          if (!isNotClickedSelectLabNumber) {
                                              setIsOpenLabDialog(false)
                                              if (studentLessonData.marks.length === 0 && props.isLab && !isNotClickedSelectLabNumber) {
                                                  handleChangeLabCheckbox(newMark, row, index, row.freeLabNumbers.length !== 0 ? checkNewLab : null, optionNewLab)
                                                  setNewCheckLab(true)
                                                  setOptionNewLab(null)
                                                  setNewMark(null)
                                              }
                                              const headerCell = getHeaderDates()[index].firstChild.firstChild
                                              headerCell.classList.remove(classes.selectedColumn)
                                          }
                                      }}>
                            {getMarkCellDialogCheckbox(studentLessonData, row, index, isDisabledLabNumberItems)}
                            {getMarkCellDialogSelect(studentLessonData, row, index, isDisabledLabNumberItems)}
                        </div>}>
            {getMarkCellInput(studentLessonData, row, index, backgroundColor)}
        </Popover>
    }

    const getOmissionColor = (studentLessonData) => {
        return !studentLessonData.omission?.respectfulOmission ? 'red' : 'green';
    }

    const getOmissionCellInput = (studentLessonData, row, index, backgroundColor) => {
        return <OmissionHoursInput
            className={classes.markInput}
            style={{
                backgroundColor: backgroundColor,
                color: getOmissionColor(studentLessonData)
            }}
            value={props.isGradeSettingMode ? '' : studentLessonData.omission?.number || ''}
            onBlur={(value) => {
                updateOmissionCell(value, row, index)
                getHeaderDates()[index].firstChild.classList.remove(classes.selectedColumn)
            }}
            onFocus={() => {
                setCurrentCellColumnIndex(index)
                setCurrentCellRowIndex(row.index)
                getHeaderDates()[index].firstChild.classList.add(classes.selectedColumn)
            }}
            onKeyDown={(e) => handleCellKeyDown(e, row, index, "omission")}
        />
    }

    const getCellTooltip = (studentLessonData) => {
        const omissionPart = studentLessonData.omission ?
            <div>
                <span>{studentLessonData.omission.number} ч</span>
                {studentLessonData.omission.note && <span>: {studentLessonData.omission.note}</span>}
            </div>
            : null;

        const marksWithNotes = studentLessonData.marks.filter(mark => mark.note);
        const marksWithNotesPart = marksWithNotes?.length ?
            <div>{marksWithNotes.map(mark => <div>{mark.number}: {mark.note}</div>)}</div>
            : null;

        const otherMarks = studentLessonData.marks.filter(mark => !mark.note);
        const otherMarksPart = otherMarks?.length ?
            <div>{
                otherMarks.map((mark, index) =>
                    (index < otherMarks.length - 1) ?
                        <span>{mark.number}, </span>
                        : <span>{mark.number}</span>
                )
            }</div>
            : null;

        return <div>
            {omissionPart}
            {marksWithNotesPart}
            {otherMarksPart}
        </div>;
    }

    const isCurrentDate = (date) => {
        const currentDate = new Date();
        return date.slice(0,4) == currentDate.getFullYear() && date.slice(5,7) == currentDate.getMonth() + 1 && date.slice(8, 10) == currentDate.getUTCDate();
    }

    const openEditLessonDialog = (lesson) => {
        if (!props.isGradeSettingMode) {
            const lessonHasSomeMarksOrOmissions = () => {
                return props.students?.some(student => student.lessonMarks.some(l => l.id === lesson.id && l.marks?.length)
                    || student.lessonOmissions.some(l => l.id === lesson.id && l.omission));
            }

            setEditDate(lesson);
            setIsEditableLessonWithMarks(() => lessonHasSomeMarksOrOmissions);//Функция – чтобы наличие оценок вычислилось только в случае попытки удаления
            setIsEditLessonDialogOpen(true);
        }
    }

    useEffect(() => {
        if (props.subgroupSetting !== prevSubgroupSetting) {
            setCurrentColumnDependency('subgroupSetting')
            setPrevSubgroupSetting(props.subgroupSetting)
        }
    }, [props.subgroupSetting])

    useEffect(() => {
        if (props.teamSetting !== prevTeamSetting) {
            setCurrentColumnDependency('teamSetting')
            setPrevTeamSetting(props.teamSetting)
        }
    }, [props.teamSetting])

    useEffect(() => {
        if (props.markAmountSetting !== prevMarkAmountSetting) {
            setCurrentColumnDependency('markAmountSetting')
            setPrevMarkAmountSetting(props.markAmountSetting)
        }
    }, [props.markAmountSetting])

    useEffect(() => {
        if (props.hourAmountSetting !== prevHourAmountSetting) {
            setCurrentColumnDependency('hourAmountSetting')
            setPrevHourAmountSetting(props.hourAmountSetting)
        }
    }, [props.hourAmountSetting])

    useEffect(() => {
        if (props.gradePointAverageSetting !== prevGradePointAverageSetting) {
            setCurrentColumnDependency('gradePointAverageSetting')
            setPrevGradePointAverageSetting(props.gradePointAverageSetting)
        }
    }, [props.gradePointAverageSetting])

    useEffect(() => {
        if (props.isOmissionsMode !== prevIsOmissionsMode) {
            setCurrentColumnDependency('omissionsMode')
            setPrevIsOmissionsMode(props.isOmissionsMode)
        }
    }, [props.isOmissionsMode])

    useEffect(() => {
        if (props.isGradeSettingMode !== prevIsGradeSettingMode) {
            setCurrentColumnDependency('isGradeSettingMode')
            setPrevIsGradeSettingMode(props.isGradeSettingMode)
        }
    }, [props.isGradeSettingMode])

    useEffect(() => {
        if (JSON.stringify(props.lessons) !== JSON.stringify(prevLessons)) {
            setCurrentColumnDependency('lessons')
            setPrevLessons(props.lessons)
        }
    }, [props.lessons])

    useEffect(() => {
        if (props.selectedSubgroup !== prevSelectedSubgroup && !props.displaySubgroupSetting) {
            setPrevSelectedSubgroup(props.selectedSubgroup )
            setIsUpdatingData(true)
        }
    }, [props.selectedSubgroup])

    useEffect(() => {
        if (props.displaySubgroupSetting !== prevDisplaySubgroupSetting) {
            setPrevDisplaySubgroupSetting(props.displaySubgroupSetting)
            setIsUpdatingData(true)
        }
    }, [props.displaySubgroupSetting])

    useEffect(() => {
        if (JSON.stringify(gradeBookStudents) !== JSON.stringify(prevGradebookStudents)) {
            setPrevGradebookStudents(gradeBookStudents)
            setIsUpdatingData(false)
        }
    }, [gradeBookStudents])

    const shouldStudentCellUpdate = (record, prevRecord) => {
        return record && prevRecord && record.index !== prevRecord.index
    }

    const shouldFioCellUpdate = (record, prevRecord) => {
        return record && prevRecord
            && (record.photo !== prevRecord.photo || JSON.stringify(record) !== JSON.stringify(prevRecord))
    }

    const shouldSubgroupSettingCellUpdate = (record, prevRecord) => {
        if (currentColumnDependency === 'subgroupSetting' && record && record.index === gradeBookStudents.length) setCurrentColumnDependency('')
        return (record && prevRecord && record.subgroup !== prevRecord.subgroup) || currentColumnDependency === 'subgroupSetting'
    }

    const shouldTeamSettingCellUpdate = (record, prevRecord) => {
        if (currentColumnDependency === 'teamSetting' && record && record.index === gradeBookStudents.length) setCurrentColumnDependency('')
        return (record && prevRecord && record.team !== prevRecord.team) || currentColumnDependency === 'teamSetting'
    }

    const shouldMarkAmountSettingCellUpdate = (record, prevRecord) => {
        if (currentColumnDependency === 'markAmountSetting' && record && record.index === gradeBookStudents.length) setCurrentColumnDependency('')
        return (record && prevRecord && record.markAmount !== prevRecord.markAmount) || currentColumnDependency === 'markAmountSetting'
    }

    const shouldHourAmountSettingCellUpdate = (record, prevRecord) => {
        if (currentColumnDependency === 'hourAmountSetting' && record && record.index === gradeBookStudents.length) setCurrentColumnDependency('')
        return (record && prevRecord && record.hourAmount !== prevRecord.hourAmount) || currentColumnDependency === 'hourAmountSetting'
    }

    const shouldGradePointAverageSettingCellUpdate = (record, prevRecord) => {
        if (currentColumnDependency === 'gradePointAverageSetting' && record && record.index === gradeBookStudents.length) setCurrentColumnDependency('')
        return (record && prevRecord && record.gradePointAverage !== prevRecord.gradePointAverage) || currentColumnDependency === 'gradePointAverageSetting'
    }

    const shouldMarkAndOmissionCellUpdate = (index, record, prevRecord, row) => {
        if (currentColumnDependency === 'omissionsMode' && record && record.index === gradeBookStudents.length) setCurrentColumnDependency('')
        if (currentColumnDependency === 'isGradeSettingMode' && record && record.index === gradeBookStudents.length) setCurrentColumnDependency('')
        return (record && prevRecord && ((JSON.stringify(record[`date${index}`].marks) !== JSON.stringify(prevRecord[`date${index}`].marks))
                || (JSON.stringify(record[`date${index}`].omission) !== JSON.stringify(prevRecord[`date${index}`].omission))
                || record.index !== prevRecord.index))
            || currentColumnDependency === 'omissionsMode'
            || currentColumnDependency === 'lessons'
            || currentColumnDependency === 'isGradeSettingMode'
            || (currentColumnDependency === 'cellIndex'
                && (((index === currentCellColumnIndex)
                        && (record.index === currentCellRowIndex))
                    || (inputRef.current
                        && inputRef.current[Object.keys(inputRef.current)[0]].key === (dates.length * (record.index - 1) + index).toString())))
    }

    const inOffsettingProps = (row) => {
        return {
            style: {backgroundColor: (row.inOffsettingValue !== 0 || row.expelledInCurrentSemester) && '#f5f5f5'}
        }
    }

    const getNumberColumn = () => {
        return {
            isOkForPercentage: true,
            title: '№',
            dataIndex: 'index',
            width: 50,
            align: 'center',
            fixed: 'left',
            shouldCellUpdate: (record, prevRecord) => shouldStudentCellUpdate(record, prevRecord),
            onCell: inOffsettingProps,
        }
    }

    const getFioColumn = () => {
        return {
            isOkForPercentage: true,
            title: <Input placeholder='ФИО'
                          value={fioFilter}
                          suffix={<IoSearch color={'rgb(0 0 0 / 30%)'} size={16} />}
                          onChange={(event) => {
                              let inputValueTmp = getLocalizedValue(event.target.value)
                              setIndexFilteredStudents(getIndexFilteredStudents(inputValueTmp, gradeBookStudents))
                              setFioFilter(inputValueTmp)
                          }}/>,
            dataIndex: 'fio',
            width: 300,
            align: 'center',
            fixed: 'left',
            shouldCellUpdate: (record, prevRecord) => shouldFioCellUpdate(record, prevRecord),
            onCell: inOffsettingProps,
            render: (fio, row) => {
                const fioCell = (
                    <div className={classes.fioCell} onClick={() => openEditCellDialog(row, 0)}>
                        {fio}
                        {row.isHeadman && <MdStarRate size={20} style={{color: '#1677ff', verticalAlign: 'top', marginLeft: '5px'}}/> }
                    </div>
                );
                return getWithOverlayTrigger(fioCell, getStudentDataTooltip(row))
            }
        }
    }

    const getSubgroupColumn = () => {
        return {
            title: props.subgroupSetting ? 'П-гр' : '',
            dataIndex: 'subgroup',
            width: props.subgroupSetting ? 70 : 0,
            align: 'center',
            fixed: 'left',
            shouldCellUpdate: (record, prevRecord) => shouldSubgroupSettingCellUpdate(record, prevRecord),
            onCell: inOffsettingProps,
            render: (subgroup) => {
                return <div>{!props.isCourseProject && props.subgroupSetting ? subgroup : ''}</div>
            }
        }
    }

    const getTeamColumn = () => {
        return {
            title: props.teamSetting ? 'Кмд' : '',
            dataIndex: 'team',
            width: props.teamSetting ? 70 : 0,
            align: 'center',
            fixed: 'left',
            shouldCellUpdate: (record, prevRecord) => shouldTeamSettingCellUpdate(record, prevRecord),
            onCell: inOffsettingProps,
            render: (team) => {
                return <div>{props.teamSetting ? team : ''}</div>
            }
        }
    }

    const getMarkAmountColumn = () => {
        return {
            title: props.markAmountSetting ? () => getWithOverlayTrigger("Оценки", "Общее количество отметок") : '',
            dataIndex: 'markAmount',
            width: props.markAmountSetting ? 90 : 0,
            align: 'center',
            fixed: 'left',
            shouldCellUpdate: (record, prevRecord) => shouldMarkAmountSettingCellUpdate(record, prevRecord),
            onCell: inOffsettingProps,
            render: (markAccount) => {
                return <div>{props.markAmountSetting ? markAccount : ''}</div>
            }
        }
    }

    const getHourAmountColumn = () => {
        return {
            title: props.hourAmountSetting ? () => getWithOverlayTrigger("Часы", "Общее количество часов") : '',
            dataIndex: 'hourAmount',
            width: props.hourAmountSetting ? 90 : 0,
            align: 'center',
            fixed: 'left',
            shouldCellUpdate: (record, prevRecord) => shouldHourAmountSettingCellUpdate(record, prevRecord),
            onCell: inOffsettingProps,
            render: (hourAmount) => {
                return <div>{props.hourAmountSetting ? hourAmount : ''}</div>
            }
        }
    }

    const getGradePointAverageColumn = () => {
        return {
            gradePointAverageSettingOnly: true,
            title: props.gradePointAverageSetting ? () => getWithOverlayTrigger("Ср. Балл", "Средний балл") : '',
            dataIndex: 'gradePointAverage',
            width: props.gradePointAverageSetting ? 90 : 1,
            align: 'center',
            fixed: 'left',
            shouldCellUpdate: (record, prevRecord) => shouldGradePointAverageSettingCellUpdate(record, prevRecord),
            onCell: inOffsettingProps,
            render: (gradePointAverage) => {
                return <div>{props.gradePointAverageSetting ? gradePointAverage : ''}</div>
            }
        }
    }

    const getDateColumns = () => {
        return dates.map((lesson, index) => ({
            isOkForPercentage: true,
            title: () => getDateColumnTitle(lesson, index),
            dataIndex: `date${index}`,
            align: 'center',
            shouldCellUpdate: (row, record, prevRecord) => shouldMarkAndOmissionCellUpdate(index, record, prevRecord, row),
            onCell: (row) => getDateColumnPropsCell(row, index),
            render: (studentLessonData, row) => {
                let resultCell
                if (row.expelledInCurrentSemester && index === 0)
                    resultCell = <div className={classes.inOffsettingCell}>Отчислен</div>
                else if (row.inOffsettingValue !== 0 && index === 0)
                    resultCell = <div className={classes.inOffsettingCell}>Перезачтено{row.inOffsettingValue !== 1 && ` с оценкой ${row.inOffsettingValue}`}</div>
                else
                    resultCell = getCell(studentLessonData, lesson, row, index, props.isOmissionsMode)
                return resultCell
            }
        }))
    }

    const getDateColumnTitle = (lesson, columnIndex) => getWithOverlayTrigger(
        <div
            className={`${classes.lessonHeader} ${isCurrentDate(lesson.date) && classes.currentDate}`}
            onClick={() => {
                openEditLessonDialog(lesson)
                if (props.isGradeSettingMode) setEditDate(lesson)
            }}
            onContextMenu={(event) => {
                event.preventDefault();
                openEditLessonDialog(lesson);
            }}
        >
            {lesson.note && <div className={classes.tooltipSign}/>}
            {getFormattedDateStringFromTimestamp(lesson.date)}
            <div style={{whiteSpace: 'nowrap'}}>{`${lessonStudentCount[columnIndex]} чел.`}</div>
        </div>,
        lesson.note
    )

    const getDateColumnPropsCell = (row, columnIndex) => {
        let mergeValue = columnIndex === 0 ? dates.length : 0;
        return ({
            key: columnIndex + (row.index - 1) * dates.length,
            colSpan: (row.inOffsettingValue !== 0 || row.expelledInCurrentSemester) && mergeValue,
        })
    }

    const columns = useMemo(() =>
        [
            getNumberColumn(),
            getFioColumn(),
            Table.SELECTION_COLUMN,
            getSubgroupColumn(),
            getTeamColumn(),
            getMarkAmountColumn(),
            getHourAmountColumn(),
            getGradePointAverageColumn(),
            ...getDateColumns()
        ].filter(
            item => !props.isPercentage || item.isOkForPercentage || Object.keys(item).length === 0
        ), [currentColumnDependency, indexFilteredStudents, currentCellRowIndex, currentCellColumnIndex, isOpenLabDialog, isNotClickedSelectLabNumber, optionNewLab])

    useEffect(() => {
        const antTableItem = document.querySelector('.ant-table')
        if (props.isGradeSettingMode && antTableItem) {
            let editDateIndex
            if (editDate) editDateIndex = dates.findIndex(date => date.id === editDate.id)
            else {
                editDateIndex = getNearestDate()
                setEditDate(dates[editDateIndex])
            }
            setGradeSettingModeForHeaderCells(editDateIndex)
            setGradeSettingModeForCells(editDateIndex)
        }
    }, [props.isGradeSettingMode, indexFilteredStudents, editDate, selectedStudentRows, props.isOmissionsMode, currentColumnDependency])

    const setGradeSettingModeForHeaderCells = (editDateIndex) => {
        let arrayOfDates = document.querySelectorAll(".ant-table-thead>tr>th:not(.ant-table-cell-fix-left, .ant-table-cell-scrollbar)")
        arrayOfDates.forEach((_date, index) => index === editDateIndex
            ? arrayOfDates[index].style.setProperty('background-color', '#d4ecff', 'important')
            : arrayOfDates[index].style.backgroundColor = '#fafafa')
    }

    const setGradeSettingModeForCells = (editDateIndex) => {
        let gradeSettingCells = document.querySelectorAll('.ant-table-tbody > tr > td.ant-table-cell:not(.ant-table-cell-fix-left)')
        if (indexFilteredStudents.length !== 0) {
            gradeSettingCells.forEach(cell => {
                let inputContainer = cell.getElementsByClassName(classes.inputContainer)[0]
                if (inputContainer) {
                    setGradeSettingModeForCell(cell, inputContainer, editDateIndex)
                }
            })
        }
    }

    const setGradeSettingModeForCell = (cell, inputContainer, editDateIndex) => {
        let input = inputContainer.firstChild
        if (parseInt(cell[Object.keys(cell)[0]].key) % dates.length === editDateIndex) {
            input.style.backgroundColor = '#e6f4ff'
        } else input.style.backgroundColor = 'initial'
        if (selectedStudentRows.find(item => cell[Object.keys(cell)[0]].key === (dates.length * (item.index - 1) + editDateIndex).toString())) {
            cell.style.zIndex = '1'
            inputContainer.style.opacity = '1'
            cell.style.pointerEvents = 'none'
            cell.style.border = '1.5px solid #0591FF'
            input.style.setProperty('background-color', '#d2e9fb', 'important')
        } else {
            cell.style.zIndex = '0'
            cell.style.pointerEvents = 'none'
            inputContainer.style.opacity = '0.5'
            cell.style.border = '1px solid #e9e9e9'
            cell.style.boxShadow = 'none'
        }
    }

    useImperativeHandle(ref, () => ({ resetGradeSettingMode }))
    const resetGradeSettingMode = () => {
        props.setIsGradeSettingMode(false)
        props.setIsOpenLabDialog(false)
        props.setNewCheckLab(true)

        const antTableItem = document.querySelector('.ant-table')
        if (antTableItem) {
            let gradeSettingCells = document.querySelectorAll('.ant-table-tbody > tr > td.ant-table-cell:not(.ant-table-cell-fix-left)')
            gradeSettingCells.forEach(cell => {
                let inputContainer = cell.getElementsByClassName(classes.inputContainer)[0]
                if (inputContainer) {
                    let input = inputContainer.firstChild
                    cell.style.pointerEvents = 'auto'
                    cell.style.zIndex = '0'
                    inputContainer.style.opacity = '1'
                    input.style.backgroundColor = 'initial'
                    cell.style.boxShadow = 'none'
                    cell.style.border = '1px solid #e9e9e9'
                }
            })
            if (editDate) {
                let arrayOfDates = document.querySelectorAll(".ant-table-thead>tr>th:not(.ant-table-cell-fix-left, .ant-table-cell-scrollbar)")
                arrayOfDates[dates.findIndex(date => date.id === editDate.id)].style.backgroundColor = '#fafafa'
            }
        }

        setEditDate(null)
        setSelectedStudentRows([])
        props.setGradeSettingValue(null)
    }

    useEffect(() => {
        if (props.isGradeSettingMode) resetGradeSettingMode()
    }, [props.selectedSubgroup, props.displaySubgroupSetting])

    const updateStudentOmissions = (data) => {
        selectedStudentRows.forEach(stud => {
            let newOmission = data.find(d => d.studentId === stud.key)
            updateStudentOmission(stud.key, newOmission.omission, dates.findIndex(date => date.id === editDate.id))
        })
        setIsUpdatingData(false)
    }

    const updateStudentMarksInGradeSettingMode = (data) => {
        selectedStudentRows.forEach(stud => {
            let newMarks = data.find(d => d.studentId === stud.key)
            updateStudentMarks(stud.key, newMarks.marks, dates.findIndex(date => date.id === editDate.id))
        })
        setIsUpdatingData(false)
    }

    useEffect(() => {
        const getUpdateStudentMarksParams = () => {
            return props.isPercentage
                ? {
                    mark: props.gradeSettingValue,
                    lessonId: editDate.id,
                    studentIds: selectedStudentRows.map(stud => stud.key)
                }
                : {
                    mark: props.gradeSettingValue,
                    lessonId: editDate.id,
                    studentLabNumberDtoList: selectedStudentRows.map(stud => {
                        return {
                            studentId: stud.key,
                            labNumber: props.checkNewLab && stud.freeLabNumbers.length !== 0 ? stud.freeLabNumbers[0] : null
                        }
                    })
                }
        }

        if (props.isClickedGradeSettingButton && props.isGradeSettingMode && props.gradeSettingValue !== null && selectedStudentRows.length !== 0) {
            setIsUpdatingData(true)
            let params
            if (props.isOmissionsMode) {
                params = {
                    missedHours: props.gradeSettingValue,
                    lessonId: editDate.id,
                    studentIds: selectedStudentRows.map(stud => stud.key),
                }
                dispatch(updateOmissions(params, updateStudentOmissions))
            } else {
                dispatch(saveMarksInGradeSettingMode(getUpdateStudentMarksParams(), props.isPercentage, updateStudentMarksInGradeSettingMode))
            }
            resetGradeSettingMode()
        }
        props.setIsClickedGradeSettingButton(false)
    }, [props.isClickedGradeSettingButton])

    const onSelectStudentChange = (newSelectedStudentRowKeys, newSelectedStudentRows) => {
        if (newSelectedStudentRowKeys.length > 0) {
            const defaultMarkValue = props.isPercentage ? null : 9
            if (!props.isGradeSettingMode) {
                props.setIsGradeSettingMode(true)
                props.setGradeSettingValue(props.isOmissionsMode ? 2 : defaultMarkValue)
                props.setIsOpenLabDialog(true)
            }
            setSelectedStudentRows(newSelectedStudentRows)
        } else resetGradeSettingMode()
    }

    const studentRowSelection = {
        selectedRowKeys: selectedStudentRows.map(stud => stud.key),
        fixed: true,
        preserveSelectedRowKeys: true,
        renderCell: (_value, row, _index, node) => ({
            props: inOffsettingProps(row),
            children: node
        }),
        getCheckboxProps: (row) => {
            return ({
                disabled: row.inOffsettingValue !== 0 || row.expelledInCurrentSemester,
            })
        },
        onChange: onSelectStudentChange,
    }

    const getWithOverlayTrigger = (name, tooltipName) => {
        let isStringTooltipName = typeof tooltipName === 'string'
        return (
            <Tooltip placement={isStringTooltipName ? "top" : "right"}
                     title={isStringTooltipName ? `${tooltipName}` : tooltipName}>
                <div style={{border: 'none'}}>{name}</div>
            </Tooltip>
        )
    }

    const getMarkAmount = (student) => {
        let markAmount = 0
        if (props.lessons.length !== 0) {
            for (const lessonMark of student.lessonMarks.filter(mark => props.lessons.find(les => les.id === mark.idLesson))) {
                markAmount += lessonMark.marks.length
            }
        }
        return markAmount
    }

    const getOmissionAmount = (student) => {
        let omissionAmount = 0
        if (props.lessons.length !== 0 && student.lessonOmissions) {
            student.lessonOmissions.forEach(omis => {
                if (props.lessons.find(les => les.id === omis.idLesson)) {
                    omissionAmount+=omis.omission.number;
                }
            })
        }
        return omissionAmount
    }

    const getGradePointAverage = (student) => {
        let gradePointAverage = 0
        let markAmount = 0
        if (props.lessons.length !== 0) {
            for (const lessonMark of student.lessonMarks) {
                for (const mark of lessonMark.marks) {
                    gradePointAverage += mark.number
                }
                markAmount += lessonMark.marks.length
            }
            if (gradePointAverage !== 0) {
                gradePointAverage = gradePointAverage / markAmount
                if (!Number.isInteger(gradePointAverage * 10)) {
                    gradePointAverage = (Math.round(gradePointAverage * 100) / 100).toFixed(2)
                }
            }
        }
        return gradePointAverage;
    }

    const getStudentDataTooltip = (student) => {
        return <div className={classes.tooltipProfile}>
            {
                student.photo &&
                <img className={classes.photo} src={student.photo}/>
            }
            <div>{student.fio}</div>
            <div>{student.email}</div>
        </div>
    }

    return <div>
        {isEditLessonDialogOpen && <EditLessonDialog
            lesson={editDate}
            onClose={() => {
                setIsEditLessonDialogOpen(false)
                setEditDate(null)
            }}
            isContainSubGroup={props.filterSubGroups.find(subgr => subgr.key === 1 || subgr.key === 2)}
            filterSubGroups={props.filterSubGroups}
            onSuccess={() => {
                props.reloadGradebookData();
                setIsEditLessonDialogOpen(false);
                setEditDate(null)
            }}
            withMarksOrOmissions={isEditableLessonWithMarks}
            isPercentage={props.isPercentage}
            students={props.students}
        />}
        {(gradeBookStudents.length !== 0)
            && <div>
                <div className={classes.tableContainer}>
                <Table className={`${classes.table} ${!props.areSubGroupsValid() && classes.disabledTable}`}
                       rowClassName={row => (row.inOffsettingValue !== 0 || row.expelledInCurrentSemester) ? classes.disabledOffsetRow : (!props.areSubGroupsValid() && classes.disabledRow)}
                       rowSelection={studentRowSelection}
                       onRow={() => {
                           return {
                               onMouseEnter: () => {
                                   if (currentColumnDependency === 'lessons') setCurrentColumnDependency('')
                               }
                           }
                       }}
                       columns={columns}
                       loading={isUpdatingData}
                       sticky
                       pagination={false}
                       bordered={false}
                       scroll={{x: dates.length < 30 ? 1500 : 4000}}
                       dataSource={gradeBookStudents.filter(student => !fioFilter || student.fio.toLowerCase().includes(fioFilter.toLowerCase()))}
                />
                {
                    isEditCellDialogOpen ?
                        <EditCellDialog selectedGroupName={props.selectedGroupName}
                                        editStudent={editStudent}
                                        editStudentLessons={editStudentLessons}
                                        editDate={editDate}
                                        allLabNumbers={allLabNumbers}
                                        dates={dates}
                                        saveMarkRequest={saveMarkRequest}
                                        updateMarkRequest={updateMarkRequest}
                                        deleteMarkRequest={deleteMarkRequest}
                                        updateOmissionRequest={updateOmissionRequest}
                                        isLab={props.isLab}
                                        onClose={() => {
                                            setIsEditCellDialogOpen(false)
                                            setEditDate(null)
                                        }}
                                        isPercentage={props.isPercentage}
                        /> : null
                }
                </div>
                {!props.isPercentage && <JournalLegend />}
            </div>}
    </div>
}

export default forwardRef(JournalTable)
