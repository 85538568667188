import { useNavigate } from 'react-router';
import classes from ".//StudentGroupLessonCard.module.scss";
import {MdOutlineKeyboardArrowRight} from "react-icons/md";
import withRippleAnimation from "../../../../../hoc/RippleAnimation/WithRippleAnimation";

const StudentGroupLessonCard = ({ item, onClick }) => {

    const navigate = useNavigate();

    return (
        <div className={classes.card} key={item.id} onClick={(e) => onClick(e)}
             onAnimationEnd={() => {
                 navigate("/gradebook/byLesson", {state: {selectedLesson: {...item}}});
             }}>
            <div className={classes.groupName}>
                <text>{item.group.name}</text>
                <span className={classes.groupAttendance}>{item.groupAttendance}</span>
            </div>
            <MdOutlineKeyboardArrowRight size={30} style={{color: '#3a547f'}}/>
        </div>
    );
}

export default withRippleAnimation(StudentGroupLessonCard);