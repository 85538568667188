import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {AiOutlineLock} from "react-icons/ai";
import {Form, Input, Result, Skeleton, Spin} from 'antd';

import classes from './ChangePassword.module.scss';
import parentClasses from '../ProfileSettings.module.scss';
import axios from '../../../services/axios';
import {setMessage} from "../../../store/actions";

import {ConfirmPassword, PasswordRequirementsList, passwordPattern} from "../../UI/ConfirmPassword/ConfirmPassword";
import SubmitButton from "../../UI/SubmitButton/SubmitButton";
import ReactCodeInput from "react-code-input";
import {BAN_DURATION, CODE_LENGTH, CODE_LIFETIME} from "../../../services/constants";
import CountdownTimer from "../../UI/CountdownTimer/CountdownTimer";
import {IoAlert} from "react-icons/io5";
import ContainerWithIconHeader from "../../UI/ContainerWithIconHeader/ContainerWithIconHeader";

const ChangePassword = () => {
    const user = useSelector(state => state.auth.user);

    const [contact, setContact] = useState(null);
    const [loadingContactsError, setLoadingContactsError] = useState(false);
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmedPassword, setConfirmedPassword] = useState('');
    const [codeRequestLoading, setCodeRequestLoading] = useState(false);
    const [isConfirmationCodeValid, setIsConfirmationCodeValid] = useState(true);
    const [isConfirmationCodeSend, setIsConfirmationCodeSend] = useState(false);
    const [banExpirationTime, setBanExpirationTime] = useState(null);
    const [confirmationCodeExpirationTime, setConfirmationCodeExpirationTime] = useState(null);
    const [loading, setLoading] = useState(true);
    const [confirmationCode, setConfirmationCode] = useState('');
    const [numberOfAttempts, setNumberOfAttempts] = useState(4);

    const [form] = Form.useForm();

    const dispatch = useDispatch();

    useEffect(() => {
        axios.get('/settings/contacts')
            .then((response) => {
                const phone = checkPhoneToSendCode(response.data.filter(item => item.contactTypeId === 4));
                setContact(phone);
                setNumberOfAttempts(phone.remainingAttempts);
                axios.post('/password-reset/phone-exists', {
                    contactValue: phone.contactValue,
                    login: user.username
                })
                    .then((response) => {
                        if (response.data.codeExpirationTime) {
                            setIsConfirmationCodeSend(true);
                            setConfirmationCodeExpirationTime(Date.parse(response.data.codeExpirationTime));
                        } else {
                            setIsConfirmationCodeSend(false);
                            setConfirmationCodeExpirationTime(null);
                        }
                    })
                    .catch((error) => {
                        if (error.response.status === 429 && error.response.data.banExpirationTime) {
                            setBanExpirationTime(error.response.data.banExpirationTime);
                        } else {
                            dispatch(setMessage({type: 'warning', text: "Не удалось подключиться к серверу."}));
                        }
                    })
                    .finally(() => {
                        setLoading(false);
                    })
            })
            .catch(() => {
                setLoadingContactsError(true);
                setLoading(false);
            })
    }, [dispatch]);

    const checkPhoneToSendCode = (phoneList) => {
        const confirmedNumber = phoneList.find(phone => phone.confirmed);
        return confirmedNumber ?? phoneList[phoneList.length - 1];
    }

    const changePasswordHandler = () => {
        setLoading(true);
        setNumberOfAttempts(numberOfAttempts - 1);
        axios.post('/settings/change-password', {
            oldPassword: oldPassword,
            newPassword: newPassword,
            contactValue: contact.contactValue,
            confirmCode: confirmationCode
        })
            .then(() => {
                form.resetFields();
                setIsConfirmationCodeSend(false);
                setConfirmationCodeExpirationTime(0);
                setConfirmationCode('');
                dispatch(setMessage({ type: 'success', text: "Пароль успешно изменен." }));
            })
            .catch((error) => {
                if (error.response?.status === 409) {
                    if (error.response.data === "Invalid confirm code") {
                        dispatch(setMessage({type: 'error', text: "Неверный код подтверждения."}));
                    } else {
                        dispatch(setMessage({type: 'error', text: "Неверный старый пароль."}));
                    }
                } else {
                    dispatch(setMessage({type: 'error', text: "Не удалось изменить пароль."}));
                }
            })
            .finally(() => setLoading(false));
    }

    const sendConfirmationCode = () => {
        axios.post('/settings/check-password', {
            oldPassword: oldPassword
        })
            .then((result) => {
                if (result.data) {
                    setCodeRequestLoading(true);
                    setIsConfirmationCodeValid(true);
                    setIsConfirmationCodeSend(false);

                    axios.post('/password-reset/send-confirm', {
                        login: user.username,
                        contactValue: contact.contactValue,
                        contactTypeId: contact.contactTypeId
                    })
                        .then(() => {
                            setCodeRequestLoading(false);
                            setConfirmationCodeExpirationTime(new Date(new Date().getTime() + CODE_LIFETIME));
                            setNumberOfAttempts(oldValue => oldValue - 1);
                            setIsConfirmationCodeSend(true);
                            dispatch(setMessage({
                                type: 'success',
                                text: `Код подтверждения отправлен на ${contact.contactValue.toLowerCase()}`
                            }));
                        })
                        .catch((error) => {
                            setIsConfirmationCodeSend(false);
                            setCodeRequestLoading(false);
                            if (error.response.status === 429 && error.response.data.banExpirationTime) {
                                setBanExpirationTime(error.response.data.banExpirationTime);
                            } else {
                                dispatch(setMessage({ type: 'error', text: "Не удалось отправить код подтверждения." }));
                            }
                        })
                } else {
                    dispatch(setMessage({ type: 'error', text: "Неверный старый пароль." }));
                }
            })
    }

    const setCodeRequestButtonText = () => {
        if (codeRequestLoading && !isConfirmationCodeSend) {
            return <Spin size='xs'/>
        } else {
            return <React.Fragment>Получить код</React.Fragment>
        }
    }

    const onCodeExpireHandler = () => {
        setIsConfirmationCodeSend(false);
        setConfirmationCodeExpirationTime(0);
        setConfirmationCode('');
        dispatch(setMessage({ type: 'error', text: "Срок действия кода подтверждения истек." }));
        if (numberOfAttempts <= 1) {
            axios.post('/settings/change-password', {
                oldPassword: oldPassword,
                newPassword: newPassword,
                contactValue: contact.contactValue,
                confirmCode: null
            })
                .then(() => {
                    // Never happens because of bad confirm code
                })
                .catch(() => {
                    setBanExpirationTime(new Date(new Date().getTime() + BAN_DURATION));
                });
        }
    }

    return <div>
        {
            loading && <div>
                {new Array(!loadingContactsError ? 1 : 0).fill(0).map((_, index) => <div key={index} >
                    <Skeleton active block />
                </div>)}
            </div>
        }
        {
            !loading && banExpirationTime && <React.Fragment>
                <ContainerWithIconHeader
                    color="#f44336"
                    containerStyles={{ minHeight: '415px' }}
                    icon={<IoAlert size={45} />}
                    title="Лимит превышен.">
                    {
                        <div className={classes.container}>
                            <div className={classes.extra}>Вы превысили лимит попыток ввода. Функционал будет доступен
                            через:
                            </div>
                            <div className={classes.banExpirationTimeContainer}>
                                {
                                    !banExpirationTime
                                        ? <Spin/>
                                        : <CountdownTimer withLabels targetDate={banExpirationTime}
                                            onExpireHandler={() => setBanExpirationTime(null)}/>
                                }
                            </div>
                        </div>
                    }
                </ContainerWithIconHeader>
            </React.Fragment>
        }
        {
            !loading && !banExpirationTime && loadingContactsError && <Result
                status="error"
                title="Не удалось загрузить контактные данные"/>}
        {
            !loading && !banExpirationTime && !loadingContactsError && <div className={parentClasses.tabContainer}>
                <div className={parentClasses.tabSideCaption}>
                    <PasswordRequirementsList/>
                </div>

                <div className={parentClasses.tabContent}>
                    <Form
                        form={form}
                        className={classes.passwordForm}
                        name="loginForm"
                        labelCol={{span: 7}}
                        labelAlign="left"
                        labelWrap
                        colon={false}
                        initialValues={{remember: false}}
                        scrollToFirstError={true}
                        onFinish={changePasswordHandler}>

                        <Form.Item
                            name="oldPassword"
                            label="Старый пароль"
                            rules={[
                                {required: true, message: 'Данное поле является обязательным!'}
                            ]}>
                            <Input.Password
                                prefix={<AiOutlineLock/>}
                                placeholder="Старый пароль"
                                value={oldPassword}
                                onChange={(e) => setOldPassword(e.target.value.trim())}/>
                        </Form.Item>

                        <ConfirmPassword
                            isChangePassword={true}
                            newPassword={newPassword}
                            confirmedPassword={confirmedPassword}
                            setNewPassword={(e) => setNewPassword(e.target.value.trim())}
                            setConfirmedPassword={(e) => setConfirmedPassword(e.target.value.trim())}/>

                        <Form.Item
                            name="code"
                            label="Код"
                            rules={[
                                {required: true, message: 'Данное поле является обязательным!'}
                            ]}>
                            <div className={classes.codeContainer}>
                                <div key={isConfirmationCodeSend}>
                                    <ReactCodeInput
                                        id="confirmationCode"
                                        type="number"
                                        inputMode="numeric"
                                        className={isConfirmationCodeValid
                                            ? classes.codeInputContainer
                                            : classes.codeInputContainerInvalid}
                                        fields={CODE_LENGTH}
                                        autoFocus={false}
                                        disabled={!isConfirmationCodeSend}
                                        isValid={isConfirmationCodeValid}
                                        onChange={(value) => setConfirmationCode(value)}
                                        value={confirmationCode}
                                    />
                                    <div className={classes.attemptsContainer}>
                                        {`Осталось попыток: `}
                                        <span className={classes.errorMessage}>{numberOfAttempts}</span>
                                    </div>
                                </div>
                                {
                                    !isConfirmationCodeSend
                                        ? <SubmitButton
                                            type="button"
                                            text={setCodeRequestButtonText()}
                                            disabled={oldPassword.length === 0
                                            || !(passwordPattern.test(newPassword)
                                            && (newPassword === confirmedPassword))
                                            || (codeRequestLoading && !isConfirmationCodeSend)}
                                            onClickHandler={() => sendConfirmationCode()}
                                        />
                                        : <div className={classes.timerContainer}>
                                            {confirmationCodeExpirationTime
                                                && <CountdownTimer targetDate={confirmationCodeExpirationTime}
                                                    onExpireHandler={() => onCodeExpireHandler()}/>}
                                        </div>
                                }
                            </div>
                        </Form.Item>

                        <Form.Item style={{padding: '0'}}
                            wrapperCol={{span: 24}}
                            className={classes.submitButtonContainer}>
                            <SubmitButton
                                type="submit"
                                text="Подтвердить"
                                block
                                disabled={oldPassword.length === 0
                                || !(passwordPattern.test(newPassword)
                                && (newPassword === confirmedPassword)
                                && confirmationCode.length === CODE_LENGTH)}
                                loading={loading}
                            />
                        </Form.Item>

                    </Form>
                </div>
            </div>
        }
    </div>
}

export default ChangePassword;
