import classes from './WithRippleAnimation.module.scss';

const withRippleAnimation = (Component) => {

    const handleClick = (e) => {
        const button = e.currentTarget;
        const circle = document.createElement("span");
        const diameter = Math.max(button.clientWidth, button.clientHeight);
        const radius = diameter / 2;
        
        const appElement = document.querySelector('html');

        circle.style.width = circle.style.height = `${diameter}px`;
        circle.style.left = `${e.clientX - (button.offsetLeft + radius)}px`;
        circle.style.top = `${e.clientY - (button.offsetTop + radius - appElement.scrollTop)}px`;
        circle.classList.add(classes.ripple);
        
        const ripple = button.getElementsByClassName(classes.ripple)[0];

        if (ripple) {
            ripple.remove();
        }
        
        button.appendChild(circle);
    }
    
    return (props) => {
        return (<Component { ...props } onClick={(e) => handleClick(e)} />);
    }
}

export default withRippleAnimation;