import classes from './JournalMobile.module.scss';
import {MdStarRate} from "react-icons/md";
const JournalLegendMobile = () => {

    return <div className={classes.mobileLegend}>
        <div style={{textAlign: 'justify'}}>
            <span style={{color: 'green'}}>Зеленым </span>
            выделены пропуски по уважительной причине (могут выставляться автоматически при одобрении деканатом
            заявлений на ОРВИ).
        </div>
        <div style={{textAlign: 'justify'}}>
            <span style={{color: 'red'}}>Красным </span>выделены пропуски по неуважительной причине.
        </div>
        <div>
            <MdStarRate size={20} style={{color: '#1677ff', verticalAlign: 'top'}}/>
            выделен староста группы.
        </div>
    </div>
}

export default JournalLegendMobile;