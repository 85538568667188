import {Modal, Table} from "antd";
import {getFio} from "../../../../services/commonService";
import {useDispatch, useSelector} from "react-redux";
import {assignTeams} from "../../../../store/actions/gradeBook";
import IntegerInput from "../../../UI/Input/IntegerInput";
import {useEffect, useState} from "react";
import classes from './TeamDialog.module.scss';

const TeamDialog = (props) => {
    const assigningTeams = useSelector(state => state.gradeBook.assigningTeams);
    const [students, setStudents] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        const studentsState = props.students ?
            JSON.parse(JSON.stringify(props.students))
                .sort((a, b) =>
                    a.lastName.localeCompare(b.lastName, 'ru')
                    || a.firstName.localeCompare(b.firstName, 'ru')
                    || a.middleName.localeCompare(b.middleName, 'ru')
                )
                .map((student, index) => ({
                    index: index + 1,
                    key: student.id,
                    fio: getFio(student.lastName, student.firstName, student.middleName),
                    team: student.team,
                    inOffsettingValue: student.inOffsettingValue,
                    expelledInCurrentSemester: student.expelledInCurrentSemester
                }))
            : [];
        setStudents(studentsState);
    }, [props])

    const inOffsettingProps = (row) => {
        return {
            style: {backgroundColor: (row.inOffsettingValue !== 0 || row.expelledInCurrentSemester) && '#f5f5f5'}
        }
    }

    const changeTeam = (index, value) => {
        setStudents(students.map(student =>{
            if (student.index === index) {
                return {...student, team: value}
            } else {
                return student;
            }
        }));
    }

    const onAssignTeams = () => {
        dispatch(assignTeams(props.termHoursId, students.map(student => {
            return {studentId: student.key, team: student.team}
        }),  props.students, props.onCancelHandler));
    }

    const columns =
        [
            {
                title: '№',
                dataIndex: 'index',
                width: 50,
                align: 'center',
                fixed: 'left',
                onCell: inOffsettingProps,
            },
            {
                title: 'ФИО',
                onCell: inOffsettingProps,
                render: student => {
                    return <div style={{textAlign: 'left'}}>{student.fio}{props.getOffsetLabel(student)}</div>
                },
                width: 150,
                align: 'center',
                fixed: 'left',
            },
            {
                title: 'Команда',
                onCell: inOffsettingProps,
                render: student => {
                    return <div>
                        <IntegerInput
                            className={(student.inOffsettingValue !== 0 || student.expelledInCurrentSemester) && classes.disabledInput}
                            value={student.team}
                            min={1}
                            onChange={value => changeTeam(student.index, value)}
                            isNullable={true}
                        />
                    </div>
                },
                width: 60,
                align: 'center',
                fixed: 'left'
            }
        ];

    const onCloseModal = () => {
        setStudents(students.map(student => {
            return {studentId: student.key, team: null}
        }));
        props.onCancelHandler();
    }

    return <Modal
        title='Назначение команд'
        open={props.showModal}
        destroyOnClose={true}
        centered={true}
        okText='Сохранить'
        okButtonProps={{disabled: assigningTeams}}
        onOk={onAssignTeams}
        cancelText='Отменить'
        onCancel={onCloseModal}
        width={500}
    >
        <div>
            <Table
                rowClassName={row => (row.inOffsettingValue !== 0 || row.expelledInCurrentSemester) && classes.disabledOffsetRow}
                columns={columns}
                dataSource={students}
                pagination={false}
                scroll={{y: '400px'}}
            />
        </div>
    </Modal>
}

export default TeamDialog