import classes from './Users.module.scss';
import {Button, Input, Pagination, Table, Tooltip} from 'antd';
import {IoLogInOutline, IoSearch} from "react-icons/io5";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getUsers} from "../../store/actions/users";
import Spinner from "../UI/Spinner/Spinner";
import NoSearchResults from '../../assets/images/NoSearchResults.png'
import useWindowDimensions from "../../hooks/windowDimensions";
import {getCustomUser} from "../../store/actions/authentication";
import {useNavigate} from "react-router-dom";
import {TbLock, TbLockOpen} from "react-icons/tb";
import MobileTableItem from "../UI/MobileTableItem/MobileTableItem";
import {getFio} from "../../services/commonService";
import {getLocalizedValue} from "../../services/localeService";

const Users = () => {
    const PAGE_SIZE = 30
    const {width} = useWindowDimensions();

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const users = useSelector(state => state.users.usersData)
    const loadingUsers = useSelector(state => state.users.loadingUsers)

    const [currentPage, setCurrentPage] = useState(1)
    const [searchLastName, setSearchLastName] = useState("")
    const [searchLoginName, setSearchLoginName] = useState("")

    useEffect(() => {
        const usersFilterDto = {
            currentPage: currentPage,
            pageSize: PAGE_SIZE,
            lastName: searchLastName,
            login: searchLoginName
        }
        dispatch(getUsers(usersFilterDto))
    }, [dispatch, currentPage, searchLastName, searchLoginName])

    const handleSearchLastName = (e) => {
        setCurrentPage(1)
        setSearchLastName(getLocalizedValue(e.target.value))
    }

    const handleSearchLogin = (e) => {
        setCurrentPage(1)
        setSearchLoginName(e.target.value)
    }

    const goToCustomUser = (login) => {
        dispatch(getCustomUser(login)).then(() => navigate("/gradebook", {replace: true}))
    }

    const columns =
        [
            {
                title: '№',
                dataIndex: 'id',
                width: '5%',
                align: 'center',
                render: (_, __, index) => {
                    return {
                        children: <div>{((currentPage - 1) * PAGE_SIZE) + index + 1}</div>
                    }
                }
            },
            {
                title: 'Логин',
                dataIndex: 'login',
                width: '20%',
                align: 'center',
                sorter: (a, b) => a.login.localeCompare(b.login),
                render: (login) => {
                    return {
                        children: <div style={{textAlign: 'left'}}>{login}</div>
                    }
                }
            },
            {
                title: 'Пользователь',
                dataIndex: 'fio',
                width: '35%',
                align: 'center',
                sorter: (a, b) => a.fio.localeCompare(b.fio),
                render: (fio) => {
                    return {
                        children: <div style={{textAlign: 'left'}}>{fio}</div>
                    }
                }
            },
            {
                title: 'Последний вход',
                dataIndex: 'lastLogin',
                width: '20%',
                align: 'center',
            },
            {
                title: 'Текущий статус',
                dataIndex: 'isBlocking',
                width: '10%',
                align: 'center',
                render: (isBlocking) => {
                    return {
                        children: <div>{isBlocking ? <TbLock color={'#eb2222'} size={20}/> : <TbLockOpen color={'#2bad45'} size={20} />}</div>
                    }
                }
            },
            {
                title: 'Действия',
                dataIndex: 'login',
                width: '10%',
                align: 'center',
                render: (login) => {
                    return {
                        children: <Tooltip title="Перейти">
                            <Button type={"link"} icon={<IoLogInOutline size={25}/>} onClick={() => goToCustomUser(login)}></Button>
                        </Tooltip>
                    }
                }
            },
        ]

    const getPaginator = () => {
        return <Pagination className={classes.paginator}
                           defaultCurrent={currentPage}
                           current={currentPage}
                           pageSize={PAGE_SIZE}
                           total={users.totalItems}
                           onChange={(page) => {
                               if (page !== currentPage) setCurrentPage(page)
                           }}
                           showSizeChanger={false}/>
    }

    const setUsersTable = () => {
        return width <= 500 ? <React.Fragment>
                {users.accountDtoList.map((account, index) =>
                    <MobileTableItem key={index}
                                     isTitleWithData={true}
                                     titles={["Логин: ", "Пользователь: ", "Последний вход: "]}
                                     data={[
                                         <div style={{display: 'flex'}}>
                                             {account.login}
                                             {account.isBlocking ? <TbLock color={'#eb2222'} size={20}/> :
                                                 <TbLockOpen color={'#2bad45'} size={20}/>}
                                             <Button className={classes.customMobileButton} type={"link"}
                                                     icon={<IoLogInOutline size={20}/>}
                                                     onClick={() => goToCustomUser(account.login)}></Button>
                                         </div>,
                                         getFio(account.fio.split(' ')[0], account.fio.split(' ')[1], account.fio.split(' ')[2]),
                                         account.lastLogin
                                     ]}
                    />)}
                {getPaginator()}
            </React.Fragment>
            : <div className={classes.usersContainer}>
                <div className={classes.tableContainer}>
                    <Table className={classes.table}
                           columns={columns}
                           sticky
                           pagination={false}
                           scroll={{x: 820}}
                           dataSource={users.accountDtoList}/>
                </div>
                {getPaginator()}
            </div>
    }

    const setUsersContainer = () => {
        if (users && users.totalItems === 0) {
            return <div className={classes.noSearchContainer}>
                <img width={100} alt={"NoSearchResults"} src={NoSearchResults} />
                <div>Результаты не найдены.</div>
            </div>
        }
        if (users && users.totalItems !== 0) {
            return setUsersTable()
        }
    }

    return <div className={classes.container}>
        <div className={classes.filtersContainer}>
            <div className={classes.filterSearchContainer}>
                <div className={classes.filterSearchName}>Фамилия</div>
                <Input className={classes.searchButton}
                       suffix={searchLastName.length === 0 ? <IoSearch color={'rgb(0 0 0 / 30%)'} size={16} /> : null}
                       placeholder="Введите фамилию"
                       value={searchLastName}
                       onChange={(e) => handleSearchLastName(e)}
                       allowClear={searchLastName.length !== 0}/>
            </div>
            <div className={classes.filterSearchContainer}>
                <div className={classes.filterSearchName}>Логин</div>
                <Input className={classes.searchButton}
                       suffix={searchLoginName.length === 0 ? <IoSearch color={'rgb(0 0 0 / 30%)'} size={16} /> : null}
                       placeholder="Введите логин"
                       value={searchLoginName}
                       onChange={(e) => handleSearchLogin(e)}
                       allowClear={searchLoginName.length !== 0}/>
            </div>
        </div>
        {!loadingUsers && users ? setUsersContainer() : <Spinner/>}
    </div>
}

export default Users

