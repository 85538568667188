import { useLocation, Navigate, useNavigate } from "react-router";
import { GiCheckMark } from "react-icons/gi";

import classes from './PasswordSuccessRecovery.module.scss'

import PasswordRecoveryWrapper from '../PasswordRecoveryWrapper';
import SubmitButton from '../../../UI/SubmitButton/SubmitButton';

const PasswordSuccessRecovery = () => {
    const location = useLocation()
    const navigate = useNavigate()

    return (
        (location?.state?.prevPath === '/new-password' || location?.state?.prevPath === '/initial-password')
            ? <PasswordRecoveryWrapper
                icon={<GiCheckMark size={35} />}
                title="Успех!"
                color="#52c41a"
                showFooter={false}
                minHeight="300px">
                <div className={classes.container}>
                    <div>Ваш пароль успешно изменён.</div>
                    <SubmitButton
                        type="button"
                        text="Вернуться ко входу в личный кабинет"
                        onClickHandler={() => navigate("/", { replace: true })}
                        submitButtonStyles={{ height: 'fit-content', whiteSpace: 'normal', margin: 'auto', display: 'block' }} />
                </div>
            </PasswordRecoveryWrapper>
            : <Navigate to='/' replace />
    )
}

export default PasswordSuccessRecovery;