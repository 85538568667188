import * as actionTypes from '../actions/actionTypes';

const initialState = {
    filteredAuditory: null,
    loadingFilteredAuditory: false
};

const loadFilteredAuditory = (state) => {
    return {
        ...state,
        loadingFilteredAuditory: true
    }
}

const setFilteredAuditory = (state, action) => {
    let tmp = [...action.filteredAuditory]
    return {
        ...state,
        filteredAuditory: tmp,
        loadingFilteredAuditory: false
    }
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOAD_FILTERED_AUDITORY: return loadFilteredAuditory(state);
        case actionTypes.SET_FILTERED_AUDITORY: return setFilteredAuditory(state, action);
        default: return state;
    }
};
export default reducer;