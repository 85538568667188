import {useEffect} from 'react';
import {Navigate, Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import './App.scss';

import {logout} from './store/actions';

import ProtectedRoute from './services/protectedRoute';

import CreatePasswordViaCode
  from './components/Authentication/PasswordRecovery/CreatePasswordViaCode/CreatePasswordViaCode';
import CreatePasswordViaHash
  from './components/Authentication/PasswordRecovery/CreatePasswordViaHash/CreatePasswordViaHash';
import GetRestorableAccount
  from './components/Authentication/PasswordRecovery/GetRestorableAccount/GetRestorableAccount';
import MainLayout from './hoc/MainLayout';
import LoginForm from './components/Authentication/LoginForm/LoginForm';
import MaskRecovery from './components/Authentication/PasswordRecovery/MaskRecovery/MaskRecovery';
import NotFound from './components/UI/NotFound/NotFound';
import PasswordSuccessRecovery
  from './components/Authentication/PasswordRecovery/PasswordSuccessRecovery/PasswordSuccessRecovery';
import ProfileSettings from './components/ProfileSettings/ProfileSettings';
import RestorableAccountBlocked
  from './components/Authentication/PasswordRecovery/RestorableAccountBlocked/RestorableAccountBlocked';
import Gradebook from './components/Gradebook/Gradebook';
import Calendar from './components/Gradebook/Calendar/Calendar';
import SubgroupSeparator from './components/Gradebook/SubgroupSeparator/SubgroupSeparator';
import JournalMobile from './components/Gradebook/JournalMobile/JournalMobile';
import StudentGroupPicker from './components/Gradebook/JournalMobile/StudentGroupPicker/StudentGroupPicker';
import Users from "./components/Users/Users";
import Positions from "./components/Positions/Positions"
import Announcements from "./components/Announcements/Announcements";
import PersonalPage from "./components/PersonalPage/PersonalPage";
import ChangePasswordAuth from './components/Authentication/ChangePasswordAuth/ChangePasswordAuth';

function App() {
  const user = useSelector(state => state.auth.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (localStorage.getItem("_expiredTime")) {
      const expiredTime = parseInt(localStorage.getItem("_expiredTime"), 10);
      if (expiredTime < Date.now()) {
        dispatch(logout()).then(() => {
          navigate('/');
        })
      }
    }
  }, [dispatch, navigate])

  const getReturnUrl = () => {
    if (location.pathname === '/gradebook/byLesson') {
      return '/?returnUrl=/gradebook';
    } else {
      return `/?returnUrl=${location.pathname}${location.search}`;
    }
  }

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<MainLayout />}>

          <Route element={<ProtectedRoute isAllowed={!user} />}>
            <Route index element={<LoginForm />} />
            <Route path="/login/change-password" element={<ChangePasswordAuth />} />
            <Route path="/new-password" element={<CreatePasswordViaCode />} />
            <Route path="/initial-password" element={<CreatePasswordViaHash/>} />
            <Route path="/password-recovery" element={<GetRestorableAccount />} />
            <Route path="/password-recovery/error" element={<RestorableAccountBlocked />} />
            <Route path="/password-recovery/mask" element={<MaskRecovery />} />
            <Route path="/password-recovery/success" element={<PasswordSuccessRecovery />} />
          </Route>

          <Route element={<ProtectedRoute isAllowed={!!user} redirectPath={getReturnUrl()} />}>
            <Route path="/settings" element={<ProfileSettings />} />
            <Route path="/gradebook" element={(user?.anyLessons || user?.authorities.includes("ROLE_ADMIN") || user?.authorities.includes("PERMISSION_JOURNAL_OF_CURRENT_PERFORMANCE_VIEW_ALL_TEACHERS")) ? <Gradebook /> : <Navigate to="/personal-page"/>} />
            <Route path="/gradebook/calendar" element={<Calendar />} />
            <Route path="/gradebook/byLesson" element={<JournalMobile />} />
            <Route path="/gradebook/byLesson/groups" element={<StudentGroupPicker />} />
            <Route path="/gradebook/students" element={<SubgroupSeparator />} />
            <Route path="/personal-page" element={<PersonalPage />} />
            <Route path="/announcements" element={(user?.anyLessons || user?.authorities.indexOf("ROLE_ADMIN") !== -1) ? <Announcements /> : <Navigate to="/settings"/>} />
            <Route path="/users" element={(user?.authorities.indexOf("ROLE_ADMIN") !== -1) ? <Users /> : <Navigate to="/settings"/>} />
            <Route path="/positions" element={<Positions />} />
          </Route>

          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
