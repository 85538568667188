import { MdPhoneIphone, MdOutlineEmail } from "react-icons/md";

export const recoveryMethods = [
    {
        typeId: 4,
        icon: <MdPhoneIphone />,
        promptText: 'По номеру телефона',
        name: 'Номер телефона',
        maxLength: 7,
        maskRecoveryValidationRules: [
            {
                required: true,
                message: 'Введите недостающую часть номера телефона!',
            },
            {
                pattern: /^\d{7,}$/g,
                message: 'Некорректный формат номера телефона!',
            },
        ]
    },
    {
        typeId: 6,
        icon: <MdOutlineEmail />,
        promptText: 'По e-mail',
        name: 'E-mail',
        maxLength: 320,
        maskRecoveryValidationRules: [
            {
                required: true,
                message: 'Введите недостающую часть электронной почты!',
            },
            {
                pattern: /^([-!#-'*+/-9=?^-~]+(\.[-!#-'*+/-9=?^-~]+)*|"(!#-[^-~ \t]|(\\[\t -~]))+")$/gi,
                message: 'Некорректный формат электронной почты!',
            },
        ]
    },
]