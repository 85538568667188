import {Checkbox, Select} from "antd";
import {useEffect, useState} from "react";
import {faNoteSticky} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const OmissionHoursMobileInput = (props) => {
  const [omissionHours, setOmissionHours] = useState(null);
  const [stateCheckBox, setStateCheckBox] = useState(null);

  const [noteButtonPressed, setNoteButtonPressed] = useState(false);

  const getLessonPeriodHours = () => props.lesson.lessonPeriodHours || 2;

  const options = [];
  for (let i = 0; i < getLessonPeriodHours(); i++) {
    options.push({
      label: i + 1 + " ч",
      value: i + 1,
    });
  }

  useEffect(() => {
    setOmissionHours(props.omissionHours);
    setStateCheckBox(!!props.omissionHours);
  }, [props.omissionHours]);

  const updateOmission = (omissionHoursValue) => {
      setOmissionHours(omissionHoursValue);
      const params = {
          missedHours: omissionHoursValue,
          lessonId: props.lesson.id,
          studentIds: [props.student.id],
          note: props.omission?.note || null,
      }
      props.updateOmission(props.student.id, params);
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center', width: '150px', justifyContent:'flex-start' }}>
      <Checkbox
        className={props.classNameCheckBox}
        checked={stateCheckBox}
        disabled={props.student.inOffsettingValue !== 0}
        onChange={() => {
          setStateCheckBox((oldValue) => {
            if (oldValue) {
              setOmissionHours(null);
            } else {
              setOmissionHours(getLessonPeriodHours());
            }
            return !oldValue;
          });
          if (stateCheckBox) {
              updateOmission(0);
          } else {
            updateOmission(getLessonPeriodHours());
          }
        }}
      />
      <Select
        className={props.omission?.respectfulOmission ? props.classNameRespectSelect : props.classNameSelect}
        options={options}
        value={omissionHours ? `${omissionHours} ч` : ""}
        disabled={!stateCheckBox}
        onChange={(value) => {
            setOmissionHours(value);
            const params = {
                missedHours: value,
                lessonId: props.lesson.id,
                studentIds: [props.student.id],
                note: props.omission.note,
            }
            props.updateOmission(props.student.id, params);
        }}
      />
      {
        props.omissionHours &&
        <FontAwesomeIcon className={`${props.classNameNoteBtn} ${noteButtonPressed ? props.classNameAnimButton : null}`}
                          icon={faNoteSticky}
                          size={'lg'}
                          color={props.omission?.note ? '#1890ff' : '#b4d7ff'}
                          onClick={(_e) => {
                              setNoteButtonPressed(true);
                          }}
                          onAnimationEnd={() => {
                              setNoteButtonPressed(false);
                              if (props.onNoteButtonClicked) {
                                  props.onNoteButtonClicked();
                              }
                          }} />
      }
    </div>
  );
};

export default OmissionHoursMobileInput;
