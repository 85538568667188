import React, {useRef, useState} from "react";
import parentClasses from '../PersonalPage.module.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPencilAlt} from '@fortawesome/free-solid-svg-icons';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {Button} from "antd";
const AdditionalInformation = (props) => {

    const [initialArea, setInitialArea] = useState(props.element.content)
    const [changedArea, setChangedArea] = useState(props.element.content)
    const [hideArea, setHideArea] = useState(true);

    const updateArea = () => {
        const updatedEmployeeDetails = {...props.employeeDetails}
        if (changedArea === null && changedArea === initialArea) {
            setInitialArea(changedArea)
            setHideArea(true)
            return;
        }

        const newAdditionalInformation = ({...props.element, content: changedArea})

        updatedEmployeeDetails.additionalInformation = updatedEmployeeDetails.additionalInformation.map(
            (information) => (information.idType === newAdditionalInformation.idType ? newAdditionalInformation : information)
        )

        setInitialArea(changedArea)
        setHideArea(true)
        props.updateAdditionalInformation({
            ...props.employeeDetails,
            additionalInformation: updatedEmployeeDetails.additionalInformation
        }, newAdditionalInformation)
    }

    const cancelChangesArea = () => {
        setHideArea(true)
        setChangedArea(initialArea)
    }

    const modules = {
        toolbar: [
            [{header: [1, 2, 3, 4, 5, 6, false]}],
            [{font: []}],
            [{size: []}],
            ["bold", "italic", "underline", "strike", "blockquote"],
            [
                {list: "ordered"},
                {list: "bullet"},
                {indent: "-1"},
                {indent: "+1"},
            ],
            ["link", "image", "video"],
            ["clean"],
            ["code-block"],
        ],
    }

    const formats = [
        "header",
        "font",
        "size",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "link",
        "image",
        "video",
        "code-block",
    ]

    const handleChange = (value, _delta, _source, editor) => {
        editor.getText().trim() === '' ? setChangedArea(null) : setChangedArea(value);
    }
    const scrollingContainerRef = useRef(null);

    return <div>
        <div className={parentClasses.headerTab} style={!hideArea ? {paddingBottom: 10} : null}>
            <div className={parentClasses.sectionTitle}>{props.element.nameType}</div>
            <div className={parentClasses.buttonAdditionalInformation}>
                {!hideArea &&
                    <Button type='secondary' className={parentClasses.cancelButton}
                            style={{marginLeft: 10, width: 105}}
                            onClick={() => cancelChangesArea()}>
                        Отмена
                    </Button>
                }
                {hideArea ?
                    <button className={parentClasses.button}
                            onClick={() => setHideArea(false)}>
                        <FontAwesomeIcon icon={faPencilAlt} style={{color: "#3A547F"}}/>
                    </button>
                    :
                    <Button className={parentClasses.submitButton} onClick={() => updateArea()}>
                        Сохранить
                    </Button>
                }
            </div>

        </div>

        <div className={parentClasses.bodyTab} ref={scrollingContainerRef}>
            {hideArea
                ? <div dangerouslySetInnerHTML={{__html: changedArea}}
                       style={{fontSize: 14, margin: 0}}></div>
                :
                    <ReactQuill
                        theme="snow"
                        value={changedArea}
                        onChange={handleChange}
                        modules={modules}
                        formats={formats}
                    />
            }
        </div>
    </div>
}

export default AdditionalInformation;