import classes from './Loader.module.scss';
import {Spin} from "antd";

const Loader = (props) => {
    return (<div>
        {
            props.loading && <div className={classes.backdrop}>
                <Spin className={classes.loadingSpinner} size='large'/>
            </div>
        }
    </div>);
}
export default Loader;