export const InitialData = {
    elements: [
        {
          fileId: "screenshot",
          type: "image",
          x: 0,
          y: 0,
          width: 1920,
          height: 1080,
          angle: 0,
          strokeColor: "transparent",
          backgroundColor: "transparent",
          fillStyle: "hachure",
          strokeWidth: 1,
          strokeStyle: "solid",
          roughness: 1,
          opacity: 100,
          groupIds: [],
          strokeSharpness: "round",
          seed: 707269846,
          version: 143,
          versionNonce: 2028982666,
          isDeleted: false,
          boundElements: null,
          updated: 1644914782403,
          link: null,
          status: "pending",
          scale: [1, 1]
        }
      ],
    appState: { viewBackgroundColor: "#d2e0f5", currentItemFontFamily: 1, currentItemStrokeColor: "#D21404" },
    scrollToContent: true,
};
