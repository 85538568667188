import dayjs from 'dayjs';

export const semesters = [
    {
        key: 'autumn',
        value: 1,
        name: 'Осенний'
    },
    {
        key: 'spring',
        value: 0,
        name: 'Весенний'
    }
]

export const DateMonth = {
    JANUARY: 0,
    FEBRUARY: 1,
    MARCH: 2,
    APRIL: 3,
    MAY: 4,
    JUNE: 5,
    JULY: 6,
    AUGUST: 7,
    SEPTEMBER: 8,
    OCTOBER: 9,
    NOVEMBER: 10,
    DECEMBER: 11
}

export const DAYS_PER_WEEK = 7;
export const STUDY_WEEKS_COUNT = 4;

export const getFormattedDateStringFromTimestamp = (timestamp) => {
    return timestamp ? dayjs(timestamp).format("DD.MM") : null;
}

export const getFullFormattedDateStringFromTimestamp = (timestamp) => {
    return timestamp ? dayjs(timestamp).format("DD.MM.YYYY") : null;
}

export const getFormattedTimeFromTimestamp = (timestamp) => {
    return timestamp ? dayjs(timestamp).format("HH:mm") : null;
}

export const getMonthExpirationDate = () => {
    const expirationDate = new Date()
    expirationDate.setMonth(expirationDate.getMonth() + 1)
    return expirationDate
}

export const generateYears = (yearsCount) => {
    let currentYear = getCurrentEducationalYear();
    let years = []
    for (let i = 0; i < yearsCount; i++)
        years.push(currentYear - i)
    return years;
}

export const generateYearsUsingLowerAndUpperBound = (lowerBound, upperBound) => {
    let years = []
    for (let i = upperBound; i >= lowerBound; i--) {
        years.push(i)
    }
    return years;
}

export const generateSemesters = (yearsCount) => {
    let terms = []
    generateYears(yearsCount).forEach(year => semesters.forEach(half => {
        terms.push({
            year: year,
            halfOfYear: half
        })
    }))
    return terms
}

export const getCurrentHalfOfYear = () => {
    const currentMonth = new Date().getMonth()
    return semesters.find(term => term.key === (currentMonth <= DateMonth.JULY ? "spring" : "autumn"))
}

export const getCurrentEducationalYear = () => {
    const today = new Date();
    const currentMonth = today.getMonth();
    return currentMonth <= DateMonth.JULY ? today.getFullYear() - 1 : today.getFullYear();
}

export const getLatestAcademicLoadYear = () => {
    const today = new Date();
    const currentMonth = today.getMonth();
    return currentMonth <= DateMonth.MAY ? today.getFullYear() - 1 : today.getFullYear();
}

export const getWeek = (sourceDate) => {
    const date = new Date(sourceDate);
    date.setHours(0, 0, 0, 0);

    let educationYear = date.getFullYear();
    if (date.getMonth() < DateMonth.AUGUST) {
        --educationYear;
    }

    const firstOfSeptember = new Date(educationYear, DateMonth.SEPTEMBER, 1, 0, 0, 0, 0);
    let diffInDays = dayjs(date).diff(firstOfSeptember, 'days')
        + (firstOfSeptember.getDay() + (DAYS_PER_WEEK - 1)) % DAYS_PER_WEEK;// смещение до понедельника недели с 1 сентября; 0-воскресенье приводится к 0-понедельнику
    while (diffInDays < 0) {
        diffInDays += DAYS_PER_WEEK * STUDY_WEEKS_COUNT;
    }

    const diffInWeeks = Math.trunc(diffInDays / DAYS_PER_WEEK);
    return diffInWeeks % STUDY_WEEKS_COUNT;
}

export const getCurrentWeekFilters = () => {
    const tmp = Array(STUDY_WEEKS_COUNT).fill(false)
    tmp[getWeek(new Date())] = true
    return tmp
}