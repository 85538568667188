import {Alert, Button, InputNumber} from "antd";
import classes from "./gradeBookService.module.scss";
import React from "react";

export const getAllLabNumbers = (selectedLabCount) => {
    let allLabNumbers = []
    for (let i = 1; i <= selectedLabCount; i++) {
        allLabNumbers.push(i)
    }
    return allLabNumbers
}

export const getBookedLabNumbers = (studentId, students) => {
    let labNumbers = []
    let lessonMarks = students.length !== 0 ? students.find(stud => stud.id === studentId).lessonMarks : []
    for (const lesson of lessonMarks) {
        lesson.marks.forEach(mark => {if (mark.labNumber) labNumbers.push(mark.labNumber)})
    }
    return labNumbers
}

export const getFreeLabNumbers = (bookedLabNumbers, allLabNumbers) => {
    return allLabNumbers.filter(labN => !bookedLabNumbers.includes(labN))
}

export const getLabMessage = (subject, group, valueLabCount, setValueLabCount, updateLabCount) => {
    return <Alert className={classes.labMessage}
                  type="error"
                  showIcon
                  message={`Для продолжения работы введите общее КОЛИЧЕСТВО лабораторных работ по ${subject} для ${group} (это значение можно будет изменить)`}
                  description={<div>
                      <span style={{marginRight: '5px'}}>Всего лабораторных:</span>
                      <div className={classes.labInputContainer}>
                          <InputNumber className={classes.labInput}
                                       defaultValue={6}
                                       min={1}
                                       max={20}
                                       onChange={(value) => setValueLabCount(value)}/>
                          <Button className={classes.labButton}
                                  disabled={!valueLabCount}
                                  onClick={() => updateLabCount(true)}
                          >Сохранить</Button>
                      </div>
                  </div>}
    />
}

export const getSubGroupMessage = () => {
    return <Alert className={classes.subgroupMessage}
                  type="error"
                  message={`Для работы с журналом распределите всех студентов по подгруппам!`}           
    />
}
