import classes from './Calendar.module.scss';
import { MdOutlineKeyboardArrowLeft, MdArrowLeft, MdArrowRight } from "react-icons/md";
import { BiDotsHorizontalRounded } from "react-icons/bi"
import { setGradeBookLessonsDate, setGradeBookLessonsNumber } from "../../../store/actions/gradeBook";
import { NavLink, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import useWindowDimensions from '../../../hooks/windowDimensions';
import { useDispatch, useSelector } from "react-redux";
import { dateToString, stringToDate, compareDates, compareMonthes, combineLessons } from "../../../services/util";
import axios from '../../../services/axios';
import { setMessage } from '../../../store/actions/common';
import Loader from "../../UI/Loader/Loader";

const Calendar = () => {
    const months = ["Январь", "Февраль", "Март", "Апрель",
        "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь",
        "Ноябрь", "Декабрь"];

     const shortMonths = ["Янв.", "Февр.", "Март", "Апр.",
        "Май", "Июнь", "Июль", "Авг.", "Сент.", "Окт.",
        "Нояб.", "Дек."];
         
    const weekDays = [
        "Пн","Вт","Ср","Чт","Пт","Сб","Вс"
    ];
    
    const nDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    const currentDate = useSelector(state => state.gradeBook.currentLessonsDate);

    const isEmployee = useSelector(state => state.gradeBook.isEmployee)
    const [activeDate, setActiveDate] = useState(stringToDate(currentDate));
    const [lessons, setLessons] = useState([])
    const {width} = useWindowDimensions();
    const [isLoading, setIsLoading] = useState(false);
    const user = useSelector(state => state.auth.user);
    const [backButtonAnimation, setBackButtonAnimation] = useState(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const gradeBookTargetFromStore = useSelector(state => state.gradeBook.selectedStudentGroupOrEmployee)


    useEffect(() => {
        setIsLoading(true);
        let gradebookTargetId = 0;
        if ((user.authorities.includes("ROLE_ADMIN") || user.authorities.includes("PERMISSION_JOURNAL_OF_CURRENT_PERFORMANCE_VIEW_ALL_TEACHERS")) && gradeBookTargetFromStore !== null) {
            
            gradebookTargetId = gradeBookTargetFromStore.id
        }
        else {
            gradebookTargetId = user.personId
        }
        axios.get('/gradebook/lessons-by-month',
        {
            params: {
                month: activeDate.getMonth() + 1,
                year: activeDate.getFullYear(),
                employeeId: isEmployee ? gradebookTargetId : null,
                studentGroupId: !isEmployee? gradebookTargetId : null,
            }
        })
        .then((response) => {
            setLessons(combineLessons(response.data));
        })
        .catch((_error) => {
            dispatch(setMessage({ type: 'warning', text: "Не удалось подключиться к серверу" }));
        })
        .finally(() => {
            setIsLoading(false);
        })
    }, [activeDate, dispatch])

    const checkFebruary = (month, year, maxDays) => {
        if (month === 1) {
            if ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0) {
            maxDays += 1;
            }
        }
        return maxDays;
    }

    const generateMatrix = () => {
        let matrix = [];
        let year = activeDate.getFullYear();
        let month = activeDate.getMonth();
     
        let firstDay = new Date(year, month, 1).getDay();
        firstDay = firstDay === 0 ? 6 : firstDay - 1;
        let maxDays = nDays[month];
        maxDays = checkFebruary(month, year, maxDays);
        let counter = 1;
        for (let row = 0; row < 6; row++) {
            matrix[row] = [];
            if (counter > maxDays) return matrix;
            for (let col = 0; col < 7; col++) {
                matrix[row][col] = -1;
                if ((row === 0 && col >= firstDay) || (row > 0 && counter <= maxDays)) {
                    matrix[row][col] = counter++;
                }
            }
        }
        return matrix;
    }
    
    const changeMonth = (n) => {
        setActiveDate((oldDate) => {
            const oldDateCopy = new Date(oldDate.valueOf());
            oldDateCopy.setDate(1);
            oldDateCopy.setMonth(oldDate.getMonth() + n);
            return oldDateCopy;
        })
    }

    const onPress = (item) => {
        if (item !== -1) {
            const newDate = new Date(activeDate.valueOf());
            newDate.setDate(item);
            const number = lessons.filter(l => l.lessonDate === dateToString(new Date(activeDate.getFullYear(),activeDate.getMonth(), item, 0, 0, 0, 0))).length;
            dispatch(setGradeBookLessonsDate(dateToString(newDate)));
            dispatch(setGradeBookLessonsNumber(number))
        }
    };

    const setLessonType = (lesson) => {
        let itemClass = ''

        if (lesson.lessonTypeAbbrev === 'ЛК' || lesson.lessonTypeAbbrev === 'УЛк') itemClass = classes.markAsLecture
        if (lesson.lessonTypeAbbrev === 'ПЗ' || lesson.lessonTypeAbbrev === 'УПз') itemClass = classes.markAsSeminar
        if (lesson.lessonTypeAbbrev === 'ЛР' || lesson.lessonTypeAbbrev === 'УЛР') itemClass = classes.markAsLabWork
        if (lesson.lessonTypeAbbrev === 'Консультация') itemClass = classes.markAsConsultation
        if (lesson.lessonTypeAbbrev === 'Экзамен' || lesson.lessonTypeAbbrev === 'Кандидатский экзамен') itemClass = classes.markAsExam
        if (lesson.lessonTypeAbbrev === 'Зачет' || lesson.lessonTypeAbbrev === 'Кандидатский зачет') itemClass = classes.markAsOffset
        if (lesson.lessonTypeAbbrev === 'КПР(Р)') itemClass = classes.markAsCourseProject

        return itemClass
    }

    const setDayType = (item) => {
        let itemClass = undefined;
        if ((item === activeDate.getUTCDate()) && compareDates(activeDate, stringToDate(currentDate)) || (item === new Date().getUTCDate() && compareMonthes(activeDate, new Date()))) {
            itemClass = classes.selectedDay;
            if(item === new Date().getUTCDate()) {
                itemClass = classes.currentDay;
            }
        }
        return itemClass;
    }

    const weekDayOfSelectedDate = (index) => {
        return new Date().getDay() === (index === 6 ? 0 : index + 1)
    }

    let matrix = generateMatrix()

    return <div className={classes.container}>
        <Loader loading={isLoading} />
        <React.Fragment>
            <div style={{display: 'flex', alignItems: 'center'}}> 
                <div style={{paddingTop: '7px'}}>
                    <MdOutlineKeyboardArrowLeft size={35} className={`${classes.backButton} ${backButtonAnimation}`}
                    onAnimationEnd = {() => {
                        navigate("/gradebook")
                        setBackButtonAnimation(null);
                    }}
                    onClick = {() => { setBackButtonAnimation(classes.wave) }}/>
                </div>       
                <div className={classes.date}>
                    <MdArrowLeft size={35} onClick={() => changeMonth(-1)}/>
                    {width > 320 ? months[activeDate.getMonth()] : shortMonths[activeDate.getMonth()]} &nbsp;
                    {activeDate.getFullYear()}
                    <MdArrowRight size={35} onClick={() => changeMonth(1)}/>
                </div>
            </div>
            <table className={classes.dateGrid} style={{width: '100%', borderCollapse: 'collapse'}}>
                <thead className={classes.weekDays}>
                {weekDays.map((wd, index) => (
                    <div className={(compareMonthes(activeDate, new Date()) && weekDayOfSelectedDate(index)) ? classes.selectedWeekDay : undefined}  key={wd}>
                        {wd}
                        <div className={index !== 0 && classes.line}></div>
                    </div>))
                }
                </thead>
                <tbody>
                {matrix.map((row) => (<tr>{
                    row.map((item) => {
                        return (
                            <td className={classes.cell}>
                            <NavLink to={"/gradebook"}>
                                <button className={classes.cellOfGrid} onClick={() => onPress(item)}>
                                {item !== -1 && 
                                <div className={classes.buttonContent}>
                                    <div className={setDayType(item)}>
                                        {item}
                                    </div>
                                    <div style={{paddingTop: '2px'}}>
                                        {lessons.filter(l => l[0].lessonDate === dateToString(new Date(activeDate.getFullYear(),activeDate.getMonth(), item, 0, 0, 0, 0))).map((day, index) =>
                                        <div>
                                            {index < 3 && <div className={setLessonType(day[0])}>{day[0].lessonName}</div>}
                                            {index === 3 && <BiDotsHorizontalRounded size={18} style={{float: 'left'}}/>}
                                        </div>
                                        )}
                                        </div>
                                </div>
                                }
                                </button>
                            </NavLink>
                            </td>
                        )
                    })}
                </tr>))
                }
                </tbody>
            </table>
        </React.Fragment>
    </div>
}

export default Calendar;