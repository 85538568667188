import React, {useState} from 'react';
import {Button, Dropdown, Select, Table} from "antd";
import classes from "./MarksTable.module.scss";
import {formatFioWithInitials} from "../../../../services/util";
import {MdStarRate} from "react-icons/md";
import {getBookedLabNumbers, getFreeLabNumbers} from "../../../../services/Gradebook/gradeBookService";
import NumberInput from "../../../UI/NumberInput/NumberInput";
import {TbEdit} from "react-icons/tb";
import MarkNoteInputModal from "./MarkNoteInputModal/MarkNoteInputModal";
import PercentageInput from "../../../UI/Input/PercentageInput";
import {GoKebabHorizontal} from "react-icons/go";

const isNotLabSymbol = '';
const {Option, OptGroup} = Select;

const MarksTable = (props) => {

    const [isAddNoteModalOpen, setIsAddNoteModalOpen] = useState(false);

    const [selectedStudent, setSelectedStudent] = useState(null);

    const [selectedStudentMark, setSelectedStudentMark] = useState(null);
    const [selectedStudentFreeLabs, setSelectedStudentFreeLabs] = useState([]);

    const items = [
        {
            key: '1',
            label: <Button className={classes.kebabSubButton}
                           icon={<TbEdit style={{marginRight: '5px'}} color={"#1677ff"} size={20}/>}
                           onClick={() => {
                               props.setValueLabCount(props.selectedLabCount)
                               props.setIsDialogEditLabOpen(true)
                           }}
                           type={"link"} href>Редактировать количество лр</Button>
        }
    ];

    const inOffsettingProps = (student) => {
        return {
            style: {
                backgroundColor: (student.inOffsettingValue !== 0 || student.expelledInCurrentSemester) && '#f5f5f5',
                padding: (student.inOffsettingValue !== 0 || student.expelledInCurrentSemester) && '5px 16px'
            }
        }
    }

    const columns = [
        {
            title: 'ФИО',
            dataIndex: 'fio',
            key: 2,
            onCell: inOffsettingProps,
            render: (_text, student, index) => {
                const fioCell = <div tabIndex={-1} className={classes.fioCell}>
                    <span style={{
                        marginRight: '10px',
                        color: student.lessonOmissions?.some(les => les.idLesson === props.currentLesson.id) ? '#9d9b9b' : '',
                        userSelect: 'none'
                    }}>
                        {index + 1}
                    </span>
                    <span style={{
                        userSelect: 'none',
                        color: student.lessonOmissions?.some(les => les.idLesson === props.currentLesson.id) ? '#9d9b9b' : ''
                    }}>
                        {formatFioWithInitials(student)}
                    </span>
                    {student?.headman && <MdStarRate size={20} style={{color: '#1677ff', verticalAlign:'top', marginLeft:'1px'}}/> }
                    {student.lessonOmissions?.some(les => les.idLesson === props.currentLesson.id) &&
                        <div className={student.lessonOmissions?.filter(les => 
                            les.idLesson === props.currentLesson.id)[0].omission.respectfulOmission ? 
                                classes.hiddenRespectfulOmissionContainer : classes.hiddenOmissionContainer}>
                        {`${student.lessonOmissions?.filter(les => les.idLesson === props.currentLesson.id)[0].omission.number} ч`}
                    </div>}    
                </div>
                return props.getWithOverlayTrigger(fioCell, props.getStudentDataTooltip(student))
            },
            width: '50%',
            align: 'center',
            fixed: true,
        },
        {
            title: <div className={classes.markHeaderCell}>
                <div style={{flex: 1}}>{props.isPercentage ? 'Проценты' : 'Оценки'}</div>
                {props.currentLesson.lessonTypeAbbrev === 'ЛР' && props.selectedLabCount &&
                    <Dropdown trigger={['click']} menu={{items}}>
                        <Button type={"link"} icon={<GoKebabHorizontal size={22} style={{color: '#1677ff', rotate: '90deg'}}
                                                                     className={classes.kebabButton}/>}/>
                    </Dropdown>}
            </div>,
            dataIndex: 'marks',
            align: "center",
            key: 3,
            render: (_, student) => {
                const studentsMarkColumns = [];
                const studentMarks = student.lessonMarks.find(les => les.idLesson === props.currentLesson.id)?.marks;
                const numberOfMarks = studentMarks ? studentMarks.length : 0;
                const freeLabNumbers = isLabCount() ? getFreeLabNumbers(getBookedLabNumbers(student.id, props.students), props.allLabNumbers.filter(lab => lab !== isNotLabSymbol)) : []
                if (studentMarks) setInputs(student, studentMarks, freeLabNumbers, studentsMarkColumns)
                setHiddenInputs(student, studentMarks, freeLabNumbers, studentsMarkColumns, numberOfMarks)

                return {
                    props: {
                        style: {
                            padding: '0',
                            whiteSpace: 'nowrap',
                            textAlign: 'left'
                        }
                    },
                    children: getCell(student, studentsMarkColumns)
                }
            }
        }
    ];

    const getCell = (student, studentsMarkColumns) => {
        let resultCell;
        if (student.expelledInCurrentSemester) {
            resultCell = <div className={classes.inOffsettingCell}>Отчислен</div>
        } else if (student.inOffsettingValue !== 0) {
            resultCell = <div className={classes.inOffsettingCell}>Перезачтено{student.inOffsettingValue !== 1 && ` с оценкой ${student.inOffsettingValue}`}</div>
        } else {
            resultCell = studentsMarkColumns
        }
        return resultCell;
    }

    const setInputs = (student, studentMarks, freeLabNumbers, studentsMarkColumns) => {
        for (let i = 0; i < studentMarks.length; i++) {
            const mark = studentMarks[i];
            const input = createNumberInput(student, i, studentMarks, freeLabNumbers, mark);
            studentsMarkColumns.push(
                isLabCount()
                    ? <div style={{position: 'relative', display: 'inline-block', width: '102px'}}>
                        <div style={{display: 'flex'}}>
                            {input}
                            <div className={classes.labNumberContainer}>
                                <Select className={classes.selectLabNumber}
                                        popupClassName={classes.selectLabNumberOption}
                                        value={studentMarks[i].labNumber || isNotLabSymbol}
                                        onSelect={(value) => {
                                            const newMark = {
                                                ...mark,
                                                labNumber: value === isNotLabSymbol ? null : value
                                            };
                                            if (props.updateMark) {
                                                props.updateMark(student, newMark);
                                            }
                                        }}>
                                    <OptGroup label={'№ лр'}>
                                        {props.allLabNumbers.map(item => (
                                            <Option value={item} label={item}
                                                    disabled={!freeLabNumbers.includes(item) && item !== isNotLabSymbol}
                                                    children={item}/>
                                        ))}
                                    </OptGroup>
                                </Select>
                            </div>
                        </div>
                        {
                            mark.note &&
                            <div className={classes.hasNoteSign}/>
                        }
                    </div>
                    : input
            );
        }
    }

    const setHiddenInputs = (student, studentMarks, freeLabNumbers, studentsMarkColumns, numberOfMarks) => {
        for (let i = 0; i < (props.numberOfMarkColumns - numberOfMarks + 1); i++) {
            studentsMarkColumns.push(<span
                onKeyDown={(e) => {
                    if (props.isLoading) {
                        e.preventDefault();
                    }
                }}>
                {createNumberInput(student, i, studentMarks, freeLabNumbers)}
            </span>)
        }
    }

    const createNumberInput = (student, index, studentMarks, freeLabNumbers, mark) => {
        const key = mark?.id || student.id * 10 + index;
        const needToFocus = props.selectedStudentId === student.id && index === studentMarks?.length - 1;
        if (!props.isPercentage) {
            return (<NumberInput
                {...getNumberInputProps(key, index, mark, needToFocus, student, studentMarks, freeLabNumbers)}
            />);
        } else {
            return <PercentageInput
                key={key}
                value={mark?.number}
                style={{width: '102px'}}
                className={classes.markInputCell}
                needToFocus={needToFocus}
                onChange={(val) => {
                    handleValueChange(val, mark, student, freeLabNumbers);
                }}
                onBlur={handleValueBlur}
                onLongPress={() => handleValueLongPress(index, mark, student, studentMarks, freeLabNumbers)}
            />
        }
    };

    const getNumberInputProps = (key, index, mark, needToFocus, student, studentMarks, freeLabNumbers) => {
        return {
            min: 0,
            max: 10,
            ...(mark && {key: key}),
            ...(mark && {value: mark.number}),
            className: (isLabCount() && mark) ? classes.labMarkInputCell : classes.markInputCell,
            style: {width: isLabCount() ? '102px' : '78px'},
            ...(mark && {needToFocus: needToFocus}),
            ...(!mark && {disabled: index !== 0}),
            onChange: (e) => {
                handleValueChange(e.target.value, mark, student, freeLabNumbers);
            },
            onBlur: handleValueBlur,
            ...((mark || (!mark && index === 0)) && {
                onLongPress: () => handleValueLongPress(index, mark, student, studentMarks, freeLabNumbers)
            })
        }
    }

    const handleValueChange = (newValue, mark, student, freeLabNumbers) => {
        if (mark) {
            if (newValue) {
                const newMark = {...mark, number: newValue};
                if (props.updateMark) {
                    props.updateMark(student, newMark);
                }
            } else if (props.deleteMark) {
                props.deleteMark(student, mark.id);
            }
        } else {
            if (newValue && props.saveMark) {
                const markToSave = {
                    mark: newValue,
                    labNumber: freeLabNumbers.length !== 0 ? freeLabNumbers[0] : null
                };
                props.saveMark(student, markToSave);
            }
        }
        props.setSelectedStudentId(student.id);
    }

    const handleValueBlur = () => props.setSelectedStudentId(null);

    const handleValueLongPress = (index, mark, student, studentMarks, freeLabNumbers) => {
        setSelectedStudent(student);
        let studentMark = null;
        if (studentMarks && studentMarks.length > 0 && mark) {
            studentMark = studentMarks[index];
        }
        setSelectedStudentMark(studentMark);
        setSelectedStudentFreeLabs(freeLabNumbers);
        setIsAddNoteModalOpen(true);
    }

    const isLabCount = () => {
        return props.isLab && props.selectedLabCount
    }

    return (<React.Fragment>
        <Table
            className={classes.marksTable}
            rowClassName={row => (row.inOffsettingValue !== 0 || row.expelledInCurrentSemester) && classes.disabledRow}
            dataSource={props.dataSource}
            columns={columns}
            pagination={false}
            scroll={{x: 'max-content'}}
        />

        <MarkNoteInputModal
            isOpen={isAddNoteModalOpen}
            onOk={() => setIsAddNoteModalOpen(false)}
            onCancel={() => setIsAddNoteModalOpen(false)}
            title={selectedStudent ? `Выставить ${formatFioWithInitials(selectedStudent)}` : null}
            studentMark={selectedStudentMark}
            student={selectedStudent}
            updateMark={props.updateMark}
            saveMark={props.saveMark}
            allLabNumbers={props.allLabNumbers}
            freeLabNumbers={selectedStudentFreeLabs}
            isPercentage={props.isPercentage}
            isLab={props.isLab}
        />
    </React.Fragment>);
}

export default MarksTable;