import * as actionTypes from '../actions/actionTypes';

const initialState = {
    employeeDetails: null,
    loadingEmployeeDetails: false,
    phones: [],
    loadingPhones: false,
    formerJobPositions: [],
    loadFormerJobPositions: false
};

const loadEmployeeDetails = (state) => {
    return {
        ...state,
        loadingEmployeeDetails: true
    }
}

const setEmployeeDetails = (state, action) => {
    let tmp = {...action.employeeDetails}
    return {
        ...state,
        employeeDetails: tmp,
        loadingEmployeeDetails: false
    }
};

const loadPhones = (state) => {
    return {
        ...state,
        loadingPhones: true,
        phones: []
    }
}

const setPhones = (state, action) => {
    return {
        ...state,
        phones: action.phonesData,
        loadingPhones: false
    }
};

const loadFormerJobPositions = (state) => {
    return {
        ...state,
        loadingFormerJobPositions: true,
        phones: []
    }
}

const setFormerJobPositions = (state, action) => {
    console.log(action)
    return {
        ...state,
        formerJobPositions: action.formerJobPositionsData,
        loadingPhones: false
    }
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOAD_EMPLOYEE_DETAILS: return loadEmployeeDetails(state);
        case actionTypes.SET_EMPLOYEE_DETAILS: return setEmployeeDetails(state, action);
        case actionTypes.LOAD_PHONES: return loadPhones(state);
        case actionTypes.SET_PHONES: return setPhones(state, action);
        case actionTypes.LOAD_FORMER_JOB_POSITIONS: return loadFormerJobPositions(state);
        case actionTypes.SET_FORMER_JOB_POSITIONS: return setFormerJobPositions(state, action);
        default: return state;
    }
};
export default reducer;