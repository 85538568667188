import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import { IoAlert } from "react-icons/io5";
import { Spin } from "antd";

import classes from './RestorableAccountBlocked.module.scss'

import PasswordRecoveryWrapper from "../PasswordRecoveryWrapper";
import CountdownTimer from "../../../UI/CountdownTimer/CountdownTimer";

const RestorableAccountBlocked = () => {
    const location = useLocation()
    const navigate = useNavigate()

    const [banExpirationTime, setBanExpirationTime] = useState(null)

    useEffect(() => {
        if (location.state?.time && Date.parse(location.state?.time) > new Date().getTime()) {
            setBanExpirationTime(Date.parse(location.state.time))
        } else navigate('/password-recovery', { replace: true })
    }, [location, navigate])

    return <PasswordRecoveryWrapper
        icon={<IoAlert size={45} />}
        title="Лимит превышен."
        color="#f44336">
        <div className={classes.container}>
            <div className={classes.extra}>Вы превысили лимит попыток ввода. Функционал будет доступен через: </div>
            <div className={classes.banExpirationTimeContainer}>
                {
                    !banExpirationTime
                        ? <Spin />
                        : <CountdownTimer withLabels targetDate={banExpirationTime} onExpireHandler={() => navigate('/password-recovery', { replace: true })} />
                }
            </div>
        </div>
    </PasswordRecoveryWrapper>
}

export default RestorableAccountBlocked;