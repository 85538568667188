import classes from './JournalTable.module.scss';
import {MdStarRate} from "react-icons/md";

const JournalLegend = () => {

    return <div className={classes.tableLegend}> 
        <div>
            <span style={{color:'green'}}>Зеленым </span>
            выделены пропуски по уважительной причине (могут выставляться автоматически при одобрении деканатом заявлений на ОРВИ). 
        </div>
        <div> 
            <span style={{color:'red'}}>Красным </span>выделены пропуски по неуважительной причине.
        </div>
        <div>
            <MdStarRate size={20} style={{color: '#1677ff', verticalAlign: 'top'}}/>
            выделен староста группы.
        </div>
    </div>  
}

export default JournalLegend;
