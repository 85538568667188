import { NavLink } from 'react-router-dom';

import classes from './UnderlinedLink.module.scss'

const UnderlinedLink = (props) => {
    return (
        props.routing
            ? <NavLink to={props.href}
                className={classes.underline}
                onClick={props.onClick}>
                {props.text}
            </NavLink>
            : <a href={props.href}
                target={props.target}
                rel={props.rel}
                className={classes.underline}>
                {props.text}
            </a>
    )
}

export default UnderlinedLink