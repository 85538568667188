import * as actionTypes from '../actions/actionTypes';

const initialState = {
    usersData: null,
    loadingUsers: false,
};

const loadUsers = (state) => {
    return {
        ...state,
        loadingUsers: true
    }
}

const setUsers = (state, action) => {
    let tmp = {...action.usersData}
    return {
        ...state,
        usersData: tmp,
        loadingUsers: false
    }
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOAD_USERS: return loadUsers(state);
        case actionTypes.SET_USERS: return setUsers(state, action);
        default: return state;
    }
};
export default reducer;