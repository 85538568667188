import { FaArrowUp } from "react-icons/fa";
import {FloatButton} from 'antd';

import classes from './ScrollUpButton.module.scss';

const ScrollUpButton = () => {
    return (
        <FloatButton.BackTop className={classes.scrollUpButtonContainer}
                             duration={500}
                             description={<div className={classes.scrollUpButton}>
                                 <FaArrowUp size={20}/>
                             </div>}/>
    );
}

export default ScrollUpButton;
