import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import classes from './MobileTableItem.module.scss';

const MobileTableItem = (props) => {

    const getButtons = (actionsArePresent) => {
        let buttons = []
        if (actionsArePresent) {
            for (const action of props.actions) {
                buttons.push(
                    <button
                        onClick={() => action.action()}
                        disabled={action.isDisabled}
                        className={classes.iconButton}
                    >
                        <FontAwesomeIcon icon={action.icon}/>
                    </button>
                )
            }
        }
        return buttons
    }

    const getActions = (index, actionsArePresent) => {
        return index === 0 && actionsArePresent ?
            <div className={classes.optionsDiv}>
                {getButtons(actionsArePresent)}
            </div>
            : null
    }

    const getClassDisplayInline = (index) => {
        return props.titles[index] === '№' || props.isTitleWithData ? classes.displayInline : ''
    }

    const getClassTitle = (index) => {
        return index === 0 ? classes.optionedTitle : classes.title
    }

    const getClassInlineData = () => {
        return props.isTitleWithData ? classes.inlineData : ''
    }

    let setData = () => {
        let element = []
        let index = 0
        let actionsArePresent = props.actions && props.actions.length > 0
        for (const item in props.data) {
            element.push(
                (props.data[item] === null || props.data[item]?.length === 0) ? '' :
                    <div key={item} className={getClassDisplayInline(index)}>
                        <div className={getClassTitle(index)}>
                            <div style={{width: '100%'}}>{props.titles[index]}</div>
                            {getActions(index, actionsArePresent)}
                        </div>
                        <div className={`${classes.data} ${getClassInlineData()}`}>{props.data[item]}</div>
                    </div>)
            index++
        }
        return element
    }

    return (
        <div className={`${[classes.container, props.classes].join(' ')}`} onClick={props.clicked} id={props.id}>
            {setData()}
        </div>
    );
}

export default MobileTableItem