const routes = [
    {
        pathname: '/initial-password',
        name: 'Кабинет сотрудника'
    },
    {
        pathname: '/',
        name: 'Кабинет сотрудника БГУИР'
    },
    {
        pathname: '/settings',
        name: 'Настройки'
    },
    {
        pathname: '/positions',
        name: 'Должности'
    },
    {
        pathname: '/gradebook',
        name: 'Журнал успеваемости'
    },
    {
        pathname: '/gradebook/calendar',
        name: 'Календарь'
    },
    {
        pathname: '/gradebook/byLesson',
        name: 'Журнал успеваемости'
    },
    {
        pathname: '/gradebook/byLesson/groups',
        name: 'Журнал успеваемости'
    },
    {
        pathname: '/gradebookByLesson/groups',
        name: 'Журнал успеваемости'
    },
    {
        pathname: '/gradebook/students',
        name: 'Разбить на подгруппы'
    },
    {
        pathname: '/announcements',
        name: 'Объявления'
    },
    {
        pathname: '/users',
        name: 'Пользователи'
    },
    {
        pathname: '/personal-page',
        name: 'Личная страница'
    }
]

export default routes