import {Select} from "antd";

const StudyYearSelector = ({
    className,
    studyYears,
    value,
    onChange
}) => {
    return (
        <Select
            className={className}
            placeholder='Учебный год'
            onChange={onChange}
            value={value}
            options={studyYears.map(studyYearStart => ({
                value: studyYearStart,
                label: `${studyYearStart}/${studyYearStart + 1}`
            }))}
        />
    )
}

export default StudyYearSelector