import React, {Fragment} from 'react';
import withRippleAnimation from '../../../../hoc/RippleAnimation/WithRippleAnimation';
import {dateToString, getCombinedGroupNames} from '../../../../services/util';
import classes from './AnnouncementCard.module.scss';
import {FaRegClock} from "react-icons/fa";
import {BsArrowRepeat} from "react-icons/bs";
import {MdDeleteOutline, MdOutlineContentCopy, MdOutlinePlace,} from "react-icons/md";
import {Button, Dropdown} from "antd";
import {TbEdit} from "react-icons/tb";
import {GoKebabHorizontal} from "react-icons/go";

const AnnouncementCard = ({ handleClick, handleCopy, handleDelete, item, alreadyPassed, isRepeatAnnouncement }) => {

    const formatAuditory = (auditory) => {
        let auditoryFormatted = "";
        if (auditory) {
            auditoryFormatted = auditory.name + '-' + auditory.buildingNumber;
        }
        return auditoryFormatted;
    }

    const displayCombinedGroupNames = () => {
        const combinedGroupNames = getCombinedGroupNames(item.groups);

        const groupElements = [];
        for (const element of combinedGroupNames) groupElements.push(<span className={classes.groupTag}>{element}</span>);
        return groupElements;
    }

    const items = [
        {
            key: '1',
            label: <Button onClick={() => handleClick(item)} icon={<TbEdit style={{marginRight: '5px'}} color={"#1677ff"} size={20}/>} type={"link"} href className={classes.kebabSubButton}>Изменить</Button>
        },
        {
            key: '2',
            label: <Button onClick={() => handleCopy()} icon={<MdOutlineContentCopy style={{marginRight: '5px'}} color={"#1677ff"} size={20}/>} type={"link"} href className={classes.kebabSubButton}>Копировать</Button>
        },
        {
            key: '3',
            label: <Button onClick={() => handleDelete(isRepeatAnnouncement)} icon={<MdDeleteOutline style={{marginRight: '5px'}} color={"#ff4d4f"} size={20}/>} type={"link"} href className={classes.kebabSubButton}>Удалить</Button>
        }
    ];

    return (
        <div className={classes.container}>
            <div className={`${classes.header} ${alreadyPassed && classes.alreadyPassedText}`}>
                <div className={classes.announcementDate}>
                    <span style={{marginRight: '5px'}}>{dateToString(new Date(Date.parse(item.date)))}</span>
                    {isRepeatAnnouncement && <BsArrowRepeat size={15}/>}
                </div>
                {item.startTime && <div style={{display: 'flex', alignItems: 'center'}}>
                    <Fragment>
                        <FaRegClock size={14}
                                    className={`${classes.clockIcon} ${alreadyPassed && classes.alreadyPassedText}`}/>
                        <span className={classes.announcementTime}>
                                {item.startTime}-{item.endTime}
                            </span>
                        <Dropdown trigger={['click']} menu={{items}}>
                            <Button type={"link"} icon={<GoKebabHorizontal size={21} style={{color: '#1677ff', rotate: '90deg'}} className={`${alreadyPassed && classes.alreadyPassedText}`}/>} className={classes.kebabButton}/>
                        </Dropdown>
                    </Fragment>
                </div>}
            </div>
            <hr></hr>
            <div className={`${classes.announcementDescription} ${alreadyPassed && classes.alreadyPassedText}`}>{item.name}</div>
            <div style={{position: 'relative'}} className={alreadyPassed && classes.alreadyPassed}>
                <div style={{width: '80%'}}>
                    {displayCombinedGroupNames()}
                </div>
                {item.auditory &&
                    <div className={`${classes.announcementAuditory} ${alreadyPassed && classes.alreadyPassedText}`}>
                        <MdOutlinePlace/>
                        <div>{formatAuditory(item.auditory)}</div>
                    </div>}
            </div>
        </div>
    );
}

export default withRippleAnimation(AnnouncementCard);