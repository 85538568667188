import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import {Excalidraw, exportToBlob} from "@excalidraw/excalidraw";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faEye, faEyeSlash} from '@fortawesome/free-solid-svg-icons'

import {InitialData} from "./initialData";
import classes from "./DrawingTools.module.css"
import {b64toBlob, getImageDimensions} from "../../../services/commonService";
import {MOBILE_WIDTH_THRESHOLD_VALUE} from "../../../services/constants";
import useWindowDimensions from "../../../hooks/windowDimensions";

const resolvablePromise = () => {
    let resolve;
    let reject;
    const promise = new Promise((_resolve, _reject) => {
        resolve = _resolve;

        reject = _reject;
    });
    promise.resolve = resolve;
    promise.reject = reject;
    return promise;
};

const DrawingTools = forwardRef((props, ref) => {
    const excalidrawRef = useRef(null);
    const [viewModeEnabled, setViewModeEnabled] = useState(false);
    const [canvasHeight, setCanvasHeight] = useState('400px')
    const { width } = useWindowDimensions();

    const initialStatePromiseRef = useRef({promise: null});
    if (!initialStatePromiseRef.current.promise) {
        initialStatePromiseRef.current.promise = resolvablePromise();
    }
    useEffect(() => {
        const fetchData = async () => {
            const imageData = b64toBlob(props.screenshot.slice(23), 'image/jpeg');
            const reader = new FileReader();
            reader.readAsDataURL(imageData);

            reader.onload = function () {
                const imagesArray = [
                    {
                        id: "screenshot",
                        dataURL: reader.result,
                        mimeType: "image/jpeg",
                        created: 1644915140367
                    }
                ];

                getImageDimensions(props.screenshot)
                  .then((response) => {
                      if (width <= MOBILE_WIDTH_THRESHOLD_VALUE) {
                          const dimensions = {...response}
                          InitialData.elements.forEach(el => {
                              el.width = dimensions.w / 2
                              el.height = dimensions.h / 2
                              setCanvasHeight(`${(dimensions.h / 2) + 70}px`)
                          })
                      }
                      initialStatePromiseRef.current.promise.resolve(InitialData);
                      excalidrawRef.current.addFiles(imagesArray);
                  })
            };
        };
        fetchData()
    }, [props.screenshot]);

    const additionalTools = () => {
        return (
          <div className={classes.buttonWrapper}>
              <button
                className={classes.resetScene}
                onClick={() => setViewModeEnabled(!viewModeEnabled)}
              >
                  <FontAwesomeIcon icon={viewModeEnabled ? faEyeSlash : faEye} size="sm"/>
              </button>
          </div>
        );
    };

    useImperativeHandle(ref, () => ({
        async getResultBlob() {
            return exportToBlob({
                elements: excalidrawRef.current.getSceneElements(),
                mimeType: "image/jpeg",
                appState: {
                    ...InitialData.appState,
                },
                files: excalidrawRef.current.getFiles(),
            })
        }
    }))

    return (
      <div className={classes.App}>
          <div className={classes.excalidrawWrapper} style={{height: window.screen.height < 1200 ? canvasHeight : ''}}>
              {additionalTools()}
              <Excalidraw
                ref={excalidrawRef}
                initialData={initialStatePromiseRef.current.promise}
                viewModeEnabled={viewModeEnabled}
                name="bugReportScreenshot"
                UIOptions={{
                    canvasActions: {
                        loadScene: false,
                        saveAsImage: false,
                        theme: false,
                        clearCanvas: false,
                        changeViewBackgroundColor: false,
                        export: false
                    }
                }}
              />
          </div>
      </div>
    );
})

export default DrawingTools
