import React from 'react'
import { Form, Input } from 'antd'
import { AiOutlineLock } from "react-icons/ai";

import classes from './ConfirmPassword.module.scss'
import { ConfirmPasswordItem } from '../Item/ConfirmPasswordItem';

export const PasswordRequirements = () => <Form.Item>
    <div className={classes.extra}>
        {`Пароль должен содержать не менее 8 символов, состоять из `}
        <b>латинских букв</b>
        {` верхнего и нижнего регистров и включать `}
        <b>цифры и спец. символы</b>.
    </div>
</Form.Item>

export const PasswordRequirementsList = () =>
    <div className={classes.requirementsList}>
        <div>Требования к паролю:</div>
        <ul>
            <li id="passwordQuantityRequirementId">не менее <b>8</b> символов</li>
            <li id="passwordRegistryRequirementId"><b>латинские</b> буквы верхнего и нижнего регистров</li>
            <li id="passwordNumberRequirementId">одна или более <b>цифр</b></li>
            <li id="passwordSpecialCharacterRequirementId">один или более <b>спец. символов</b></li>
        </ul>
    </div>

export const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@!()*,.:;/?[_\]\-{}#$%^&=])([A-Za-z\d@!()*,.:;/?[_\]\-{}#$%^&=]){8,}$/
export const passwordRegistryRequirement = /^(?=.*[A-Z])(?=.*[a-z])/
export const passwordNumberRequirement = /(?=.*\d)/
export const passwordSpecialCharacterRequirement = /(?=.*[@!()*,.:;/?[_\]\-{}#$%^&=])/
export const passwordQuantityRequirement = /([A-Za-z\d@!()*,.:;/?[_\]\-{}#$%^&=]){8,}$/

export const ConfirmPassword = ({
    newPassword, setNewPassword,
    confirmedPassword, setConfirmedPassword, isChangePassword
}) => {
    return <React.Fragment>

        <Form.Item
            name="password"
            label="Новый пароль"
            dependencies={['oldPassword']}
            rules={[
                { required: true, message: 'Данное поле является обязательным!' },
                {
                    pattern: passwordPattern,
                    message: 'Некорректный формат пароля.\nДопустимые спец.символы: ! ? @ () [] , . : ; / # $ % ^ &*-=_'
                },
                ({ getFieldValue }) => ({
                    validator(_, value) {
                        if (isChangePassword) {
                            const setMarkerColor = (item, isRulesMatch) => {
                                if (isRulesMatch) item.style.setProperty('--marker-color', '#48fa0d', 'important')
                                else item.style.setProperty('--marker-color', 'inherit')
                            }
                            setMarkerColor(document.getElementById("passwordRegistryRequirementId"), passwordRegistryRequirement.test(getFieldValue('password')))
                            setMarkerColor(document.getElementById("passwordNumberRequirementId"), passwordNumberRequirement.test(getFieldValue('password')))
                            setMarkerColor(document.getElementById("passwordSpecialCharacterRequirementId"), passwordSpecialCharacterRequirement.test(getFieldValue('password')))
                            setMarkerColor(document.getElementById("passwordQuantityRequirementId"), passwordQuantityRequirement.test(getFieldValue('password')))
                        }
                        if (!value || (getFieldValue('oldPassword') !== getFieldValue('password'))) {
                            return Promise.resolve();
                        }
                        return Promise.reject(new Error('Старый и новый пароли не должны совпадать!'));
                    },
                }),
            ]}>
            <Input.Password
                prefix={<AiOutlineLock />}
                placeholder="Новый пароль"
                value={newPassword}
                onChange={setNewPassword} />
        </Form.Item>

        <ConfirmPasswordItem
            dependencie="password"
            placeholder="Подтвердите пароль"
            value={confirmedPassword}
            onChange={setConfirmedPassword}
        />

    </React.Fragment>
}