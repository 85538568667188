import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";

import './index.scss';
import 'antd/dist/reset.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { store } from './store/storeConfig'
import { setupInterceptors } from './services/interceptors'
import { disableDevtools } from './services/commonService'
import ru_RU from "antd/es/locale/ru_RU";
import {ConfigProvider} from "antd";

if (process.env.NODE_ENV === 'production') {
  disableDevtools()
}

setupInterceptors(store)

if (process.env.NODE_ENV !== "development") console.log = () => { };

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
          <ConfigProvider
              locale={ru_RU}
              theme={{
                  token: {
                      fontFamily: "Lora"
                  }
              }}>
              <App/>
          </ConfigProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
