import classes from './MarkNoteInputModal.module.scss';
import {Modal, Select} from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, {useEffect, useState} from "react";
import NumberInput from "../../../../UI/NumberInput/NumberInput";
import PercentageInput from "../../../../UI/Input/PercentageInput";

const {Option} = Select;

const isNotLabSymbol = '';

const MarkNoteInputModal = (props) => {

    const [noteText, setNoteText] = useState(null);
    const [mark, setMark] = useState(null);

    const [selectedLabNumber, setSelectedLabNumber] = useState(null);
    const [markStatus, setMarkStatus] = useState(null);

    useEffect(() => {
        setMarkStatus(null);
        setNoteText(props.studentMark?.note);
        setMark(props.studentMark?.number);
        setSelectedLabNumber(props.studentMark?.labNumber ? props.studentMark?.labNumber : isNotLabSymbol);
    }, [props.studentMark, props.isOpen]);

    const updateMark = () => {
        if (props.updateMark && (selectedLabNumber !== props.studentMark.labNumber
            || Number(mark) !== props.studentMark.number
            || noteText !== props.studentMark.note)) {

            const updatedMark = {
                id: props.studentMark.id,
                number: mark,
                labNumber: Number(selectedLabNumber),
                note: noteText
            };
            props.updateMark(props.student, updatedMark);
        }
    }

    const validateMarkInput = () => {
        setMarkStatus((!mark && mark !== 0) ? "error" : null);
        return (mark || mark === 0);
    }

    const saveMark = () => {
        if (props.saveMark) {
            const markToSave = {
                mark: mark,
                labNumber: Number(selectedLabNumber),
                note: noteText
            };
            props.saveMark(props.student, markToSave);
        }
    }

    return <div>
        <Modal
            title={props.title}
            open={props.isOpen}
            onOk={() => {
                if (validateMarkInput()) {
                    if (props.studentMark) {
                        updateMark();
                    } else {
                        saveMark();
                    }
                    if (props.onOk) {
                        props.onOk();
                    }
                }
            }}
            onCancel={props.onCancel}
            centered
            wrapClassName={classes.container}
        >

            <div className={classes.modalContent}>
                <div className={classes.firstRowControls}>
                    <div className={classes.inputContainer}>
                        <span className={classes.inputLabel}>{props.isPercentage ? "Проценты" : "Оценка"}</span>
                        {
                            props.isPercentage?
                            <PercentageInput
                                className={classes.percentageInput}
                                value={mark}
                                onChange={(value) => {
                                    setMark(value);
                                }}
                                status={markStatus}
                            />
                            : <NumberInput
                                className={classes.markInput}
                                min={0}
                                max={10}
                                value={mark}
                                status={markStatus}
                                onChange={(e) => {
                                    setMark(e.target.value);
                                }}
                            />
                        }
                    </div>
                    {props.isLab && <div className={classes.inputContainer}>
                        <span className={classes.inputLabel}>№ ЛР</span>
                        <div className={classes.labNumberContainer}>
                            <Select className={classes.selectLabNumber}
                                    popupClassName={classes.selectLabNumberOption}
                                    value={selectedLabNumber}
                                    onChange={(value) => setSelectedLabNumber(value)}>
                                {
                                    props.allLabNumbers.map(item =>
                                        <Option value={item} label={item}
                                                disabled={!props.freeLabNumbers.includes(item) && item !== isNotLabSymbol}>
                                        </Option>)
                                }
                            </Select>
                        </div>
                    </div>}
                </div>

                <div className={classes.inputContainer}>
                    <span className={classes.inputLabel}>Примечание</span>
                    <TextArea className={classes.noteInput}
                              rows={3}
                              value={noteText}
                              onChange={(e) => {
                                  setNoteText(e.target.value);
                              }}/>
                </div>
            </div>
        </Modal>
    </div>
}

export default MarkNoteInputModal;