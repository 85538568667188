import {Button, Collapse, InputNumber, Modal, Segmented, Tooltip} from 'antd';
import {cloneDeep} from 'lodash';
import {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useLocation, useNavigate} from 'react-router';
import classes from './JournalMobile.module.scss';
import axios from '../../../services/axios';
import {setMessage} from '../../../store/actions/common';
import {MdOutlineKeyboardArrowLeft} from 'react-icons/md';
import {Picker} from 'antd-mobile';
import Loader from '../../UI/Loader/Loader';
import {NavLink} from "react-router-dom";
import {getAllLabNumbers, getLabMessage} from "../../../services/Gradebook/gradeBookService";
import {editLabCount, getBaseForGradebookApi, updateOmissions} from "../../../store/actions/gradeBook";
import OmissionsTable from "./OmissionsTable/OmissionsTable";
import MarksTable from "./MarksTable/MarksTable";
import JournalLegendMobile from './JournalLegendMobile';
import DateButton from '../../UI/DateButton/DateButton';

const {Panel} = Collapse;
const isNotLabSymbol = ''

const JournalMobile = () => {

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [selectOptions, setSelectOptions] = useState([]);
  const [currentLesson, setCurrentLesson] = useState(location.state.selectedLesson);
  const [currentTab, setCurrentTab] = useState((currentLesson.lessonTypeAbbrev === 'ЛК' || currentLesson.lessonTypeAbbrev === 'УЛк') ? 2 : 1);
  const [students, setStudents] = useState(null);
  const [numberOfMarkColumns, setNumberOfMarkColumns] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [displaySubgroupButton, setDisplaySubgroupButton] = useState(false);
  const [pickerVisible, setPickerVisible] = useState(false);
  const [pickerValue, setPickerValue] = useState([currentLesson.subgroup, currentLesson.id]);
  const [backButtonAnimation, setBackButtonAnimation] = useState(null);

  const [selectedStudentId, setSelectedStudentId] = useState(null);

  const [pageClass, setPageClass] = useState(currentTab === 2 ? classes.secondPageFirst : null);
  const [selectedLabCount, setSelectedLabCount] = useState(location.state.selectedLesson.group.labCount)
  const [valueLabCount, setValueLabCount] = useState(6)
  const [isDialogEditLabOpen, setIsDialogEditLabOpen] = useState(false)
  const [allLabNumbers, setAllLubNumbers] = useState([])

  const isPercentage = () => {
    return currentLesson?.isPercentage;
  }

  useEffect(() => {
    const lesson = location.state.selectedLesson;
    setIsLoading(true);
    if (isLabAndCountSet()) {
      let allLabNumbersTmp = getAllLabNumbers(lesson.group.labCount)
      allLabNumbersTmp.unshift(isNotLabSymbol)
      setAllLubNumbers(allLabNumbersTmp)
    }
    axios.get(`${getBaseForGradebookApi(isPercentage())}/lessons-for-subject`,
      {
        params: {
          termHoursId: lesson.termHoursId,
          studentGroupId: lesson.group.id
        }
      })
      .then((response) => {
        setSelectOptions(response.data);
      })
      .catch((_error) => {
        dispatch(setMessage({ type: 'warning', text: "Не удалось подключиться к серверу" }));
      })
  }, [location.state.selectedLesson])

  useEffect(() => {
    if (currentTab === 1) {
      setPageClass(classes.slideLeft);
    } else {
      setPageClass(classes.slideRight);
    }
  }, [currentTab])

  useEffect(() => {
    setIsLoading(true);
    axios.get(`${getBaseForGradebookApi(isPercentage())}`, { params: { lessonId: currentLesson.id } })
      .then((response) => {
        getStudentPhotos(response.data.students);
        setStudents(response.data.students);
        setDisplaySubgroupButton(true);
      })
      .catch(() => {
        dispatch(setMessage({ type: 'warning', text: "Не удалось загрузить журнал успеваемости" }));
      })
      .finally(() => {
        setIsLoading(false);
      })
  }, [currentLesson])

  useEffect(() => {
    const clickCallback = (_e) => {
      const selectedOption = selectOptions?.find(o => o.id === pickerValue[1]);
      setCurrentLesson(selectedOption);
    }
    const elements = Array.from(document.querySelectorAll('div.adm-mask'));
    if (elements && elements.length) {
      elements[0].addEventListener('click', clickCallback, { once: true });
    }

    return () => {
      if (elements && elements.length) {
        elements[0].removeEventListener('click', clickCallback, { once: true });
      }
    }
  }, [pickerValue])

  useEffect(() => {
    if (students) {
      let maxNumber = 0;
      if (!isPercentage()) {
        for (const student of students) {
          const marks = student.lessonMarks.find(les => les.idLesson === currentLesson.id)?.marks;
          if (marks && marks.length > maxNumber) {
            maxNumber = marks.length;
          }
        }
      }
      setNumberOfMarkColumns(maxNumber);
    }
  }, [students])

  const isLab = () => {
    return currentLesson.lessonTypeAbbrev === 'ЛР'
  } 
  const checkLab = () => {
    return currentLesson.lessonTypeAbbrev !== 'ЛР' || (isLab() && selectedLabCount)
  }

  const isLabAndCountSet = () => {
    return isLab() && selectedLabCount
  }

  const updateLabCount = (isCreate = false) => {
    let currentLessonTmp = cloneDeep(currentLesson)
    currentLessonTmp.group.labCount = valueLabCount
    setCurrentLesson(currentLessonTmp)
    navigate('./', {state: {selectedLesson: currentLessonTmp}})
    setSelectedLabCount(valueLabCount)
    setValueLabCount(6)
    if (valueLabCount) {
      const params = {
        termHoursId: currentLesson.termHoursId,
        studentGroupId: currentLesson.group.id,
        labCount: valueLabCount,
        isCreate: isCreate
      }
      dispatch(editLabCount(params, null))
    }
  }

  const setLessonType = (lesson) => {
    let itemClass = ''

    if (lesson.lessonTypeAbbrev === 'ЛК' || lesson.lessonTypeAbbrev === 'УЛк') itemClass = '#33db24'
    if (lesson.lessonTypeAbbrev === 'ПЗ' || lesson.lessonTypeAbbrev === 'УПз') itemClass = '#8e4fec'
    if (lesson.lessonTypeAbbrev === 'ЛР' || lesson.lessonTypeAbbrev === 'УЛР') itemClass = '#F98F43'
    if (lesson.lessonTypeAbbrev === 'Консультация') itemClass = '#4FE8FF'
    if (lesson.lessonTypeAbbrev === 'Экзамен' || lesson.lessonTypeAbbrev === 'Кандидатский экзамен') itemClass = '#F95B5E'
    if (lesson.lessonTypeAbbrev === 'Зачет' || lesson.lessonTypeAbbrev === 'Кандидатский зачет') itemClass = '#ffd900'
    if (lesson.lessonTypeAbbrev === 'КПР(Р)') itemClass = '#3039d4'

    return itemClass
  }

  const getWithOverlayTrigger = (name, tooltipName) => {
    return (
      <Tooltip placement={"top"}
        title={tooltipName}>
        <div>{name}</div>
      </Tooltip>
    )
  }

  const getStudentDataTooltip = (student) => {
    return <div className={classes.tooltipProfile}>
      {
        student.photo &&
        <img className={classes.photo} src={student.photo} />
      }
      <div style={{width: '150px'}}>{student.lastName + ' ' + student.firstName + ' ' + student.middleName}</div>
      <div>{student.email}</div>
    </div>
  }

  const getStudentPhotos = (items) => {
    const studentsCopy = items.map(s => cloneDeep(s));
    const getPhotoPromises = [];
    for (let student of items) {
      getPhotoPromises.push(axios.get(`/person/student-photo/${student.id}`)
        .then(response => {
          const studentToUpdate = studentsCopy.find(s => s.id === student.id);
          studentToUpdate.photo = `data:image/jpeg;base64,${response.data}`;
          return response;
        }));
    }
    Promise.allSettled(getPhotoPromises)
      .then(() => {
        setStudents(studentsCopy);
      })
}

  const getSaveMarkRequestBody = (student, mark) => {
    return isPercentage()
        ? {
          lessonId: currentLesson.id,
          studentIds: [student.id],
          ...mark,
        }
        : {
          lessonId: currentLesson.id,
          mark: mark.mark,
          note: mark.note,
          studentLabNumberDtoList: [{
            studentId: student.id,
            labNumber: mark.labNumber
          }],
        }
  }

  const saveMark = (student, mark) => {
    setIsLoading(true);
    axios.post(`${getBaseForGradebookApi(isPercentage())}/save-marks`, getSaveMarkRequestBody(student, mark))
      .then(response => {
        const updatedStudent = response.data[0];
        updateStudentMarks(updatedStudent.studentId, updatedStudent.marks);
      })
      .catch(() => {
        dispatch(setMessage({ type: 'warning', text: "Не удалось выставить оценку" }));
      })
      .finally(() => {
        setIsLoading(false);
      })
  }

  const deleteMark = (student, markId) => {
    setIsLoading(true);
    axios.delete(`${getBaseForGradebookApi(isPercentage())}/delete-mark`, { params: { markId }})
      .then(() => {
        const updatedMarks = student.lessonMarks.find(les => les.idLesson === currentLesson.id).marks.filter(m => m.id !== markId);
        updateStudentMarks(student.id, updatedMarks);
      })
      .catch(_error => {
        dispatch(setMessage({ type: 'warning', text: "Не удалось удалить оценку" }));
      })
      .finally(() => {
        setIsLoading(false);
      })
  }

  const updateMark = (student, mark) => {
    setIsLoading(true);
    axios.put(`${getBaseForGradebookApi(isPercentage())}/update-mark`, mark)
      .then(response => {
        const updatedMark = response.data;
        const copiedStudent = cloneDeep(student);
        const lessonMarks = copiedStudent.lessonMarks.find(les => les.idLesson === currentLesson.id).marks;
        const markToUpdate = lessonMarks.find(m => m.id === updatedMark.id);
        markToUpdate.number = updatedMark.number;
        markToUpdate.labNumber = updatedMark.labNumber;
        markToUpdate.note = updatedMark.note;
        updateStudentMarks(student.id, lessonMarks);
      })
      .catch(() => {
        dispatch(setMessage({ type: 'warning', text: "Не удалось обновить оценку" }));
      })
      .finally(() => {
        setIsLoading(false);
      })
  }

  const updateOmission = (studentId, requestBody) => {
    setIsLoading(true);

    dispatch(updateOmissions(requestBody,
        updateStudentOmission,
        () => setIsLoading(false)
    ));
  }

  const updateStudentMarks = (studentId, newMarks) => {
    setStudents(oldValue => {
      return oldValue.map(v => {
        const copiedStudent = cloneDeep(v);
        if (studentId === v.id) {
          const lesson = copiedStudent.lessonMarks.find(les => les.idLesson === currentLesson.id);
          if (newMarks.length !== 0 && lesson)
            lesson.marks = newMarks;
          else if (newMarks.length !== 0)
            copiedStudent.lessonMarks.push({
              idLesson: currentLesson.id,
              marks: newMarks
            });
          else
            copiedStudent.lessonMarks = copiedStudent.lessonMarks.filter(les => les.idLesson !== currentLesson.id)
        }
        return copiedStudent;
      });
    })
  }

  const updateStudentOmission = (updatedStudents) => {
    setStudents(oldValue => {
      return oldValue.map(v => {
        const copiedStudent = cloneDeep(v);
        const updatedStudent = updatedStudents.find(stud => stud.studentId === v.id);
        if (updatedStudent) {
          const copiedStudentOmission = copiedStudent.lessonOmissions.find(les => les.idLesson === currentLesson.id);
          if (updatedStudent.omission && copiedStudentOmission) copiedStudentOmission.omission = updatedStudent.omission;
          else if (updatedStudent.omission) copiedStudent.lessonOmissions.push({
            idLesson: currentLesson.id,
            omission: updatedStudent.omission
          });
          else copiedStudent.lessonOmissions = copiedStudent.lessonOmissions.filter(les => les.idLesson !== currentLesson.id);
        }
        return copiedStudent;
      });
    })
  }

  const getFullGroupName = (lesson) => {
    let groupName = lesson.group.name;
    if (lesson.subgroup !== 0) {
      groupName += "-" + lesson.subgroup;
    }
    return groupName;
  }

  const displayButton = () => {
    return displaySubgroupButton ?
      <div>
        <NavLink to={"/gradebook/students"} state={{ lesson: currentLesson }}>
          <div className={classes.assignSubgroups}>
            <Button style={{backgroundColor: '#1890ff', color: 'white'}} className={`adm-button adm-button-default`}>
              Разбить на подгруппы
            </Button>
          </div>
        </NavLink>
      </div>
    :
    null
  }

  const shouldDisplayTable = () => {
    return (students && students.length !== 0) ||
      ((currentLesson.lessonTypeAbbrev === 'ЛК' || currentLesson.lessonTypeAbbrev === 'УЛк') && (!students || students.length === 0));
  }

  const getSubGroup = (isCurrentSubgroup) => {
    let subgroupTmp
    if ((isCurrentSubgroup && currentLesson.subgroup === 1) || (!isCurrentSubgroup && currentLesson.subgroup === 2)) subgroupTmp = 1
    else if ((isCurrentSubgroup && currentLesson.subgroup === 2) || (!isCurrentSubgroup && currentLesson.subgroup === 1)) subgroupTmp = 2
    return subgroupTmp
  }

  const getTableStudents = (isCurrentSubgroup) => {
    let studentsTmp
    if (currentLesson.subgroup !== 0 && isCurrentSubgroup === true) studentsTmp = students.filter(stud => stud.subgroup === currentLesson.subgroup)
    else if (currentLesson.subgroup !== 0 && isCurrentSubgroup === false) studentsTmp = students.filter(stud => stud.subgroup !== currentLesson.subgroup)
    else studentsTmp = students
    return studentsTmp
  }

  const getTable = (isCurrentSubgroup) => {
    return <div className={classes.pages}>
      <div className={`${classes.page} ${pageClass}`}>
        <MarksTable
          dataSource={getTableStudents(isCurrentSubgroup)}
          currentLesson={currentLesson}
          selectedLabCount={selectedLabCount}
          setValueLabCount={setValueLabCount}
          setIsDialogEditLabOpen={setIsDialogEditLabOpen}
          getStudentDataTooltip={getStudentDataTooltip}
          getWithOverlayTrigger={getWithOverlayTrigger}
          students={students}
          saveMark={saveMark}
          updateMark={updateMark}
          deleteMark={deleteMark}
          allLabNumbers={allLabNumbers}
          numberOfMarkColumns={numberOfMarkColumns}
          isLoading={isLoading}
          selectedStudentId={selectedStudentId}
          setSelectedStudentId={setSelectedStudentId}
          isPercentage={isPercentage()}
          isLab={isLab()}
        />
      </div>
      {!isPercentage() && <div className={`${classes.page} ${pageClass}`}>
        <OmissionsTable
            dataSource={getTableStudents(isCurrentSubgroup)}
            subgroup={getSubGroup(isCurrentSubgroup)}
            currentLesson={currentLesson}
            getStudentDataTooltip={getStudentDataTooltip}
            getWithOverlayTrigger={getWithOverlayTrigger}
            updateOmission={updateOmission}
            updateOmissions={(params) => {
              setIsLoading(true)
              dispatch(updateOmissions(params, updateStudentOmission, () => setIsLoading(false)))
            }}
        />
      </div>}
    </div>
  }

  const getPanelHeader = (isCurrentSubgroup) => {
    return getSubGroup(isCurrentSubgroup) === 1 ? "1 подгруппа" : "2 подгруппа"
  }

  const getPanelColor = (isCurrentSubgroup) => {
    return getSubGroup(isCurrentSubgroup) === 1 ? classes.firstSubGroup : classes.secondSubGroup
  }

  const getTableContainer = () => {
    return currentLesson.subgroup === 0 ?
        <div className={classes.tableContainer} style={{marginLeft: "-10px", marginRight: "-10px"}}>
          {getTable(null)}
        </div>
        : <Collapse style={{marginTop: "5px"}} expandIconPosition="end" defaultActiveKey={1} className={`${classes.collapseContainer}`}>
          <Panel header={getPanelHeader(true)} key={1} className={`${classes.panelContainer} ${getPanelColor(true)}`}>
            <div className={classes.tableContainer}>
              {getTable(true)}
            </div>
          </Panel>
          <Panel header={getPanelHeader(false)} key={2} className={`${classes.panelContainer} ${getPanelColor(false)}`}>
            <div className={classes.tableContainer}>
              {getTable(false)}
            </div>
          </Panel>
        </Collapse>
  }

  const getLabCountDialog = () => {
    return <Modal className={classes.labCountDialog}
                  title={<div>
                    <span style={{fontSize: '18px', marginRight: '10px'}}>Всего лр:</span>
                    <InputNumber className={classes.labInput}
                                 defaultValue={selectedLabCount}
                                 min={1}
                                 max={20}
                                 onChange={(value) => setValueLabCount(value)}/>
                  </div>}
                  open={true}
                  onOk={() => {
                    updateLabCount()
                    setIsDialogEditLabOpen(false)
                  }}
                  okButtonProps={{disabled: !valueLabCount || (valueLabCount && valueLabCount === selectedLabCount)}}
                  onCancel={() => setIsDialogEditLabOpen(false)}
                  cancelText="Отменить"
                  okText="Сохранить"
                  centered>
    </Modal>
  }

  const getContent = () => {
    return shouldDisplayTable() && areSubgroupsValid() ? (
        <>
          <Segmented
              className={classes.tabs}
              value={currentTab}
              onChange={(value) => {
                setSelectedStudentId(null);
                setCurrentTab(value);
              }}
              options={[
                {
                  label: "Оценки",
                  value: 1,
                },
                {
                  label: "Пропуски",
                  value: 2,
                  disabled: isPercentage(),
                },
              ]}
          />
          {getTableContainer()}
        </>
    ) : (displayButton())
  }

  const isGroupSplit = () => {
    let subGroupStudents = getTableStudents()
    return (subGroupStudents !== null && subGroupStudents.length !== 0);
  }

  const areSubgroupsValid = () => {
    return (isLab() && (!students.some(student => student.subgroup === null) || selectedLabCount == null)) || !isLab();
  }

  return (
    <div className={classes.container}>
      <Loader loading={isLoading} />
      <div className={classes.controlsPanel}>
        <MdOutlineKeyboardArrowLeft
          size={35}
          className={`${classes.backButton} ${backButtonAnimation}`}
          onAnimationEnd={() => {
            navigate("/gradebook");
            setBackButtonAnimation(null);
          }}
          onClick={() => {
            setBackButtonAnimation(classes.wave);
          }}
        />

        <div>
          <span
            className={classes.subjectName}
            style={{ textDecorationColor: `${setLessonType(currentLesson)}` }}
          >
            {" "}
            {currentLesson.lessonName}
          </span>
          <span className={classes.studentGroup}>
            {getFullGroupName(currentLesson)}
          </span>
        </div>

        <DateButton
          date={currentLesson?.lessonDate}
          onAction={() => {
            setSelectedStudentId(null);
            setPickerVisible(true);
          }}
        />
      </div>

      {checkLab()
          ? getContent()
          : getLabMessage(currentLesson.lessonName, currentLesson.group.name, valueLabCount, setValueLabCount, updateLabCount)}

      {isDialogEditLabOpen && getLabCountDialog()}

      <Picker
        title={
          currentLesson.lessonName +
          " " +
          currentLesson.lessonTypeAbbrev +
          " " +
          getFullGroupName(currentLesson)
        }
        columns={[
          [...new Set(selectOptions.map((element) => element.subgroup))].sort().map(
            (s) => {
              return { label: s === 0 ? 'Вся группа' : s, value: s };
            }
          ),
          selectOptions
            .filter((o) => o.subgroup === pickerValue[0])
            ?.map((o) => {
              return { label: o.lessonDate, value: o.id };
            }),
        ]}
        visible={pickerVisible}
        onClose={() => {
          setPickerVisible(false);
        }}
        value={pickerValue}
        onSelect={(v) => {
          setPickerValue(v);
        }}
        onConfirm={(v) => {
          setPickerValue(v);
          const selectedOption = selectOptions?.find((o) => o.id === v[1]);
          setCurrentLesson(selectedOption);
        }}
        onCancel={() => {
          setPickerValue([currentLesson.subgroup, currentLesson.id]);
        }}
        cancelText="Отмена"
        confirmText="Выбрать"
      />
      {!isPercentage() && isGroupSplit() && areSubgroupsValid() && <JournalLegendMobile />}
    </div>
  );
}

export default JournalMobile;
