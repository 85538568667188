import * as actionTypes from './actionTypes';
import axios from '../../services/axios';
import {setMessage} from "./common";

export const loadFilteredGroups = () => {
    return {
        type: actionTypes.LOAD_FILTERED_GROUPS
    }
}

export const setFilteredGroups = (filteredGroups) => {
    return {
        type: actionTypes.SET_FILTERED_GROUPS,
        filteredGroups: filteredGroups
    }
}

export const getFilteredGroups  = (searchGroup) => {
    return (dispatch) => {
        dispatch(loadFilteredGroups())
        axios.get(`/student-group/filter?searchGroup=${searchGroup}`)
            .then(response => {
                dispatch(setFilteredGroups(response.data))
            })
            .catch(() => {
                dispatch(setMessage({type: 'warning', text: "Не удалось получить данные."}))
            })
    }
}