import SubgroupDialog from "../../SubgroupDialog/SubgroupDialog";
import { useDispatch, useSelector } from "react-redux";
import {
    downloadGradebookReportExcel,
} from "../../../../../store/actions/gradeBook";
import React, { useState } from "react";
import { Button, Dropdown, Space, Tooltip } from "antd";
import classes from './MenuButtons.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCalendarPlus,
    faFileExcel,
    faPeopleGroup,
    faPrint,
    faTable,
    faUserPlus
} from '@fortawesome/free-solid-svg-icons';
import {
    generateSemesters,
} from "../../../../../services/dateUtils";
import TeamDialog from "../../TeamDialog/TeamDialog";
import EditLessonDialog from "../../LessonDialogs/EditLessonDialog";
import MemoReportModal from "../../MemoReportDialog/MemoReportModal";
import useWindowDimensions from "../../../../../hooks/windowDimensions";


const MenuButtons = ({
    gradeBookStudents,
    gradeBookLessons,
    filterSubGroups,
    gradeBookFilterStudents,

    selectedSemesterIndex,
    selectedSubject,
    selectedTypeLesson,
    selectedGroupId,
    selectedTermHours,
    selectedSubgroup,
    selectedTeacher,

    isGradeSettingMode,
    selectedLabCount,
    loadGradeBookData,
    journalTableRef,
    areSubGroupsValid
}) => {
    const { width } = useWindowDimensions();
    const buttons = { SUBGROUP: 'Подгруппы', TEAM: 'Команды', LESSON: 'Добавить занятие' };

    const getSemesters = () => generateSemesters(3);

    const loadingGradeBook = useSelector(state => state.gradeBook.loadingGradeBookData);
    const gradeBookData = useSelector(state => state.gradeBook.gradeBookData);
    const assigningSubgroups = useSelector(state => state.gradeBook.assigningSubgroups);
    const assigningTeams = useSelector(state => state.gradeBook.assigningTeams);
    const user = useSelector(state => state.auth.user);
    const addingNewLesson = useSelector(state => state.gradeBook.addingNewLesson);
    const editingLesson = useSelector(state => state.gradeBook.editingLesson);
    const deletingLesson = useSelector(state => state.gradeBook.deletingLesson);
    const isSidebarDesktopCollapsed = useSelector(state => state.common.isSidebarDesktopCollapsed);

    const [isSubgroupDialogOpen, setIsSubgroupDialogOpen] = useState(false);
    const [isTeamDialogOpen, setIsTeamDialogOpen] = useState(false);
    const [isNewLessonDialogOpen, setIsNewLessonDialogOpen] = useState(false);
    const [showMemoModal, setShowMemoModal] = useState(false);

    const dispatch = useDispatch();

    const noActualGradebookData = !gradeBookData || loadingGradeBook;
    const isLab = selectedTypeLesson === 4;
    const isLabCount = isLab && selectedLabCount !== null

    const sidebarWidth = isSidebarDesktopCollapsed ? 0 : 200
    const labButtonWidth = isLabCount && !noActualGradebookData ? 170 : 0
    const mainButtonsWidth = width - sidebarWidth - labButtonWidth
    const smallIconButtons = mainButtonsWidth < 1540
    const isSmallIconMargin = mainButtonsWidth < 1050
    const iconMobileClass = classes.iconMobile + (isSmallIconMargin ? (" " + classes.iconMobileSmallMargin) : "")

    const getSelectedSemester = () => {
        return getSemesters()[selectedSemesterIndex];
    }

    const checkLab = () => {
        return !isLab || isLabCount
    }

    const downloadReport = () => {
        const semester = getSelectedSemester();

        dispatch(downloadGradebookReportExcel(
            semester.halfOfYear.value,
            semester.year,
            selectedGroupId,
            ((user?.authorities.includes("ROLE_ADMIN") || user?.authorities.includes("PERMISSION_JOURNAL_OF_CURRENT_PERFORMANCE_VIEW_ALL_TEACHERS"))
                && selectedTeacher !== null) ? selectedTeacher?.id : user.personId,
            selectedTermHours)
        )
    }

    const isContainSubGroup = () => {
        return filterSubGroups.find(subgr => subgr.key === 1 || subgr.key === 2)
    }

    const getMenuButton = (icon, buttonName, onClick, disabled, classButton) => {
        const handleButtonClick = () => {
            onClick()
            if (isGradeSettingMode && journalTableRef.current) journalTableRef.current.resetGradeSettingMode()
        }
        return (!smallIconButtons ? <Button
            className={`${classes.menuButton} ${classButton}`}
            onClick={handleButtonClick}
            disabled={disabled}>
            <Space className={classes.spaceItem}>
                <FontAwesomeIcon size={"lg"} className={classes.icon} icon={icon} />
                <span>{buttonName}</span>
            </Space>
        </Button> :
            <Tooltip title={buttonName}>
                <FontAwesomeIcon className={`${iconMobileClass} ${disabled && classes.disabledIcon}`}
                    icon={icon} onClick={handleButtonClick} />
            </Tooltip>);
    }


    const getReportsDropdown = () => {
        const getReportDropdownItem = (icon, name, onClick) => {
            return <div className={classes.reportsDropdownItem} onClick={onClick}>
                <div className={classes.reportsDropdownItemIcon}><FontAwesomeIcon size={"lg"} className={classes.icon} icon={icon} /></div>
                <div className={classes.reportsDropdownItemText}>{name}</div>
            </div>
        }

        const items = [
            {
                key: '1',
                label: getReportDropdownItem(faTable, 'Журнал успеваемости', () => downloadReport())
            },
            {
                key: '2',
                label: getReportDropdownItem(faPrint, 'Докладная записка', () => setShowMemoModal(true))
            }
        ];

        return <Dropdown
            className={classes.menuButton}
            trigger={['click']}
            menu={{ items }}
            disabled={noActualGradebookData || gradeBookFilterStudents.length === 0 || !checkLab() || !areSubGroupsValid()}>
            {
                !smallIconButtons ? <Button>
                    <Space className={classes.spaceItem}>
                        <FontAwesomeIcon size={"lg"} className={classes.icon} icon={faFileExcel} />
                        <span>Отчеты</span>
                    </Space>
                </Button> :
                    <Tooltip title="Отчеты">
                        <FontAwesomeIcon className={`${iconMobileClass} ${(noActualGradebookData
                            || gradeBookFilterStudents.length === 0) && classes.disabledIcon}`} icon={faFileExcel} />
                    </Tooltip>
            }
        </Dropdown>

    }

    const getStudentStatusLabel = (student) => {
        let studentStatusLabel;
        if (student.expelledInCurrentSemester) {
            studentStatusLabel = <span className={classes.studentStatusLabel}>{" (отчислен)"}</span>;
        } else if (student.inOffsettingValue !== 0) {
            studentStatusLabel = <span className={classes.studentStatusLabel}>{" (перезачтено)"}</span>;
        }
        return studentStatusLabel;
    }

    const isCourseProject = () => {
        return selectedTypeLesson === 5;
    }

    const isPercentage = () => {
        return isCourseProject();
    }

    const getSubgroupButtonClass = () => {
        if ((!noActualGradebookData && selectedSubgroup && gradeBookFilterStudents.length === 0) || !areSubGroupsValid()) {
            return classes.pulsateButton;
        }
        return null;
    }

    return (
        <div className={classes.buttonsContainer}>
            <div className={classes.innerButtonsContainer}>
                {getMenuButton(faPeopleGroup, buttons.SUBGROUP, () => setIsSubgroupDialogOpen(true), isPercentage() || noActualGradebookData || assigningSubgroups || selectedSubgroup === null || !checkLab(), getSubgroupButtonClass())}
                {
                    <SubgroupDialog
                        lessons={gradeBookLessons}
                        students={gradeBookStudents}
                        termHoursId={selectedTermHours}
                        onShow={isSubgroupDialogOpen}
                        getOffsetLabel={getStudentStatusLabel}
                        onClose={() => setIsSubgroupDialogOpen(!isSubgroupDialogOpen)}
                    />
                }

                {getMenuButton(faUserPlus, buttons.TEAM, () => setIsTeamDialogOpen(true), isPercentage() || noActualGradebookData || assigningTeams || gradeBookFilterStudents.length === 0 || !checkLab() || !areSubGroupsValid())}
                {
                    <TeamDialog
                        students={gradeBookStudents}
                        termHoursId={selectedTermHours}
                        showModal={isTeamDialogOpen}
                        getOffsetLabel={getStudentStatusLabel}
                        onCancelHandler={() => setIsTeamDialogOpen(!isTeamDialogOpen)}
                    />
                }
            </div>

            <div className={classes.innerButtonsContainer}>
                {getMenuButton(faCalendarPlus, buttons.LESSON, () => setIsNewLessonDialogOpen(true), noActualGradebookData || addingNewLesson || editingLesson || deletingLesson || !gradeBookFilterStudents.length || !checkLab() || !areSubGroupsValid())}
                {
                    isNewLessonDialogOpen && <EditLessonDialog
                        isAdd={true}
                        onClose={() => setIsNewLessonDialogOpen(false)}
                        termHoursId={selectedTermHours}
                        studentGroupId={selectedGroupId}
                        isContainSubGroup={isContainSubGroup()}
                        selectedSubgroup={selectedSubgroup}
                        filterSubGroups={filterSubGroups}
                        onSuccess={() => {
                            loadGradeBookData();
                            setIsNewLessonDialogOpen(false);
                        }}
                        isPercentage={isPercentage()}
                    />
                }

                {getReportsDropdown()}
                {
                    <MemoReportModal
                        showModal={showMemoModal}
                        isCourseProject={isCourseProject()}
                        onCancelHandler={() => setShowMemoModal(!showMemoModal)}
                        groupId={selectedGroupId}
                        employeeId={(user?.authorities.includes("ROLE_ADMIN") && selectedTeacher !== null) ? selectedTeacher?.id : user.personId}
                        subject={selectedSubject}
                        termHoursId={selectedTermHours}
                    />
                }
            </div>
        </div>
    )
}

export default MenuButtons;