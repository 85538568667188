import React, { useState, useEffect } from "react";

import classes from './CountdownTimer.module.scss'
import { setWordEnding } from "../../../services/commonService";

const useCountdown = (targetDate) => {
    const countDownDate = new Date(targetDate).getTime();

    const [countDown, setCountDown] = useState(
        countDownDate - new Date().getTime()
    );

    useEffect(() => {
        const interval = setInterval(() => {
            setCountDown(countDownDate - new Date().getTime());
        }, 1000);

        return () => clearInterval(interval);
    }, [countDownDate]);

    return getReturnValues(countDown);
};

const getReturnValues = (countDown) => {
    const hours = Math.floor(
        (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

    return [hours, minutes, seconds];
};

const CountdownTimer = ({ targetDate, onExpireHandler, withLabels, displayHours = true, extraText }) => {
    const [hours, minutes, seconds] = useCountdown(targetDate);

    const formatTimeValue = (value) => {
        if (value < 10) return `0${value}`
        else return `${value}`
    }

    useEffect(() => {
        if (hours + minutes + seconds <= 0) {
            onExpireHandler()
        }
    }, [hours, minutes, seconds, onExpireHandler])

    return (<React.Fragment>
        {!withLabels && <div>
            {`${extraText ?? ''} `}
            {`${displayHours ? formatTimeValue(hours) + ':' : ''}${formatTimeValue(minutes)}:${formatTimeValue(seconds)}`}
        </div>}

        {withLabels &&
            <div className={classes.timerWithLabelsContainer}>
                {displayHours && <div className={classes.timerValue}>
                    <div>{formatTimeValue(hours)}</div>
                    <div>{setWordEnding(hours, ['час', 'часа', 'часов'])}</div>
                </div>}
                <div className={classes.separator}>:</div>
                <div className={classes.timerValue}>
                    <div>{formatTimeValue(minutes)}</div>
                    <div>{setWordEnding(minutes, ['минута', 'минуты', 'минут'])}</div>
                </div>
                <div className={classes.separator}>:</div>
                <div className={classes.timerValue}>
                    <div>{formatTimeValue(seconds)}</div>
                    <div>{setWordEnding(seconds, ['секунда', 'секунды', 'секунд'])}</div>
                </div>
            </div>}
    </React.Fragment>
    );
};

export default CountdownTimer