import { Button } from 'antd';

import classes from './SubmitButton.module.scss'

const SubmitButton = ({ key, block, type, text, submitButtonStyles, onClickHandler, disabled, loading, icon }) => {
    return <Button
        key={key}
        icon={icon}
        block={block}
        htmlType={type}
        disabled={disabled}
        loading={loading}
        onClick={onClickHandler}
        style={{ ...submitButtonStyles }}
        className={classes.submitButton}>
        {text}
    </Button>
}

export default SubmitButton;