import * as actionTypes from './actionTypes';


export const setMessage = (payload) => {
    return {
        type: actionTypes.SET_MESSAGE,
        message: payload
    }
}

export const setSidebarDesktopCollapsed = (payload) => {
    return {
        type: actionTypes.SET_SIDEBAR_DESKTOP_COLLAPSED,
        isSidebarDesktopCollapsed: payload
    }
}

export const setServerError = (value) => {
    return {
        type: actionTypes.SET_SERVER_UNAVAILABLE,
        ifServerUnavailable: value
    }
}