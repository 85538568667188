import React, {useState} from 'react';
import {Table} from "antd";
import {MdStarRate} from "react-icons/md";
import classes from './OmissionsTable.module.scss';
import {formatFioWithInitials} from "../../../../services/util";
import OmissionHoursMobileInput from "../../../UI/Input/OmissionHoursMobileInput";
import OmissionNoteInputModal from "./OmissionNoteInputModal/OmissionNoteInputModal";
import OmissionHoursCommonCheckbox from "./OmissionHoursCommonCheckbox/OmissionHoursCommonCheckbox";

const OmissionsTable = (props) => {

    const [isAddNoteModalOpen, setIsAddNoteModalOpen] = useState(false);
    const [selectedOmissionParams, setSelectedOmissionParams] = useState(null);

    const columns = [
        {
            title: 'ФИО',
            dataIndex: 'fio',
            align: 'center',
            onCell: (student) => ({
                style: {
                    padding: (student.inOffsettingValue !== 0 || student.expelledInCurrentSemester) && '5px 16px'
                }
            }),
            render: (_text, student, index) => {
                const fioCell = <div tabIndex={-1} style={{textAlign: 'left', maxWidth:'154px'}}>
                    <span style={{ marginRight: '10px', userSelect: 'none' }}>{index + 1}</span>
                    <span style={{ userSelect: 'none' }}>{formatFioWithInitials(student)}</span>
                    {student?.headman && <MdStarRate size={20} style={{color: '#1677ff', verticalAlign:'top', marginLeft:'1px'}}/> }
                </div>
                return props.getWithOverlayTrigger(fioCell, props.getStudentDataTooltip(student))
            },
            width: '50%'
        },
        {
            title: <div style={{display: 'flex', justifyContent: 'start'}}>
                <div style={{width: '154px', marginLeft:'4px'}}>
                    <OmissionHoursCommonCheckbox
                        classNameCheckBox={classes.checkBox}
                        currentLesson={props.currentLesson}
                        students={props.dataSource}
                        subgroup={props.subgroup}
                        updateOmissions={props.updateOmissions}
                    />
                    Пропуски
                </div>
            </div>,
            dataIndex: 'omissions',
            render: (_, student) => {
                const omission = student.lessonOmissions.find(les => les.idLesson === props.currentLesson.id)?.omission;
                return {
                    props: {
                        style: {
                            paddingTop: '0',
                            paddingBottom: '0',
                            whiteSpace: 'nowrap'
                        }
                    },
                    children: getCell(student, props.currentLesson, omission)
                }
            },
        }
    ];

    const getCell = (student, lesson, omission) => {
        let resultCell;
        if (student.expelledInCurrentSemester) {
            resultCell = <div className={classes.inOffsettingCell}>Отчислен</div>
        } else if (student.inOffsettingValue !== 0) {
            resultCell = <div className={classes.inOffsettingCell}>Перезачтено</div>
        } else {
            resultCell = <OmissionHoursMobileInput
                classNameCheckBox={classes.checkBox}
                classNameSelect={classes.omissionHours}
                classNameRespectSelect={classes.respectOmissionHours}
                classNameAnimButton={classes.animatedButton}
                classNameNoteBtn={classes.noteButton}
                classNameRespectOmission={classes.respectfulIcon}
                onNoteButtonClicked={() => {
                    setIsAddNoteModalOpen(true);
                    const omissionParams = {
                        studentId: student.id,
                        omission: omission,
                        lessonId: lesson.id
                    };
                    setSelectedOmissionParams(omissionParams);
                }
                }
                lesson={lesson}
                omissionHours={omission?.number}
                omission={omission}
                student={student}
                updateOmission={props.updateOmission}
            />
        }
        return resultCell;
    }

    return (<React.Fragment>
        <Table
            className={classes.table}
            rowClassName={row => (row.inOffsettingValue !== 0 || row.expelledInCurrentSemester) && classes.disabledRow}
            dataSource={props.dataSource}
            columns={columns}
            pagination={false}
        />

        <OmissionNoteInputModal
            isOpen={isAddNoteModalOpen}
            onOk={() => setIsAddNoteModalOpen(false)}
            onCancel={() => setIsAddNoteModalOpen(false)}
            omissionParams={selectedOmissionParams}
            updateOmission={props.updateOmission}>
        </OmissionNoteInputModal>
    </React.Fragment>);
}

export default OmissionsTable;