import { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { AiOutlineUser } from "react-icons/ai";
import { Avatar } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { dateToString } from "../../../services/util";
import {setGradeBookLessonsDate} from "../../../store/actions/gradeBook";

import Logo from '../../../assets/images/logo.svg'
import classes from './Sidebar.module.scss'
import { createSidebarNavigationItems } from '../Menus';
import { logout } from '../../../store/actions/authentication';

import Backdrop from '../../UI/Backdrop/Backdrop';

const SidebarMobile = (props) => {
    const navigate = useNavigate();

    const [sidebarState, setSidebarState] = useState(false);

    const dispatch = useDispatch();
    const user = useSelector(state => state.auth.user);

    useEffect(() => {
        setSidebarState(props.mobileSidebarState);
    }, [props.mobileSidebarState])

    useEffect(() => {
        if (sidebarState) {
            document.body.style.width = '100%'
            document.body.style.overflow = 'hidden'
        } else {
            document.body.style.width = ""
            document.body.style.overflow = ""
        }
    }, [sidebarState])

    const closeSidebar = () => {
        setSidebarState(false);
        if (props.mobileSidebarState) props.mobileToggleClicked();
    }

    const handleLogout = () => {
        dispatch(logout()).then(() => {
            setSidebarState(false);
            dispatch(setGradeBookLessonsDate(dateToString(new Date())));
            props.mobileToggleClicked();
            navigate("/", { replace: true });
        });
    };

    return <div>
        <Backdrop show={sidebarState} clicked={() => closeSidebar()} />

        <div className={sidebarState ? classes['container-mobile--opened'] : classes['container-mobile--closed']}>
            {
                (user) ?
                    <div className={classes.mobileProfileDataContainer}>
                        <NavLink to="/personal-page" onClick={() => closeSidebar()}>
                            <Avatar
                                src={user.photoUrl}
                                size={75}
                                icon={<AiOutlineUser />} alt="profileIcon" />
                            <div className={classes.userData}>
                                <div>{user.fio}</div>
                                <div>{`[${user.username}]`}</div>
                            </div>
                        </NavLink>
                    </div> :
                    <div className={classes.logoContainer}>
                        <NavLink to="/settings" onClick={() => closeSidebar()}>
                            <img src={Logo} alt="Логотип" width="50px" height="50px" className={classes.logo} />
                            <span className={classes.navigationItemText}>Кабинет сотрудника БГУИР</span>
                        </NavLink>
                    </div>
            }

            <div className={classes.navigationItemsMobile}>
                {
                    createSidebarNavigationItems(user).map((navItem) =>
                        <NavLink
                            to={navItem.path}
                            className={({ isActive }) => isActive ? classes.selectedNavigationItem : null}
                            key={navItem.path}
                            onClick={() => closeSidebar()}>
                            <div className={classes.navigationItemIcon}>{navItem.icon}</div>
                            <div className={classes.navigationItemText}>{navItem.text}</div>
                        </NavLink>
                    )
                }
            </div>

            {user && <div className={classes.toggleContainer}>
                <button onClick={() => handleLogout()}>
                    Выйти из аккаунта
                </button>
            </div>}
        </div>
    </div>
}

export default SidebarMobile;