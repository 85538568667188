export const formatFioWithInitials = (person) => {
    let formatted = "";
    if (person.lastName) {
        formatted += person.lastName;
    }
    if (person.firstName) {
        if (formatted) {
            formatted += " ";
        }
        formatted += person.firstName.substring(0, 1) + ".";
    }
    if (person.middleName) {
        if (formatted) {
            formatted += " ";
        }
        formatted += person.middleName.substring(0, 1) + ".";
    }
    return formatted;
}

export const formatFullFio = (person) => {
    let formatted = "";
    if (person.lastName) {
        formatted += person.lastName;
    }
    if (person.firstName) {
        if (formatted) {
            formatted += " ";
        }
        formatted += person.firstName;
    }
    if (person.middleName) {
        if (formatted) {
            formatted += " ";
        }
        formatted += person.middleName;
    }
    return formatted;
}

export const dateToString = (date) => {
    let dd = String(date.getDate()).padStart(2, '0');
    let mm = String(date.getMonth() + 1).padStart(2, '0');
    let yyyy = date.getFullYear();
    date = dd + '.' + mm + '.' + yyyy;
    return date
}

export const stringToDate = (stringDate) => {
    let pattern = /(\d{2})\.(\d{2})\.(\d{4})/;
    let date = new Date(stringDate.replace(pattern, '$3-$2-$1'));
    return date;
}

export const getStatusOfDate = (stringDate) => {
    let currentDate = new Date();
    let dateTomorrow = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1);
    if (stringDate === dateToString(dateTomorrow)) {
        return "Завтра";
    }
    if (stringDate === dateToString(currentDate)) {
        return "Сегодня";
    }
    return "";
}

export const compareDates = (date1, date2) => {
    return dateToString(date1) === dateToString(date2);
}

export const compareMonthes = (date1, date2) => {
    return date1.getMonth() === date2.getMonth() && date1.getFullYear() === date2.getFullYear();
}

export const currentLesson = (item) => {
    if(!item.startLessonTime) return null;
    return item.lessonDate === dateToString(new Date()) && checkIfCurrentTime(item.startLessonTime, item.endLessonTime)
}

export const checkIfCurrentTime = (start, end) => {
    const startDate = new Date()
    const endDate = new Date()
    const currentDate = new Date()

    startDate.setHours(start.substring(0, 2))
    startDate.setMinutes(start.substring(3, 5))
    endDate.setHours(end.substring(0, 2))
    endDate.setMinutes(end.substring(3, 5))

    return (currentDate.getTime() >= startDate.getTime() && currentDate.getTime() <= endDate.getTime())
}

export const combineLessons = (lessons) => {
    const combinedLessonsList = [];
    for (let lesson of lessons) {
        const alreadyCoveredLesson = combinedLessonsList.find(a => isTheSameLessonForAnotherGroup(a[0], lesson));
        if (!alreadyCoveredLesson) {
            combinedLessonsList.push(combineLessonsForOne(lessons, lesson));
        }
    }
    return combinedLessonsList;
}

const combineLessonsForOne = (lessons, baseLesson) => {
    const combinedLessons = [baseLesson];
    for (let lesson of lessons) {
        if (lesson.id !== baseLesson.id && isTheSameLessonForAnotherGroup(lesson, baseLesson)) {
            combinedLessons.push(lesson);
        }
    }
    return combinedLessons.sort((a, b) => a.group.name - b.group.name);
}

const isTheSameLessonForAnotherGroup = (l1, l2) => {
    return l1.termHoursId === l2.termHoursId
        && l1.startLessonTime === l2.startLessonTime
        && l1.endLessonTime === l2.endLessonTime
        && l1.lessonDate === l2.lessonDate;
}

/**
 * accepts array of groups and returns combined groups as strings
 *
 * for example, for input array: [{id: 1, name: '150501'}, {id: 2, name: '150502'}, {id: 3, name: '250501'}]
 * output will be: ['150501-2', '250501']
 * @param {*} groups array of groups of the form [{id: 2, name: '150502'}, ...]. This array needs to be sorted by group name
 * @returns array of combined group names as strings
 */
export const getCombinedGroupNames = (groups) => {
    const groupNames = [];
    for (let group of groups) {
        const coveredGroup = groupNames.find(g => g.slice(0, 5) === group.name.slice(0, 5)
            && g[g.length - 2] === '-'
            && (+g[g.length - 1] >= +group.name[group.name.length - 1]));
        if (!coveredGroup) {
            groupNames.push(combineGroups(groups, group));
        }
    }
    return groupNames;
}

const combineGroups = (groups, baseGroup) => {
    let resultGroup = baseGroup.name;
    for (let group of groups) {
        if (baseGroup.id !== group.id && baseGroup.name.slice(0, 5) === group.name.slice(0, 5)) {
            const resultGroupLength = resultGroup.length;
            if ((+group.name[group.name.length - 1] - +resultGroup[resultGroupLength - 1]) === 1) {
                if (resultGroup[resultGroup.length - 2] !== '-') {
                    resultGroup += '-';
                    resultGroup += group.name[group.name.length - 1];
                } else {
                    resultGroup = resultGroup.substring(0, resultGroupLength - 1) + group.name[group.name.length - 1];
                }
            }
        }
    }
    if (!resultGroup.includes('-') && baseGroup.subgroup && baseGroup.subgroup !== 0) {
        resultGroup += '(' + baseGroup.subgroup + ')'
    }
    return resultGroup;
}

export const getFormattedLessonPeriod = (item) => {
    return item.startLessonTime + " - " + item.endLessonTime;
}