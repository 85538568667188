import React from "react";
import { Button, InputNumber, Tooltip } from "antd";
import classes from './LabCount.module.scss';
import { TbEdit } from "react-icons/tb";
import { MdOutlineSaveAlt } from "react-icons/md";

const LabCount = ({
    selectedLabCount,
    labEditButtonState,
    valueLabCount,
    setValueLabCount,
    setLabEditButtonState,
    updateLabCount,
}) => {
    return <InputNumber
        className={classes.labCount}
        defaultValue={selectedLabCount}
        min={1}
        max={20}
        prefix="Всего лр:"
        disabled={labEditButtonState}
        onChange={setValueLabCount}
        addonAfter={<Button className={classes.labCountButton}
            type="link"
            icon={<Tooltip
                title={labEditButtonState ? 'Редактировать' : 'Сохранить'}>
                {labEditButtonState
                    ? <TbEdit size={17} />
                    : <MdOutlineSaveAlt size={17} />}
            </Tooltip>}
            onClick={() => {
                if (labEditButtonState || valueLabCount) {
                    setValueLabCount(selectedLabCount)
                    setLabEditButtonState(!labEditButtonState)
                }
                if (!labEditButtonState && valueLabCount && valueLabCount !== selectedLabCount) updateLabCount()
            }}>
        </Button>}
    />
}

export default LabCount