import Modal from "antd/es/modal/Modal";
import DateInput from "../../../UI/Input/DateInput";
import {getFullFormattedDateStringFromTimestamp} from "../../../../services/dateUtils";
import React, {useEffect, useState} from "react";
import classes from "./EditLessonDialog.module.css";
import {Button, Input, Select} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {addNewLesson, deleteLesson, editLesson, getLessonPeriods} from "../../../../store/actions/gradeBook";
import {
    faTrash
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {getFormattedLessonPeriod} from "../../../../services/util";

const MAX_NOTE_LENGTH = 255;

const parseDate = (dateString) => {
    const [day, month, year] = dateString.split('.').map(Number);
    return new Date(year, month - 1, day);
  };

const EditLessonDialog = (props) => {
    const dispatch = useDispatch();

    const lessonDate = props.isAdd ? null : getFullFormattedDateStringFromTimestamp(props.lesson.date);

    const lessonPeriod = props.isAdd ? null : getLessonPeriodOption(props.lesson.lessonPeriod);

    const addingNewLesson = useSelector(state => state.gradeBook.addingNewLesson);
    const editingLesson = useSelector(state => state.gradeBook.editingLesson);
    const deletingLesson = useSelector(state => state.gradeBook.deletingLesson);
    const lessonPeriods = useSelector(state => state.gradeBook.lessonPeriods);

    const [newLessonDate, setNewLessonDate] = useState(lessonDate);
    const [newSubgroup, setNewSubgroup] = useState(props.filterSubGroups.find(subgr => subgr.key === props.lesson?.subgroup || subgr.key === props.selectedSubgroup).key);
    const [newNote, setNewNote] = useState(props.lesson?.note || '');
    const [hasMarksOrOmissions, setHasMarksOrOmissions] = useState();
    const [newLessonPeriod, setNewLessonPeriod] = useState(lessonPeriod == null ? null : lessonPeriod.value);
    const loadLessonPeriods = () => {
        dispatch(getLessonPeriods())
    }

    useEffect(() => {
        loadLessonPeriods()
    }, [])

    const getTitle = () => {
        return props.isAdd ? 'Добавить занятие' : 'Изменить занятие';
    }

    const onSaveNewLesson = () => {
        const lessonParams = {
            studentGroupId: props.studentGroupId,
            termHoursId: props.termHoursId,
            subgroup: newSubgroup,
            lessonNote: newNote || null,
            date: newLessonDate,
            lessonPeriodId: newLessonPeriod
        };
        dispatch(addNewLesson(lessonParams, props.onSuccess, props.isPercentage));
    }

    const onEditLesson = () => {
        const lessonParams = {
            lessonId: props.lesson.id,
            subgroup: newSubgroup,
            lessonNote: newNote || null,
            date: newLessonDate,
            lessonPeriodId: newLessonPeriod
        };
        dispatch(editLesson(lessonParams, props.onSuccess, props.isPercentage));
    }

    const handleOk = () => {
        if (props.isAdd) {
            onSaveNewLesson();
        } else {
            console.log(props.students)
            const hasOmissions = props.students?.some(student => 
                student.lessonOmissions.some(omission => omission.idLesson === props.lesson.id)
            );
            const isTransferOmissions = getFullFormattedDateStringFromTimestamp(props.lesson.date) === newLessonDate
                || parseDate(newLessonDate) < new Date();
            if (!isTransferOmissions && hasOmissions) {
                Modal.confirm({
                    title: 'Изменение даты предмета',
                    content: 'При переносе даты пропуски студентов не будут перенесены',
                    okText: 'Сохранить',
                    cancelText: 'Отмена',
                    onOk: onEditLesson
                });
            } else {
                onEditLesson();
            }
        }
    };

    const onDeleteLesson = () => {
        if (!hasMarksOrOmissions && hasMarksOrOmissions !== false && props.withMarksOrOmissions) {
            const warningIsRequired = props.withMarksOrOmissions();
            setHasMarksOrOmissions(warningIsRequired);
            if (warningIsRequired) return;
        }

        dispatch(deleteLesson(props.lesson.id, props.onSuccess, props.isPercentage));
    }

    const saveButtonIsToBeDisabled = () => {
        return !newLessonDate || !newLessonPeriod || noSubgroup() || (props.isAdd ? addingNewLesson : editingLesson);
    }

    const noSubgroup = () => {
        return !newSubgroup && newSubgroup !== 0;
    }

    function getLessonPeriodOption(item) {
        return item == null ? null : {value: item.id, label: getFormattedLessonPeriod(item)};
    }

    return <Modal
        title={getTitle()}
        open={true}
        okButtonProps={{disabled: saveButtonIsToBeDisabled()}}
        onOk={handleOk}
        onCancel={props.onClose}
        cancelText="Отменить"
        okText="Сохранить"
        width={400}
        centered={true}
    >
        <div className={classes.inputWithBottomMargin}>
            <div className={classes.inputHeader}>Дата занятия{!newLessonDate && <span className={classes.warningText}> *</span>}</div>
            <DateInput
                defaultValue={lessonDate}
                onChange={setNewLessonDate}
                style={{width: '100%'}}
            />
        </div>
        {props.isContainSubGroup && <div className={classes.inputWithBottomMargin}>
            <div className={classes.inputHeader}>Подгруппа{noSubgroup() &&
                <span className={classes.warningText}> *</span>}</div>
            <Select
                placeholder="Подгруппа"
                options={props.filterSubGroups.map(item => ({value: item.key, label: item.name}))}
                onChange={setNewSubgroup}
                value={newSubgroup}
                style={{width: '100%'}}
            />
        </div>}
        <div className={classes.inputWithBottomMargin}>
            <div className={classes.inputHeader}>Время занятия{!newLessonPeriod &&
                <span className={classes.warningText}> *</span>}</div>
            <Select
                placeholder="Выберите время"
                options={lessonPeriods.map(item => getLessonPeriodOption(item))}
                onChange={setNewLessonPeriod}
                defaultValue={lessonPeriod}
                style={{width: '100%'}}
            />
        </div>

        <div>
            <div className={classes.inputHeader}>Примечания</div>
            <Input value={newNote} onChange={(event) => setNewNote(event.target.value.substring(0, MAX_NOTE_LENGTH))}/>
            <div className={classes.textInputControls}>
                <button className={classes.clearInputButton}
                        onClick={() => setNewNote('')}
                >
                    Очистить
                </button>
                <span className={classes.textInputMessageLengthInfo}>{newNote.length}/{MAX_NOTE_LENGTH}</span>
            </div>
        </div>
        {hasMarksOrOmissions &&
            <div className={classes.deletionMessage}>Будут удалены выставленные оценки и пропуски. Продолжить
                удаление?</div>}
        {!props.isAdd && <Button danger
                                 disabled={deletingLesson}
                                 onClick={onDeleteLesson}
                                 className={classes.deleteButton}>
            <FontAwesomeIcon icon={faTrash}/>&nbsp;Удалить
        </Button>}
    </Modal>
}

export default EditLessonDialog