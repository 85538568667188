import locale from "antd/es/date-picker/locale/ru_RU";
import {DatePicker} from "antd";
import {useEffect, useState} from "react";
import dayjs from 'dayjs';
import {getFullFormattedDateStringFromTimestamp} from "../../../services/dateUtils";

const DateInput = (props) => {
    const [value, setValue] = useState();
    const dateFormat = 'DD.MM.YYYY';

    useEffect(() => {
        setValue(props.value)
    }, [props.value])

    return <DatePicker
        inputReadOnly={props.inputReadOnly}
        placeholder={props.placeholder}
        status={props.status}
        locale={locale}
        format={props.format ? props.format : dateFormat}
        allowClear={false}
        value={value}
        style={props.style}
        className={props.className}
        defaultValue={(props.defaultValue && dayjs(props.defaultValue, dateFormat)) || null}
        disabled={props.disabled}
        onChange={(newValue) => {
            setValue(newValue);
            props.onChange(getFullFormattedDateStringFromTimestamp(newValue));
        }}
        disabledDate={(date) => {
            if (props.disabledDate) return props.disabledDate(date)
        }}
    />
}

export default DateInput;