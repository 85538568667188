import axios from './axios'
import * as actionTypes from '../store/actions/actionTypes'
import {SESSION_LIFETIME} from './constants';

export const setupInterceptors = (store) => {

    const extendSession = () => {
        if (localStorage.getItem('_expiredTime')) {
            localStorage.setItem('_expiredTime', Date.now() + 1000 * SESSION_LIFETIME);
        }
    }

    axios.interceptors.response.use(
        function (response) {
            extendSession();
            return response;
        }, function (error) {
            if (!error.response || !navigator || !navigator.onLine) {
                store.dispatch({ type: actionTypes.SET_SERVER_UNAVAILABLE, ifServerUnavailable: true });
            } else {
                store.dispatch({ type: actionTypes.SET_SERVER_UNAVAILABLE, ifServerUnavailable: false });
                if (error?.response?.status === 403) {
                    if (localStorage.getItem('user')) localStorage.removeItem('user')
                    if (localStorage.getItem('_expiredTime')) localStorage.removeItem('_expiredTime')
                    store.dispatch({ type: actionTypes.LOGOUT });
                }
            }
            if (error?.response?.status !== 403) {
                extendSession();
            }

            return Promise.reject(error);
        });
}
