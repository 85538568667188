import React from 'react';

import classes from '../ChangePhoto/ChangePhoto.module.scss';
import parentClasses from '../ProfileSettings.module.scss';
import ProfilePhotoInput from "../ProfilePhotoInput/ProfilePhotoInput";

const ChangePhoto = () => {
    const PhotoData = () =>
        <div>
            <div className={classes.photoTitle}>Фотография</div>
            <div className={classes.requirementsList}>
                Данная фотография будет использоваться для отображения:
                <ul>
                    <li>в расписании</li>
                    <li>в телефонном справочнике</li>
                    <li>на личной странице</li>
                </ul>
            </div>
        </div>

    return <div>
        <div className={parentClasses.tabContainer}>
            <div className={parentClasses.tabSideCaption}>
                <PhotoData/>
            </div>
            <div className={parentClasses.tabContent}>
                <ProfilePhotoInput/>
            </div>
        </div>
    </div>
}

export default ChangePhoto;
