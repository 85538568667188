//common
export const SET_MESSAGE = "SET_MESSAGE"
export const SET_SIDEBAR_DESKTOP_COLLAPSED = "SET_SIDEBAR_DESKTOP_COLLAPSED"
export const SET_SERVER_UNAVAILABLE = "SET_SERVER_UNAVAILABLE"

//auth
export const LOGIN_PROCESSING = "LOGIN_PROCESSING"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const LOGIN_FAILED = "LOGIN_FAILED"
export const LOGOUT = "LOGOUT"
export const RESET_AUTH_MESSAGE = "RESET_AUTH_MESSAGE"
export const LOGIN_EQUALS_PASSWORD = "LOGIN_EQUALS_PASSWORD"

//gradeBook
export const LOAD_GRADE_BOOK = "LOAD_GRADE_BOOK";
export const SET_GRADE_BOOK = "SET_GRADE_BOOK";
export const LOAD_GRADE_BOOK_SUBJECTS = "LOAD_GRADE_BOOK_SUBJECTS"
export const LOAD_STUDENT_GROUPS_OR_EMPLOYEES = "LOAD_STUDENT_GROUPS_OR_EMPLOYEES"
export const LOAD_STUDENT_GROUP_OR_EMPLOYEE = "LOAD_STUDENT_GROUP_OR_EMPLOYEE"
export const SET_GRADE_BOOK_SUBJECTS = "SET_GRADE_BOOK_SUBJECTS"
export const SET_STUDENT_GROUPS_OR_EMPLOYEES = "SET_STUDENT_GROUPS_OR_EMPLOYEES"
export const SET_STUDENT_GROUP_OR_EMPLOYEE = "SET_STUDENT_GROUP_OR_EMPLOYEE"
export const SET_IS_EMPLOYEE = "SET_IS_EMPLOYEE"
export const SET_LESSONS_DATE = "SET_LESSONS_DATE"
export const SET_LESSONS_NUMBER = "SET_LESSONS_NUMBER"
export const SET_FILTER_STUDENT_GROUP_OR_EMPLOYEE = "SET_FILTER_STUDENT_GROUP_OR_EMPLOYEE"
export const STUDENT_PHOTOS_LOADED = "STUDENT_PHOTOS_LOADED";

export const ASSIGN_SUBGROUPS = "ASSIGN_SUBGROUPS"
export const ACCEPT_SUBGROUPS = "ACCEPT_SUBGROUPS"
export const SKIP_SUBGROUPS = "SKIP_SUBGROUPS"

export const LOAD_GRADEBOOK_REPORT = 'LOAD_GRADEBOOK_REPORT'
export const SAVE_GRADEBOOK_REPORT = 'SAVE_GRADEBOOK_REPORT'
export const LOAD_MEMO_REPORT = 'LOAD_MEMO_REPORT'
export const SAVE_MEMO_REPORT = 'SAVE_MEMO_REPORT'

export const ASSIGN_TEAMS = "ASSIGN_TEAMS"
export const ACCEPT_TEAMS = "ACCEPT_TEAMS"
export const SKIP_TEAMS = "SKIP_TEAMS"

export const ADD_NEW_LESSON = "ADD_NEW_LESSON"
export const ACCEPT_NEW_LESSON = "ACCEPT_NEW_LESSON"
export const SKIP_NEW_LESSON = "SKIP_NEW_LESSON"

export const EDIT_LESSON = "EDIT_LESSON"
export const ACCEPT_EDITED_LESSON = "ACCEPT_EDITED_LESSON"
export const SKIP_EDITED_LESSON = "SKIP_EDITED_LESSON"

export const DELETE_LESSON = "DELETE_LESSON"
export const ACCEPT_DELETED_LESSON = "ACCEPT_DELETED_LESSON"
export const SKIP_DELETED_LESSON = "SKIP_DELETED_LESSON"

export const START_UPDATING_LAB_COUNT = "START_UPDATING_LAB_COUNT"
export const END_UPDATING_LAB_COUNT = "END_UPDATING_LAB_COUNT"

export const SET_LESSON_PERIODS = "SET_LESSON_PERIODS"

//announcements
export const LOAD_ANNOUNCEMENTS = "LOAD_ANNOUNCEMENTS";
export const SET_ANNOUNCEMENTS = "SET_ANNOUNCEMENTS";
export const START_UPDATING_ANNOUNCEMENT = "START_UPDATING_ANNOUNCEMENT";
export const END_UPDATING_ANNOUNCEMENT = "END_UPDATING_ANNOUNCEMENT";

//users
export const LOAD_USERS = "LOAD_USERS";
export const SET_USERS = "SET_USERS";

//positions
export const LOAD_POSITIONS = "LOAD_POSITIONS";
export const SET_POSITIONS = "SET_POSITIONS";

//auditory
export const LOAD_FILTERED_AUDITORY = "LOAD_FILTERED_AUDITORY";
export const SET_FILTERED_AUDITORY = "SET_FILTERED_AUDITORY";

//group
export const LOAD_FILTERED_GROUPS = "LOAD_FILTERED_GROUPS";
export const SET_FILTERED_GROUPS = "SET_FILTERED_GROUPS";

//personal page
export const LOAD_EMPLOYEE_DETAILS = "LOAD_EMPLOYEE_DETAILS";
export const SET_EMPLOYEE_DETAILS = "SET_EMPLOYEE_DETAILS"
export const SET_PHONES = 'SET_PHONES';
export const LOAD_PHONES = 'LOAD_PHONES';
export const SET_FORMER_JOB_POSITIONS = 'SET_FORMER_JOB_POSITIONS';
export const LOAD_FORMER_JOB_POSITIONS = 'LOAD_FORMER_JOB_POSITIONS';