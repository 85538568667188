import React from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { MdOutlineMenu } from "react-icons/md";
import { AiOutlineUser } from "react-icons/ai";
import { Avatar } from 'antd';

import Logo from '../../../assets/images/logo.svg'
import classes from './NavigationHeader.module.scss'
import { logout } from '../../../store/actions';
import routes from '../../../services/routes';
import { MOBILE_WIDTH_THRESHOLD_VALUE } from '../../../services/constants';

import BugReportButton from '../../BugReport/BugReportButton/BugReportButton';
import useWindowDimensions from '../../../hooks/windowDimensions';

const NavigationHeader = (props) => {
    const dispatch = useDispatch()
    const user = useSelector(state => state.auth.user);

    const { width } = useWindowDimensions();

    const location = useLocation()
    const navigate = useNavigate()

    const onLogoutHandler = () => {
        dispatch(logout()).then(() => navigate("/", { replace: true }))
    }

    return (
        <div className={classes.navbar}>
            {(user && width > 500) && <div className={classes.logoContainer}>
                <NavLink to="/personal-page">
                    <img src={Logo} alt="Логотип" width="50px" height="50px" />
                </NavLink>
            </div>}

            {user && <button className={classes.mobileSidebarToggle} onClick={props.mobileToggleClicked} aria-label="toggle sidebar">
                <MdOutlineMenu size={25} />
            </button>}

            <div className={(!user || width <= MOBILE_WIDTH_THRESHOLD_VALUE) ? classes.reverseContainer : classes.container}>
                <BugReportButton
                  image={props.image}
                  getImage={props.getImage}
                />
                {(location.pathname !== '/') && <div
                    className={classes.titleContainer}
                    style={{ marginLeft: (!user) ? 0 : '15px' }}>
                    {routes.find(route => route.pathname === location.pathname)?.name ?? 'Кабинет сотрудника БГУИР'}
                </div>}
            </div>

            {(user && width > MOBILE_WIDTH_THRESHOLD_VALUE) && <div className={classes.navigationItemsContainer}>
                <div className={classes.usernameContainer}>{`${user.fio} [${user.username}]`}</div>

                <NavLink to="/personal-page">
                    <Avatar
                        src={user.photoUrl}
                        size={47}
                        icon={<AiOutlineUser />} alt="profileIcon"
                        className={classes.toggleTrigger} />
                </NavLink>

                <button
                    type='button'
                    className={classes.logoutButton}
                    onClick={() => onLogoutHandler()}>
                    Выйти
                </button>
            </div>}
        </div>
    )
};

export default NavigationHeader;
